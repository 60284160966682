import { FlatTreeControl } from '@angular/cdk/tree';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, Subject } from 'rxjs';
import { URL_API } from 'src/main';
import { ProjectService } from '../services/project.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

class proyectoParametros {
  idProyecto: string;
  bloque: string;
  escalera: string;
  planta: string;
  vivienda: string;
  habitacion: string;

}

@Component({
  selector: 'app-param-selection',
  templateUrl: './paramselection.component.html',
  styleUrls: ['./paramselection.component.css'],
})


export class ParamSelectionComponent implements OnInit, OnDestroy {

  public webViewMessage = new Subject<any>();

  projectId: string = "";

  paramsBloque: string[] = [];
  paramsEscalera: string[] = [];
  paramsPlanta: string[] = [];
  paramsVivienda: string[] = [];
  paramsHabitacion: string[] = [];


  isLoader: boolean = false;
  isRevitLoad:boolean = false;

  bloqueControl = new FormControl();
  filteredOptionsBloque: Observable<string[]>;

  escaleraControl = new FormControl();
  filteredOptionsEscalera: Observable<string[]>;

  plantaControl = new FormControl();
  filteredOptionsPlanta: Observable<string[]>;

  viviendaControl = new FormControl();
  filteredOptionsVivienda: Observable<string[]>;

  habitacionControl = new FormControl();
  filteredOptionsHabitacion: Observable<string[]>;


  constructor(public auth: AuthService, private route: ActivatedRoute, private http: HttpClient, private router: Router, private projectService: ProjectService) {
    window?.chrome?.webview?.addEventListener('message', event => {
      this.isRevitLoad = true;
      this.webViewMessage.next(event.data);
      this.isRevitLoad = false;
      if (!event.data.startsWith("ProjectData")) {
        console.log("datos", event.data);
        this.paramsBloque = JSON.parse(event.data);
        this.paramsEscalera = JSON.parse(event.data);
        this.paramsPlanta = JSON.parse(event.data);
        this.paramsVivienda = JSON.parse(event.data);
        this.paramsHabitacion = JSON.parse(event.data);

      }
    });
    window?.chrome?.webview?.addEventListener('messageerror',  err => {
      this.isRevitLoad = false;
    });
  }


  ngOnInit() {
    this.isLoader = true;
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.projectService.setProjectId(this.projectId);
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };


        this.http.get<proyectoParametros>(URL_API + '/proyectos/parametroshabitacion/' + this.projectService.projectId.getValue(), httpOptions).subscribe(result => {
          this.isLoader = false;
          if (result != null && result.idProyecto === this.projectId) {

            if (this.paramsBloque.length == 0) {
              this.paramsBloque.push(result.bloque);
              this.paramsEscalera.push(result.escalera);
              this.paramsPlanta.push(result.planta);
              this.paramsVivienda.push(result.vivienda);
              this.paramsHabitacion.push(result.habitacion);

            }

            this.bloqueControl.patchValue(result.bloque);

            this.escaleraControl.patchValue(result.escalera);
            this.plantaControl.patchValue(result.planta);
            this.viviendaControl.patchValue(result.vivienda);
            this.habitacionControl.patchValue(result.habitacion);
            
            //this.SendToRevit(result);
          }
        }, err => {
          this.isLoader = false;
        });

      } else {
        this.isLoader = false;
      }
    })
    this.filteredOptionsBloque = this.bloqueControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value, this.paramsBloque))
    );

  this.filteredOptionsEscalera = this.escaleraControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value, this.paramsEscalera))
    );

  this.filteredOptionsPlanta = this.plantaControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value, this.paramsPlanta))
    );

  this.filteredOptionsVivienda = this.viviendaControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value, this.paramsVivienda))
    );

  this.filteredOptionsHabitacion = this.habitacionControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value, this.paramsHabitacion))
    );

  }



  private _filter(value: string, options: string[]): string[] {
    const filterValue = value.toLowerCase();

    return options.filter(option => option.toLowerCase().includes(filterValue));
  }

 

  ngAfterViewInit(): void {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'RoomsParamsName' }));
  }


  save() {
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          })
        };
        var params: proyectoParametros = new proyectoParametros();

        params.bloque = this.bloqueControl.value;
        params.escalera = this.escaleraControl.value;
        params.planta = this.plantaControl.value;
        params.vivienda = this.viviendaControl.value;
        params.habitacion = this.habitacionControl.value;
        params.idProyecto = this.projectId;
        this.http.post<proyectoParametros>(URL_API + '/proyectos/parametroshabitacion', JSON.stringify(params), httpOptions).subscribe(result => {
          this.SendToRevit(result);
        });

      }
    })
  }

  next() {
    this.router.navigate(['/projecto', this.projectId]);
  }

  SendToRevit(result: proyectoParametros) {
       this.isRevitLoad = true;
       window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'RoomsParamsResult', 'obj': result }));
    setTimeout(() => {
      this.isRevitLoad = false;
    }, 120000);
  }

  ngOnDestroy(): void {
    window?.chrome?.webview?.removeEventListener('message', event => {
      this.webViewMessage.next(event.data);
      this.paramsBloque = JSON.parse(event.data);
      this.paramsEscalera = JSON.parse(event.data);
      this.paramsPlanta = JSON.parse(event.data);
      this.paramsVivienda = JSON.parse(event.data);
      this.paramsHabitacion = JSON.parse(event.data);

    });
  }

}

