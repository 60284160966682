import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../project/services/project.service';

@Component({
  selector: 'app-start-revit',
  templateUrl: './start-revit.component.html',
  styleUrls: ['./start-revit.component.css']
})
export class StartRevitComponent {
  projectId:string;
  constructor(private route:ActivatedRoute ,private projectService:ProjectService ,private router:Router){

  }
  ngOnInit(){
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.projectService.isRevitData = true;
    if(this.projectId){
      this.projectService.setProjectId(this.projectId);
      window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'project', 'obj': this.projectId }));
      this.router.navigate(['/project/paramselection', this.projectId]);
    }else{
      this.router.navigate(['/']);
    }
  }
}
