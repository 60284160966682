
<div class="new-project-page">
    <app-loading *ngIf="isLoader"></app-loading>
  <section class="page-section" *ngIf="!isLoader">
    <div class="page-section-div">
      <div class="row">
        <div class="col-lg-7 offset-lg-1 col-sm-12 page-section-col-2">
          <div class="page-section-card">
            <div #map style="width:100%;height:100%"></div>
          </div>
          <!-- <form>
            <div class="form-group">
              <input type="text" class="page-section-btn" placeholder="Required Field">
            </div>
            <div class="form-group">
              <input type="text" class="page-section-btn" placeholder="Required Field">
            </div>
            <div class="form-group">
              <input type="text" class="page-section-btn" placeholder="Required Field">
            </div>
            <div class="form-group">
              <input type="text" class="page-section-btn" placeholder="Required Field">
            </div>
            <div class="form-group">
              <input type="text" class="page-section-btn" placeholder="Required Field">
            </div>
            <div class="form-group">
              <input type="text" class="page-section-btn" placeholder="Required Field">
            </div>
            <div class="form-group">
              <input type="text" class="page-section-btn" placeholder="Required Field">
            </div>
          </form> -->
        </div>
        <div class="col-lg-4 col-sm-12 page-section-col-1">
          <div class="location-content" *ngIf="location">
            <div class="mb-4" >
              <p class="location-sub-heading">{{'street' | tr}}</p>
              <p class="location-text">{{location?.streetName}}</p>
            </div>
            <div class="mb-4">
              <p class="location-sub-heading">{{'number' | tr}}</p>
              <p class="location-text">{{location?.streetNumber}}</p>
            </div>
            <div class="mb-4">
              <p class="location-sub-heading">{{'zipcode' | tr}}</p>
              <p class="location-text">{{location?.zipCode}}</p>
            </div>
            <div class="mb-4">
              <p class="location-sub-heading">{{'city' | tr}}</p>
              <p class="location-text">{{location?.city}}</p>
            </div>
            <div class="mb-4">
              <p class="location-sub-heading">{{'province' | tr}}</p>
              <p class="location-text">{{location?.province}}</p>
            </div>
            <div class="mb-4">
              <p class="location-sub-heading">{{'state' | tr}}</p>
              <p class="location-text">{{location?.state}}</p>
            </div>
            <div class="mb-4">
              <p class="location-sub-heading">{{'country' | tr}}</p>
              <p class="location-text">{{location?.country}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>