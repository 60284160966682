<div class="project-resume-page">
  <app-loading *ngIf="isLoader"></app-loading>
  <section class="page-section" *ngIf="!isLoader">
    <div class="page-section-div">
      <div class="row">
        <div
          class="col-sm-12 col-lg-4"
          *ngIf="
            (!projectService.project ||
              projectService.project.tipoProyecto !== 'salubridad') &&
            projectResumeData?.puertas &&
            (projectResumeData?.puertas.error.length ||
              projectResumeData?.puertas.info.length ||
              projectResumeData?.puertas.warning.length)
          "
        >
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(6)">
              <i class="bi bi-door-open-fill"></i>
              <div class="py-2">
                <h3>{{ 'puertas' | tr }}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div
                class="resume-card-box mt-3"
                *ngFor="let error of projectResumeData?.puertas.error"
              >
                <div class="resume-card-box-content box-content-error">
                  {{ error.mensaje }}
                </div>
                <div></div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let info of projectResumeData?.puertas.info"
              >
                <div class="resume-card-box-content box-content-info">
                  {{ info.mensaje }}
                </div>
                <div></div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let warning of projectResumeData?.puertas.warning"
              >
                <div class="resume-card-box-content box-content-warning">
                  {{ warning.mensaje }}
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12 col-lg-4"
          *ngIf="
            projectResumeData?.habitaciones &&
            (projectResumeData?.habitaciones.error.length ||
              projectResumeData?.habitaciones.info.length ||
              projectResumeData?.habitaciones.warning.length)
          "
        >
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(4)">
              <i class="fas fa-bed"></i>
              <div class="py-2">
                <h3>{{ 'habitaciones' | tr }}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div
                class="resume-card-box mt-3"
                *ngFor="let error of projectResumeData?.habitaciones.error"
              >
                <div class="resume-card-box-content box-content-error">
                  {{ error.mensaje }}
                </div>
                <i
                  class="bi bi-arrow-right"
                  *ngIf="error.viviendas?.length"
                  (click)="openViviendasModal(error.viviendas, error.mensaje)"
                ></i>
                <div></div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let info of projectResumeData?.habitaciones.info"
              >
                <div class="resume-card-box-content box-content-info">
                  {{ info.mensaje }}
                </div>
                <div></div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let warning of projectResumeData?.habitaciones.warning"
              >
                <div class="resume-card-box-content box-content-warning">
                  {{ warning.mensaje }}
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12 col-lg-4"
          *ngIf="
            projectResumeData?.falsosTechos &&
            (projectResumeData?.falsosTechos.error.length ||
              projectResumeData?.falsosTechos.info.length ||
              projectResumeData?.falsosTechos.warning.length)
          "
        >
          <div class="resume-card mt-4">
            <div class="resume-card-header">
              <div class="py-2">
                <h3>{{ 'techos' | tr }}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div
                class="resume-card-box mt-3"
                *ngFor="let error of projectResumeData?.falsosTechos.error"
              >
                <div class="resume-card-box-content box-content-error">
                  {{ error.mensaje }}
                </div>
                <i
                  class="bi bi-arrow-right"
                  *ngIf="error.techos?.length"
                  (click)="openViviendasModal(error.techos, error.mensaje)"
                ></i>
                <div></div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let info of projectResumeData?.falsosTechos.info"
              >
                <div class="resume-card-box-content box-content-info">
                  {{ info.mensaje }}
                </div>
                <div></div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let warning of projectResumeData?.falsosTechos.warning"
              >
                <div class="resume-card-box-content box-content-warning">
                  {{ warning.mensaje }}
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12 col-lg-4"
          *ngIf="
            projectResumeData?.elementos &&
            (projectResumeData?.elementos.error.length ||
              projectResumeData?.elementos.info.length ||
              projectResumeData?.elementos.warning.length)
          "
        >
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(5)">
              <i class="bi bi-border-all"></i>
              <div class="py-2">
                <h3>{{ 'elementos' | tr }}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div
                class="resume-card-box mt-3"
                *ngFor="let error of projectResumeData?.elementos.error"
              >
                <div class="resume-card-box-content box-content-error">
                  {{ error.mensaje }}
                </div>
                <i
                  class="bi bi-arrow-right"
                  *ngIf="error.habitaciones?.length"
                  (click)="
                    openViviendasModal(error.habitaciones, error.mensaje, true)
                  "
                ></i>
                <i
                  class="bi bi-arrow-right"
                  *ngIf="error.elementos?.length"
                  (click)="
                    openViviendasModal(error.elementos, error.mensaje, true)
                  "
                ></i>
                <div></div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let info of projectResumeData?.elementos.info"
              >
                <div class="resume-card-box-content box-content-info">
                  {{ info.mensaje }}
                </div>
                <div></div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let warning of projectResumeData?.elementos.warning"
              >
                <div class="resume-card-box-content box-content-warning">
                  {{ warning.mensaje }}
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12 col-lg-4"
          *ngIf="
            projectResumeData?.instalaciones &&
            (projectResumeData?.instalaciones.error.length ||
              projectResumeData?.instalaciones.info.length ||
              projectResumeData?.instalaciones.warning.length)
          "
        >
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(9)">
              <i class="bi bi-patch-question-fill"></i>
              <div class="py-2">
                <h3>{{ 'instalaciones' | tr }}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div
                class="resume-card-box mt-3"
                *ngFor="let error of projectResumeData?.instalaciones.error"
              >
                <div class="resume-card-box-content box-content-error">
                  {{ error.mensaje }}
                </div>
                <div></div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let info of projectResumeData?.instalaciones.info"
              >
                <div class="resume-card-box-content box-content-info">
                  {{ info.mensaje }}
                </div>
                <div></div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let warning of projectResumeData?.instalaciones.warning"
              >
                <div class="resume-card-box-content box-content-warning">
                  {{ warning.mensaje }}
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12 col-lg-4"
          *ngIf="
            projectResumeData?.patinejos &&
            (projectResumeData?.patinejos.error.length ||
              projectResumeData?.patinejos.info.length ||
              projectResumeData?.patinejos.warning.length)
          "
        >
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(8)">
              <i class="bi bi-layers"></i>
              <div class="py-2">
                <h3>{{ 'patinejos' | tr }}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div
                class="resume-card-box mt-3"
                *ngFor="let error of projectResumeData?.patinejos.error"
              >
                <div class="resume-card-box-content box-content-error">
                  {{ error.mensaje }}
                </div>
                <div></div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let info of projectResumeData?.patinejos.info"
              >
                <div class="resume-card-box-content box-content-info">
                  {{ info.mensaje }}
                </div>
                <div></div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let warning of projectResumeData?.patinejos.warning"
              >
                <div class="resume-card-box-content box-content-warning">
                  {{ warning.mensaje }}
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12 col-lg-4"
          *ngIf="
            projectResumeData?.viviendas &&
            (projectResumeData?.viviendas.error.length ||
              projectResumeData?.viviendas.info.length ||
              projectResumeData?.viviendas.warning.length)
          "
        >
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(7)">
              <i class="bi bi-house-door"></i>
              <div class="py-2">
                <h3>{{ 'viviendas' | tr }}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div
                class="resume-card-box mt-3"
                *ngFor="let error of projectResumeData?.viviendas.error"
              >
                <div class="resume-card-box-content box-content-error">
                  {{ error.mensaje }}
                </div>
                <div>
                  <i class="bi bi-arrow-right"></i>
                </div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let info of projectResumeData?.viviendas.info"
              >
                <div class="resume-card-box-content box-content-info">
                  {{ info.mensaje }}
                </div>
                <div>
                  <i class="bi bi-arrow-right"></i>
                </div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let warning of projectResumeData?.viviendas.warning"
              >
                <div class="resume-card-box-content box-content-warning">
                  {{ warning.mensaje }}
                </div>
                <div>
                  <i class="bi bi-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12 col-lg-4"
          *ngIf="
            projectResumeData?.niveles &&
            (projectResumeData?.niveles.error.length ||
              projectResumeData?.niveles.info.length ||
              projectResumeData?.niveles.warning.length)
          "
        >
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(2)">
              <i class="bi bi-layers"></i>
              <div class="py-2">
                <h3>{{ 'plant-stepper' | tr }}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div
                class="resume-card-box mt-3"
                *ngFor="let error of projectResumeData?.niveles.error"
              >
                <div class="resume-card-box-content box-content-error">
                  {{ error.mensaje }}
                </div>
                <div>
                  <!-- <i
                    class="bi bi-arrow-right"
                    *ngIf="error.niveles?.length"
                    (click)="openViviendasModal(error.niveles, error.mensaje)"
                  ></i> -->
                </div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let info of projectResumeData?.niveles.info"
              >
                <div class="resume-card-box-content box-content-info">
                  {{ info.mensaje }}
                </div>
              </div>
              <div
                class="resume-card-box mt-3"
                *ngFor="let warning of projectResumeData?.niveles.warning"
              >
                <div class="resume-card-box-content box-content-warning">
                  {{ warning.mensaje }}
                </div>
                <div>
                  <i class="bi bi-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Fondo Traslúcido -->
<div class="modal-backdrop" *ngIf="showViviendasModal"></div>

<!-- Modal de Viviendas -->
<div
  class="modal"
  [ngClass]="{ 'show d-block': showViviendasModal }"
  tabindex="-1"
  role="dialog"
  *ngIf="showViviendasModal"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          {{ modalTitle }}
        </h3>
        <button
          type="button"
          class="close"
          (click)="closeViviendasModal()"
          aria-label="Cerrar"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container>
          <table class="table">
            <thead>
              <tr>
                <th>Bloque</th>
                <th>Escalera</th>
                <th>Planta</th>
                <th>Vivienda</th>
                <th *ngIf="esHabitacion">Habitación</th>
                <!-- Añadir más columnas según las propiedades de Vivienda -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let vivienda of viviendasList">
                <td>{{ vivienda.bloque }}</td>
                <td>{{ vivienda.escalera }}</td>
                <td>{{ vivienda.planta }}</td>
                <td
                  [ngClass]="{ clickavaliable: enRevit }"
                  (click)="searchVivienda(vivienda)"
                >
                  {{ vivienda.vivienda || 'Sin nombre' }}
                </td>
                <td
                  *ngIf="vivienda"
                  [ngClass]="{ clickavaliable: enRevit }"
                  (click)="searchRoom(vivienda)"
                >
                  {{ vivienda.habitacion }}
                </td>
                <!-- Añadir más celdas según las propiedades de Vivienda -->
              </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-template #errorContent>
          <div class="alert alert-danger" role="alert">
            No se pudieron cargar las viviendas.
          </div>
        </ng-template>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeViviendasModal()"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
