import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ErrorComponent } from './pages/error/error.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { ProjectComponent } from './pages/project/project/project.component';
import { ParamSelectionComponent } from './pages/project/paramSelection/paramselection.component';
import { SuperficiesSinHabitacionComponent } from './pages/project/superficieSinHabitacion/superficieSinHabitacion.component';
import { PatinejosComponent } from './pages/project/patinejos/patinejos.component';
import { HabitacionesComponent } from './pages/project/habitaciones/habitaciones.component';
import { FamiliasComponent } from './pages/project/familias/familias.component';
import { VentanasComponent } from './pages/project/ventanas/ventanas.component';
import { NuevoProyectoComponent } from './pages/project/ubicacion-proyecto/ubicacion-proyecto.component';
import { DoorsComponent } from './pages/project/doors/doors.component';
import { LevelsComponent } from './pages/project/levels/levels.component';
import { SettingComponent } from './pages/setting/setting.component';
import { ProjectDataComponent } from './pages//project/project-data/project-data.component';
import { PlantSelectionComponent } from './pages/project/plant-selection/plant-selection.component';
import { GroundPlantsComponent } from './pages/project/ground-plants/ground-plants.component';
import { ProjectHomeComponent } from './pages/project/project-home/project-home.component';
import { OverGroundSelectionComponent } from './pages/project/over-ground-selection/over-ground-selection.component';
import { UploadProjectComponent } from './pages/project/upload-project/upload-project.component';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'project/:id',
    component: ProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/paramselection/:id',
    component: ParamSelectionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/superficiessinhabitacion/:id',
    component: SuperficiesSinHabitacionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/patinejos/:id',
    component: PatinejosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/habitaciones/:id',
    component: HabitacionesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/familias/:id',
    component: FamiliasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/ventanas/:id',
    component: VentanasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/puertas/:id',
    component: DoorsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/niveles/:id',
    component: LevelsComponent,
    canActivate: [AuthGuard],
  },
  // user-info
  {
    path: 'project/ubicacion/:id',
    component: NuevoProyectoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-info',
    component: SettingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/project-data/:id',
    component: ProjectDataComponent,
  },
  {
    path: 'project/plant-selection/:id',
    component: PlantSelectionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/ground-plants/:id',
    component: GroundPlantsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project-home/:id/:stepIndex',
    component: ProjectHomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project-home/:id',
    component: ProjectHomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'projecto/over-ground-plants/:id',
    component: OverGroundSelectionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'projecto/upload-project/:id',
    component: UploadProjectComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: '**',
  //   redirectTo: '',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
