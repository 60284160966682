import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Installation, Selections } from 'src/app/Shared/modals/allStructre.interface';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { ProjectService } from '../services/project.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-installations',
  templateUrl: './installations.component.html',
  styleUrls: ['./installations.component.css']
})
export class InstallationsComponent {
  projectId: string;
  isLoader: boolean = false;
  expandedIndex = 0;
  isDisable:boolean = true;
  form:FormGroup
  idProyectoPlanta:string;
  installationList:Installation;
  plumbingPressureList:any;
  plumbingMaterialList:any;
  salubrityTypeList:any;
  salubrityMaterialList:any;
  salubrityBrand:any;
  sanitationTypeList:any;
  tipoCuadroList:any;
  climatizacionTypeList:any;
  climatizacionSistemaList:any;
  climatizacionMarcaList:any;
  climatizacionCargasList:any;
  climatizacionEmisorCalorList:any;

  constructor(private fb:FormBuilder,private route: ActivatedRoute, private router: Router, private api: ApiServices, private toastr: ToastrService, public projectService: ProjectService) {
    this.projectId = this.route.snapshot.paramMap.get('id');
    if (this.projectId) {
      this.projectService.setProjectId(this.projectId);
    }
    this.form = this.fb.group({
      plumbing:[false],
      pressure:[''],
      cistren:[false],
      pressureGroup:[''],
      piping:[''],
      salubrity:[projectService.project && this.projectService.project.tipoProyecto === 'salubridad'],
      type:[''],
      sistem:[''],
      brand:[''],
      sanitation:[false],
      typeRed:[''],
      idInstalaciones_Tipo:[''],
      idClimatizacion_Tipo:[''],
      IdElectricidad_TipoCuadro:[''],
      idClimatizacion_Sistema:[''],
      idClimatizacion_Marca:[''],
      idClimatizacion_Cargas:[''],
      climaCocina:[false],
      frioClima:[false],
      idClimatizacion_EmisorCalor:[''],
      electricity:[false],
      borna:[false],
      toalleroElectrico:[false],
      potenciaVehiculosElectricos:10,
      persianaElectrica:[false],
      climatizacion:[false],
    })
  }
  ngOnInit(): void {
    this.api.getInstallationPlumbingGroupPressure().subscribe(res =>{
      this.plumbingPressureList = res;
    })
    this.api.getInstallationPlumbingMaterial().subscribe(res =>{
      this.plumbingMaterialList = res;
    })
    this.api.getInstallationSalubrityType().subscribe(res =>{
      this.salubrityTypeList = res;
    })
    this.api.getInstallationSalubrityMaterial().subscribe(res =>{
      this.salubrityMaterialList = res;
    })
    this.api.getInstallationSalubrityBrand().subscribe(res =>{
      this.salubrityBrand = res;
    })
    this.api.getInstallationSanitationType().subscribe(res =>{
      this.sanitationTypeList = res;
    })
    this.api.getidInstalaciones_Tipo().subscribe(res =>{
      this.tipoCuadroList = res;
    })
    this.api.getInstallationClimatizacionType().subscribe(res =>{
      this.climatizacionTypeList = res;
    })
    this.api.getInstallationClimatizacionSistema().subscribe(res =>{
      this.climatizacionSistemaList = res;
    })
    this.api.getInstallationClimatizacionMarca().subscribe(res =>{
      this.climatizacionMarcaList = res;
    })
    this.api.getInstallationClimatizacionCargas().subscribe(res =>{
      this.climatizacionCargasList = res;
    })
    this.api.getInstallationClimatizacionEmisorCalor().subscribe(res =>{
      this.climatizacionEmisorCalorList = res;
    })
    if(this.projectId){
      this.api.getInstallationById(this.projectId).subscribe((res:Installation) =>{
        this.installationList = res;
        console.log('installationList:', this.installationList);
        if(this.installationList.fontaneria){
          this.form.patchValue({
            plumbing: true
          })
        }
        if(this.installationList.salubridad){
          this.form.patchValue({
            salubrity: true
          })
        }
        if(this.installationList.saneamiento){
          this.form.patchValue({
            sanitation: true
          })
        }
        
        if(this.installationList.electricidad){
          this.form.patchValue({
            electricity: true
          })
        }
        
        if(this.installationList.climatizacion){
          this.form.patchValue({
            climatizacion: true
          })
        }
        
        this.form.patchValue({
          pressure:this.installationList.fontaneria?.presion,
          cistren:this.installationList.fontaneria?.aljibe,
          pressureGroup:this.installationList.fontaneria?.idMarcaGrupoPresion,
          piping:this.installationList.fontaneria?.idMaterial_fontaneria,
          type:this.installationList.salubridad?.idInstalaciones_Tipo,
          sistem:this.installationList.salubridad?.idSistema,
          brand:this.installationList.salubridad?.idMarcaSalubridad,
          typeRed:this.installationList.saneamiento?.idSaneamiento_TipoRed,
          IdElectricidad_TipoCuadro:this.installationList.electricidad?.idElectricidad_TipoCuadro,
          borna:this.installationList.electricidad?.borna,
          toalleroElectrico:this.installationList.electricidad?.toalleroElectrico,
          potenciaVehiculosElectricos:this.installationList.electricidad?.potenciaVehiculosElectricos,
          persianaElectrica:this.installationList.electricidad?.persianaElectrica,
          idClimatizacion_Tipo:this.installationList.climatizacion?.idInstalaciones_Tipo,
          idClimatizacion_Sistema:this.installationList.climatizacion?.idClimatizacion_Sistema,
          idClimatizacion_Marca:this.installationList.climatizacion?.idClimatizacion_Marca,
          idClimatizacion_Cargas:this.installationList.climatizacion?.idClimatizacion_Cargas,
          climaCocina:this.installationList.climatizacion?.climaCocina,
          frioClima:this.installationList.climatizacion?.frioClima,
          idClimatizacion_EmisorCalor:this.installationList.climatizacion?.idClimatizacion_EmisorCalor,
        })
      },err=>{
      });
    }else{
      this.toastr.info('Project Data Not Found !')
    }
  }
 
  SaveInstallation() {
    console.log('instaltion saved:', this.form.value);
    const payload = {
      idProject: this.projectService.projectId.getValue(),
      fontaneria: {
        presion: this.form.value.pressure,
        aljibe: this.form.value.cistren == "true" ? true : false,
        idMarcaGrupoPresion: this.form.value.pressureGroup,
        idMaterial_fontaneria: this.form.value.piping
      },
      salubridad: {
        IdInstalaciones_Tipo: this.form.value.type,
        IdSistema: this.form.value.sistem,
        IdMarcaSalubridad: this.form.value.brand,
      },
      saneamiento: {
        idSaneamiento_TipoRed: this.form.value.typeRed
      },
      electricidad: {
        IdElectricidad_TipoCuadro: this.form.value.IdElectricidad_TipoCuadro,
        borna: this.form.value.borna == "true" ? true : false,
        toalleroElectrico: this.form.value.toalleroElectrico == "true" ? true : false,
        potenciaVehiculosElectricos: this.form.value.potenciaVehiculosElectricos,
        persianaElectrica: this.form.value.persianaElectrica == "true" ? true : false
      },
      climatizacion: {
        IdInstalaciones_Tipo: this.form.value.idClimatizacion_Tipo,
        idClimatizacion_Sistema: this.form.value.idClimatizacion_Sistema,
        idClimatizacion_Marca: this.form.value.idClimatizacion_Marca,
        idClimatizacion_Cargas: this.form.value.idClimatizacion_Cargas,
        climaCocina: this.form.value.climaCocina == "true" ? true : false,
        frioClima: this.form.value.frioClima == "true" ? true : false,
        idClimatizacion_EmisorCalor: this.form.value.idClimatizacion_EmisorCalor
      }

    };

    if (!this.form.value.plumbing) {
      delete payload.fontaneria;
    }

    if (!this.form.value.salubrity) {
      delete payload.salubridad;
    }

    if (!this.form.value.sanitation) {
      delete payload.saneamiento;
    }

    if (!this.form.value.electricity) {
      delete payload.electricidad;
    }

    if (!this.form.value.climatizacion) {
      delete payload.climatizacion;
    }

    console.log('payload:', payload);
    if (payload) {
      this.api.UpdateInstallation(payload).subscribe(res => {
        console.log('after updated:', res);
        this.toastr.success('Saved Changes');
      }, err => {
        this.toastr.error('Installation cannot be updated! Something went wrong');
      });
    }
  }
  plumbingChange(value:any){
    if(value === 'true'){
      this.form.patchValue({
        plumbing:true
      })
    }else if(value === 'false'){
      this.form.patchValue({
        plumbing:false
      })
    }else{
      this.form.patchValue({
        plumbing:value
      })
    }
  }
  salubrityChange(value:any){
    if(value === 'true'){
      this.form.patchValue({
        salubrity:true
      })
    }else if(value === 'false'){
      this.form.patchValue({
        salubrity:false
      })
    }else{
      this.form.patchValue({
        salubrity:value
      })
    }
  }
  sanitationChange(value:any){
    if(value === 'true'){
      this.form.patchValue({
        sanitation:true
      })
    }else if(value === 'false'){
      this.form.patchValue({
        sanitation:false
      })
    }else{
      this.form.patchValue({
        sanitation:value
      })
    }
  }
  electricityChange(value:any){
    if(value === 'true'){
      this.form.patchValue({
        electricity:true
      })
    }else if(value === 'false'){
      this.form.patchValue({
        electricity:false
      })
    }else{
      this.form.patchValue({
        electricity:value
      })
    }
  }

  climaChange(value:any){
    if(value === 'true'){
      this.form.patchValue({
        climatizacion:true
      })
    }else if(value === 'false'){
      this.form.patchValue({
        climatizacion:false
      })
    }else{
      this.form.patchValue({
        climatizacion:value
      })
    }
  }

}
