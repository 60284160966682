<app-loading *ngIf="isLoader"></app-loading>
<section class="page-section" *ngIf="!isLoader">
 <div class="page-section-div">
  <mat-accordion>
    <mat-expansion-panel class="unexpend-header-expension">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'level_col1' | tr}}
        </mat-panel-title>
        <mat-panel-title>
          {{'level_col2' | tr}}
        </mat-panel-title>
        <mat-panel-title>
          {{'level_col3' | tr}}
        </mat-panel-title>
        <mat-panel-title>
          {{'level_col4' | tr}}
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
    <mat-expansion-panel *ngFor="let nivel of uniqNiveles;let index = index">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{nivel.nombre}}
        </mat-panel-title>
        <mat-panel-title>
          {{(nivel.altura * 0.3048).toFixed(2)}}
        </mat-panel-title>
        <mat-panel-title>
          <div (click)="$event.stopPropagation();" style="cursor: pointer;">
            <mat-checkbox class="example-margin"
            [checked]="nivel.exportar"
            color="primary"
            [indeterminate]="nivel.exportar==null?nivel.exportar: !nivel.exportar"
            (change)="exportarChange($event , nivel)">
            </mat-checkbox>
          </div>
        </mat-panel-title>
        <mat-panel-title>
          <div (click)="$event.stopPropagation();" style="cursor: pointer;">
            <mat-checkbox class="example-margin"
            [checked]="nivel.isBajoRasante"
            color="primary"
            [indeterminate]="nivel.isBajoRasante==null?nivel.isBajoRasante: !nivel.isBajoRasante"
            (change)="bajoRasanteChange($event , nivel)">
            </mat-checkbox>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-ground-plants *ngIf="nivel.isBajoRasante" [nombreNivel]="nivel.nombre"></app-ground-plants>
    </mat-expansion-panel>
  </mat-accordion>
 </div>
</section>