
<div class="nav-container">
    <nav class="navbar navbar-expand-md navbar-light navbar-custome">
      <div class="containers">
        <div class="navbar-brand">
          <p class="logo-text" routerLink="/">LetsMEP</p>
        </div>
  
        <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isCollapsed">
  
          <!-- <ul class="navbar-nav mr-auto">
            <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
              <a routerLink="/" class="nav-link">Home</a>
            </li>
            <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
              <a [routerLink]="['project/paramselection/',proyectoService.projectId]" class="nav-link">Parámetros</a>
            </li>
            <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
              <a [routerLink]="['project/habitaciones/',proyectoService.projectId]" class="nav-link">Habitaciones</a>
            </li>
            <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
              <a [routerLink]="['project/familias/',proyectoService.projectId]" class="nav-link">Familias</a>
            </li>
            <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
              <a [routerLink]="['project/',proyectoService.projectId]" class="nav-link">Estructura del proyecto</a>
            </li>
            <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
              <a [routerLink]="['project/patinejos/',proyectoService.projectId]" class="nav-link">Patinejos</a>
            </li>
            <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
              <a [routerLink]="['project/ventanas/',proyectoService.projectId]" class="nav-link">Ventanas</a>
            </li>
            <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
              <button class="btn btn-link p-0" style="min-width: unset" id="qsLogoutBtn" (click)="logout()">
                Log out
              </button>
            </li>
          </ul> -->
          <ul class="navbar-nav ml-perct mt-3" *ngIf="!(page === 'user-info')">
            <li class="nav-item" style="margin-bottom: 0rem;">
              <p class="nav-heading">{{'current_project'|tr}}</p>
              
            </li>
          </ul>
          <ul class="navbar-nav ml-perct mt-3" *ngIf="page ==='user-info'">
            <li class="nav-item" style="margin-bottom: 0rem;">
              <p class="nav-heading">{{'user_information' | tr}}</p>
            </li>
          </ul>
          <ul class="navbar-nav d-md-block ml-auto mr-3 mt-3" *ngIf="auth.user$ | async as user">
            <li class="nav-item">
              <p class="headet-end-text-1">{{'user'|tr}}: {{user.name}}</p>
              <p class="headet-end-text-2">{{user.nickname}}</p>
            </li>
          </ul>
          <ul class="navbar-nav d-md-block ml-auto mr-2 mt-2" *ngIf="!(auth.isAuthenticated$ | async)">
            <li class="nav-item nav-icons" >
                <div class="d-flex align-items-center">
                  <div class="nav-icon-login" (click)="loginWithRedirect()">
                    <p class="headet-end-text-3 mr-1">{{'sign_in'|tr}}</p>
                    <img src="../../../assets/login.png" style="cursor: pointer;width: 42px !important;">
                  </div>
                  <div class="flag-icons ml-3">
                    <select *ngIf="selectLang" [(ngModel)]="selectLang" class="language-select" (change)="onLanguageChange($event.target.value)">
                      <option *ngFor="let x of ['en', 'es']" [value]="x">
                        {{ x }}
                      </option>
                    </select>
                    <img class="ml-1"  *ngIf="selectLang === 'en'" src="../../../assets/english1.png" alt="English" style="width: 16px !important;">
                    <img class="ml-1"  *ngIf="selectLang === 'es'" src="../../../assets/spanish2.png" alt="Spanish" style="width: 16px !important;">
                  </div>
                </div>
            </li>
          </ul>
          <ul class="navbar-nav d-md-block" *ngIf="(auth.isAuthenticated$ | async) && !page">
            <li class="nav-item nav-icons d-flex align-items-center li-navbar-icons" >
              <img src="../../../assets/setting.png" class="mr-md-2 mr-lg-4" routerLink="/user-info">
              <img src="../../../assets/off.png" (click)="logout()">
              <div class="flag-icons ml-lg-3 ml-sm-2">
                <select *ngIf="selectLang"  (change)="onLanguageChange($event.target.value)" [(ngModel)]="selectLang" class="language-select">
                  <option *ngFor="let e of ['en', 'es']; let i = index" [value]="e">
                       {{e }}
                  </option>
              </select>
                <img class="ml-1" *ngIf="selectLang === 'en'" src="../../../assets/english1.png" >
                <img class="ml-1" *ngIf="selectLang === 'es'" src="../../../assets/spanish2.png" >
              </div>
            </li>
            
            <!-- <button (click)="translator.setLanguage(x)" *ngFor="let x of ['en','es']"></button> -->
            <!-- <li class="nav-item dropdown" *ngIf="auth.user$ | async as user" ngbDropdown>
              <a ngbDropdownToggle class="nav-link dropdown-toggle" id="profileDropDown" data-toggle="dropdown">
                <img [src]="user.picture" alt="Profile picture" class="nav-user-profile rounded-circle"
                  style="width: 75px;" />
              </a>
              <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
                <div class="dropdown-header">
                  {{ user.name }}
                </div>
                <a routerLink="/profile" class="dropdown-item dropdown-profile">
                  <fa-icon [icon]="faUser" class="mr-3"></fa-icon> Profile
                </a>
                <button (click)="logout()" class="btn btn-link dropdown-item">
                  <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon> Log out
                </button>
              </div>
            </li> -->
          </ul>
          <ul class="navbar-nav d-md-block" *ngIf="(auth.isAuthenticated$ | async) && page ==='user-info'">
            <li class="nav-item nav-icons  d-flex align-items-center" >
              <img src="../../../assets/Path 3.png" style="cursor: pointer;" class="mr-xl-4 mr-lg-2 ml-xl-4 ml-lg-2 " routerLink="/">
              <div class="flag-icons ml-3">
                <select *ngIf="selectLang" [(ngModel)]="selectLang" class="language-select" (change)="onLanguageChange($event.target.value)">
                  <option *ngFor="let x of ['en', 'es']" [value]="x">
                    {{ x }}
                  </option>
                </select>
                <img class="ml-1"  *ngIf="selectLang === 'en'" src="../../../assets/english1.png" alt="English" style="width: 16px !important;">
                <img class="ml-1"  *ngIf="selectLang === 'es'" src="../../../assets/spanish2.png" alt="Spanish" style="width: 16px !important;">
              </div>
            </li>
          </ul>
  
          <!-- <ul class="navbar-nav d-md-none" *ngIf="(auth.isAuthenticated$ | async) === false">
            <button class="btn btn-primary btn-block" id="qsLoginBtn" (click)="loginWithRedirect()">
              Log in
            </button>
  
          </ul>
          <ul class="navbar-nav d-md-none justify-content-between" *ngIf="auth.user$ | async as user"
            style="min-height: 170px;">
            <li class="nav-item">
              <span class="user-info">
                <img alt="Profile picture" class="nav-user-profile d-inline-block rounded-circle mr-3"
                  style="width: 75px;" [src]="user.picture" />
                <h6 class="d-inline-block">{{ user.name }}</h6>
              </span>
            </li>
            <li>
              <fa-icon [icon]="faUser" class="mr-3"></fa-icon>
              <a routerLink="/profile">Profile</a>
            </li>
  
            <li>
              <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon>
              <button class="btn btn-link p-0" (click)="logout()">
                Log out
              </button>
            </li>
          </ul> -->
          <!-- <div>
            <ul class="navbar-nav mt-3">
                <li class="nav-item">
                  <p class="nav-heading">PROYECTOS ACTUALES</p>
                </li>
            </ul>
            <ul class="navbar-nav d-md-block mr-3 mt-3">
                <li class="nav-item">
                  <p class="headet-end-text-1">Usuaro:alber.castillo@applaid.com</p>
                  <p class="headet-end-text-2">ALBERTO CASTILLO</p>
                </li>
            </ul>
          </div> -->
        </div>
        <button style="position: absolute; right: 1px; top: 15px;" class="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarNav" aria-controls="navbarNav" [attr.aria-expanded]="!isCollapsed"
          aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  </div>
 