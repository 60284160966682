<!-- <div *ngIf="(auth.isAuthenticated$ | async) === true" class="next-steps my-5">
    <button (click)="nuevoProyecto()">Nuevo proyecto</button>
    <app-card
    *ngFor="let project of projects"
    [TituloProyecto]="project.tituloProyecto"
    [IdMunicipio]="project.idMunicipio"
    [CodigoProyecto]="project.codigoProyecto"
    [FechaCreacion]="project.fechaCreacion"
    [projectId]="project.id"
    (click)="project_Click(project)"
  ></app-card>
</div> -->
<section>
  <div class="home-div" *ngIf="(auth.isAuthenticated$ | async)">
  <div *ngIf="!profesionalNotValidated">
    <div class="d-flex justify-content-between">
      <button class="main-btn" (click)="nuevoProyecto()">{{'new_project' | tr}}</button>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 pb-5" *ngFor="let item of projects">
        <div class="card card-custom" (click)="cardClick($event , item.id)">
            <img *ngIf="item.imagen && item.imagen.imagen" [src]="'data:image/jpeg;base64,' + item.imagen.imagen" alt="projectImage" class="card-img-top image-custome" />
          <img *ngIf="!item.imagen || !item.imagen.imagen" class="card-img-top image-custome" src="../../../assets/Card/Mask Group 1.png" alt="Card image cap">
          <div class="card-body">
            <div class="cards-header">
              <p class="cards-heading" title="{{ item.tituloProyecto }}">{{ item.tituloProyecto?.slice(0, 10) }}{{ item.tituloProyecto?.length > 10 ? '...' : '' }}</p>
              <div class="d-flex">
                <div>
                  <img src="../../../assets/holiday_village_FILL0_wght400_GRAD0_opsz48.png" alt="">
                </div>
                <div class="ml-1">
                  <span>{{item.numeroViviendas}}</span>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <p class="cards-sub-heading">{{item.nombreVia + ', ' + item.numero}}</p>
                <p class="small-text">{{item.municipio + ', ' + item.provincia}}</p>
              </div>
              <div>
                <img style="cursor: pointer;" src="../../../assets/delete.png" alt="" (click)="deleteProyecto(item.id);$event.stopImmediatePropagation()">
              </div>
            </div>
            <!-- <div class="card-btn-div">
              <button class="card-btn">{{'finalized' | tr}}</button>
              <p class="date-text small-text">{{item.fechaUltimoAcceso | date : 'd/MM/y'}}</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="profesionalNotValidated">
    <div class="row">
      <div class="col-lg-12 pb-5 logedOff">
        <div class="card card-custom-note">
          <div class="card-body">
            <div class="d-block text-center">
              <h1>{{'user_not_validated_header' | tr}}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="home-div" *ngIf="!(auth.isAuthenticated$ | async)">
    <div class="row">
      <div class="col-lg-12 pb-5 logedOff">
        <div class="card card-custom-note">
          <div class="card-body">
            <div class="d-block text-center">
              <h1>Please login first to see all projects.</h1>
            <Button (click)="loginWithRedirect()" class="login-btn-home">Login Here</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
