<app-loading *ngIf="isLoader"></app-loading>
<div class="page-section" *ngIf="!isLoader">
  <div class="page-section-div">
    <table *ngIf="dataSource?.data.length > 0" mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="margin: 50px;">
      
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-button (click)="toggleExpandCollapse()">
            <mat-icon>{{ isExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
            {{'expandircolapsar' | tr}}
            </button>
        </th>
        <td mat-cell *matCellDef="let data">
          <button mat-icon-button [style.visibility]="!data.expandable ? 'hidden' : ''"
            [style.marginLeft.px]="data.level * 32" (click)="treeControl.toggle(data)">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(data) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <button *ngIf="data.tipo == 'Habitacion'" mat-icon-button (click)="toggleRow(data)">
            <mat-icon class="mat-icon-rtl-mirror">
              add
            </mat-icon>
          </button>
          {{ data.tipo === 'Vivienda' && !data.nombre ? 'Zonas comunes' : (data.tipo + ' - ' + data.nombre) }}
          <button *ngIf="projectService.isRevitData && data.tipo == 'Vivienda'" mat-icon-button (click)="searchVivienda(data)">
            <mat-icon class="mat-icon-rtl-mirror">
              search
            </mat-icon>
          </button>
          <button *ngIf="projectService.isRevitData && data.tipo == 'Habitacion'" mat-icon-button (click)="searchRoom(data)">
            <mat-icon class="mat-icon-rtl-mirror">
              search
            </mat-icon>
          </button>
          <div *ngIf="data.tipo == 'Habitacion' && isRowExpanded(data)">
            <app-habitacion-info [habitacion]="data"></app-habitacion-info>
          </div>
          <span *ngIf="data.tipo == 'Bloque'">  / {{'number_of_houses' | tr}} {{data.length}} </span>
          <span *ngIf="data.tipo == 'Escalera'">  / {{'number_of_houses' | tr}} {{data.length}} </span>
          <span *ngIf="data.tipo == 'Planta'">  / {{'number_of_houses' | tr}} {{data.length}} </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>