import { FlatTreeControl } from '@angular/cdk/tree';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Subject } from 'rxjs';
import { URL_API } from 'src/main';
import { Project, ProjectService } from '../services/project.service';
import { Proyecto } from 'src/app/Shared/modals/proyecto';
import { Vivienda } from 'src/app/Shared/modals/allStructre.interface';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
})



export class ProjectComponent implements OnInit , OnDestroy {

  displayedColumns: string[] = ['nombre'];
  projectId: string = "";
  isLoader:boolean = false;
  private transformer = (node, level: number) => {
    var hijos = [];
    var idElement = "";
    var nivel = "";
    var tipo = "";
    var elementos = [];
    var puertas = [];
    var ventanas = []
    var length = 0;
    if (level == 0) {
      tipo = "Bloque";
      hijos = node.escaleras;
      
      //sumar en length el numero de viviendas que hay en el bloque
      node.escaleras.forEach(escalera => {
        escalera.plantas.forEach(planta => {
          //sumar en length el numero de viviendas que hay en la planta y que no tengan nombre vacio
          planta.viviendas.forEach(vivienda => {
            if (vivienda.nombre != "") length++;
          });
        });
      });
    }
    if (level == 1) {
      tipo = "Escalera";
      hijos = node.plantas;

      //sumar en length el numero de viviendas que hay en la escalera
      node.plantas.forEach(planta => {
        planta.viviendas.forEach(vivienda => {
          if (vivienda.nombre != "") length++;
        });
      });
    }
    if (level == 2) {
      tipo = "Planta";
      hijos = node.viviendas;
      //sumar en length el numero de viviendas que hay en la planta
      node.viviendas.forEach(vivienda => {
        if (vivienda.nombre != "") length++;
      });
    }
    if (level == 3) {
      tipo = "Vivienda";
      hijos = node.habitaciones;
      length = node.habitaciones.length;
    }
    if (level == 4) {
      tipo = "Habitacion";
      idElement = node.idElement;
      nivel = node.nivel;
      elementos = node.elementos;
      puertas = node.puertas;
      ventanas = node.ventanas;
    }
    return {
      expandable: !!hijos && hijos.length > 0,
      nombre: node.nombre,
      level: level,
      idElement: idElement,
      nivel: nivel,
      tipo: tipo,
      elementos: elementos,
      puertas:puertas,
      ventanas:ventanas,
      length:length,
      hijos: hijos
    };
  }

  private getHijos(node: any, level: number): any[] {
    var hijos = [];
    if (node.escaleras != undefined) hijos = node.escaleras;
    else if (node.plantas != undefined) hijos = node.plantas;
    else if (node.viviendas != undefined) hijos = node.viviendas;
    else if (node.habitaciones != undefined) hijos = node.habitaciones;
    return hijos;
  }

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level,
    node => node.expandable, node => this.getHijos(node, node.level));


  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  public webViewMessage = new Subject<any>();

  constructor(public auth: AuthService, 
    private route: ActivatedRoute,
    private router: Router, 
    private http: HttpClient,
    public projectService: ProjectService) {
    this.projectService.setRoomsData();
    this.dataSource.data = [];
    window?.chrome?.webview?.addEventListener('message', event => {
      this.isLoader = true;
      this.webViewMessage.next(event.data);
      
      this.projectService.isRevitData = true;
      if (!event.data.startsWith("ProjectData")) {
        var data = new Project();
        data.idProyecto = this.projectService.projectId.getValue();
        data.bloques = JSON.parse(event.data);
        //this.projectService.saveRevitData(data);

        this.projectService.getRoomsByApi().subscribe(data => {
          this.isLoader =false;
          this.dataSource.data = data.bloques; // Actualiza el DataSource cuando llegan los datos
          //this.treeControl.expandAll();
        },err=>{
          this.isLoader = false;
        });
      }
    });
  }

  ngOnInit() {
    this.isLoader = true;
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.projectService.setProjectId(this.projectId);
    if (this.projectId != this.projectService.projectId.getValue()){
      this.projectService.setprojectData(this.projectId);
      this.projectService.getRoomsByApi().subscribe((data) => {
        this.isLoader = false;
        this.dataSource.data = data.bloques; // Actualiza el DataSource cuando llegan los datos
        //this.treeControl.expandAll();
      }, error =>{
        this.isLoader = false;
      });
    }else{
      this.projectService.getRoomsByApi().subscribe(data => {
        this.isLoader = false;
        this.dataSource.data = data.bloques; // Actualiza el DataSource cuando llegan los datos
        //this.treeControl.expandAll();
      } , error =>{
        this.isLoader = false;
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.projectService.isRevitData || this.projectService.roomsData.getValue().length == 0) {
      //window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'getdataproject' }));
    }
  }

  isExpanded:Boolean = false;
  toggleExpandCollapse() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }

  searchRoom(data) {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchRoom', 'obj': data }));
  }

  searchVivienda(vivienda) {
    console.log(vivienda);
    var a = new Vivienda();
    a.habitaciones = vivienda.hijos;
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchVivienda', 'obj': a }));
  }

  // save() {
  //   const dataToSerialize = new Project ();{
  //     dataToSerialize.idProyecto = this.projectService.projectId,
  //     dataToSerialize.bloques = this.projectService.roomsData.getValue()
  // };
  //   this.projectService.save(dataToSerialize);
  // }


  expandedRows = new Set<any>();

  toggleRow(data: any) {
    if (this.isRowExpanded(data)) {
      this.expandedRows.delete(data);
    } else {
      this.expandedRows.add(data);
    }
  }

  isRowExpanded(data: any) {
    return this.expandedRows.has(data);
  }
  ngOnDestroy(): void{
    window?.chrome?.webview?.removeEventListener('message', event => {
      this.webViewMessage.next(event.data);
    });
  }
}


interface FlatNode {
  nombre: string;
  expandable: boolean;
  level: number;
}

