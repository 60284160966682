import { DatePipe, formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ProjectService } from 'src/app/pages/project/services/project.service';
import { URL_API } from 'src/main';

@Component({
  selector: 'app-card',
  template: `
    <div class="card" (click)="cardClick($event)">
      <div class="body">
        <h3>{{ TituloProyecto }}</h3>
        <h4>{{ IdMunicipio }}</h4>
        <p>{{ CodigoProyecto }}</p>
      </div>
      <div class="footer">
        <button mat-icon-button class="edit-button">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button class="delete-button" (click)="deleteProject()">
          <mat-icon>delete</mat-icon>
        </button>
        <span>{{ GetDateFormated(FechaCreacion) }}</span>
      </div>
    </div>
  `,
  styles: [
    `
      .card {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }

      .header img {
        width: 100%;
        height: auto;
      }

      .body {
        padding: 10px 0;
      }

      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .edit-button {
        position: absolute;
        top: 5px;
        right: 45px;
      }

      .delete-button {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    `
  ]
})
export class CardComponent {
  @Input() TituloProyecto: string;
  @Input() IdMunicipio: string;
  @Input() CodigoProyecto: string;
  @Input() FechaCreacion: Date;
  @Input() projectId: string;
  FechaCreacionFormated: string;
  constructor(@Inject(LOCALE_ID) private locale: string,
    public projectService: ProjectService,
    private router: Router,
    private http: HttpClient,
    public auth: AuthService, ) {
    //this.FechaCreacionFormated = formatDate(this.FechaCreacion, 'yyyy-MM-dd', this.locale);
  }
  GetDateFormated(fecha: Date): string {
    return formatDate(fecha, 'dd-MM-yyyy', this.locale)
  }

  deleteProject() {
    const isConfirmed = window.confirm(`Seguro que quieres eliminar el proyecto "${this.TituloProyecto}"?`);
    if (isConfirmed) {
      const a = this.auth.idTokenClaims$;
      a.subscribe(accessToken => {
        if (accessToken && accessToken != undefined) {

          const httpOptions = {
            headers: new HttpHeaders({
              'Authorization': 'Bearer ' + accessToken.__raw,
              'Access-Control-Allow-Origin': '*',
            })
          };
          this.http.delete(URL_API + '/proyectos/' + this.projectService.projectId.getValue(), httpOptions).subscribe(
            () => {
              window.location.reload();
              // You can add any additional logic here after successful deletion
            },
            (error) => {
              console.error('Error deleting project:', error);
              // Handle any error that might occur during deletion
            }
          );

        }
      })


    }

  }

  cardClick(event: Event) {
    // Check if the clicked target is not a button before calling project_Click()
    const target = event.target as HTMLElement;
    if (!target.closest('button')) {
      this.project_Click();
    }
  }

  project_Click() {
    this.projectService.setProjectId(this.projectId);
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'project', 'obj': this.projectId }));
    this.router.navigate(['/project-home/', this.projectService.projectId.getValue(),0]);
  }
}
