import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { URL_API } from 'src/main';
import { ProjectService } from 'src/app/pages/project/services/project.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { Proyecto } from 'src/app/Shared/modals/proyecto';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { LangTranslatorModule, Translator } from "../../lang-translator/index";
import { MatSelectModule } from '@angular/material/select';
@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.css']
})
export class HomeContentComponent implements OnInit, OnDestroy  {
  faLink = faLink;


  public projects: Proyecto[] = [];
  public projectSelected: string = "";
  public projectId:string;
  latitude:string;
  longitude:string;
  profesionalNotValidated = false;


  public webViewMessage = new Subject<any>();

  

  constructor(
    public api: ApiService,
    public auth: AuthService, 
    private http: HttpClient, 
    private router: Router, 
    private projectService: ProjectService,
    public dialog: MatDialog,
    private apiService: ApiServices,
    public translator: Translator 
    ) {
    window?.chrome?.webview?.addEventListener('message', event => {
      this.webViewMessage.next(event.data);
      if (event.data == 'ProjectOk') {
        this.router.navigate(['/project-home/',]);
      }
    });

    this.getProyectos();
  }

  ngOnInit() {
    // Suponiendo que tienes un servicio 'apiService' que devuelve 'profesional'
    this.api.getProfesional().subscribe(profesional => {
      this.api.profesional = profesional;
      this.profesionalNotValidated = !profesional.isValidated; // Establece la variable de control cuando la suscripción se complete
    });
  }

  public nombreProyecto: string = "";
  public tipoProyecto: string = "";
  nuevoProyecto() {
    this.nombreProyecto = "";
    this.tipoProyecto = "";
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      data: {nombre: this.nombreProyecto, tipoProyecto: this.tipoProyecto},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      this.nombreProyecto = result.nombre;
      this.tipoProyecto = result.tipoProyecto;
      if (this.nombreProyecto != "" && this.nombreProyecto != undefined){
        this.crearProyecto();
      }
    });
  }


  crearProyecto() {
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        var nuevoProyecto = new Proyecto();
        nuevoProyecto.tituloProyecto = this.nombreProyecto;
        nuevoProyecto.tipoProyecto = this.tipoProyecto
        this.http.post<Proyecto>(URL_API + '/proyectos', nuevoProyecto, httpOptions).subscribe(result => {
          this.projects.concat(result);
          this.projectService.setprojectData(result.id);
          window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'project', 'obj': result.id }));
          this.router.navigate(['/project-home/', result.id,0]);
        });
      }
    })
  }

  getProyectos() {
   this.apiService.getProyectos().subscribe(res => {
      this.projects = res;
      console.log('projects',this.projects)
    },err =>{
      this.projects = [];
    })
  }

  deleteProyecto(id:any){
    const message = `Are you sure you want to Delete this?`;
    const dialogData = {
      title:'Confirm Action',
      message:message
    }

    const dialogRef = this.dialog.open(ConfirmationComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.apiService.deleteProyecto(id).subscribe(res =>{
          window.location.reload();
        }, error =>{
          console.error('Error deleting project:', error);
        })
      }
    });
  }
  cardClick(event: Event , id:any) {
    // Check if the clicked target is not a button before calling project_Click()
    const target = event.target as HTMLElement;
    if (!target.closest('button')) {
      this.project_Click(id);
    }
  }

  project_Click(id:any) {
    this.projectId = id;
    this.projectService.setProjectId(this.projectId);
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'project', 'obj': this.projectId }));
    // this.router.navigate(['/project/paramselection', this.projectId]);
    this.router.navigate(['/project-home/', id,0]);
  }
  loginWithRedirect() {
    this.auth.loginWithRedirect().subscribe(result => {
      
    });
  }
  ngOnDestroy(): void {
    window?.chrome?.webview?.removeEventListener('message', event => {
      this.webViewMessage.next(event.data);
    })
  }
  
}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-nombre-proyecto.component.html',
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule, FormsModule, MatButtonModule,LangTranslatorModule],
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public translator: Translator
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface DialogData {
  nombre: string;
  tipoProyecto: string; 
}

declare global {
  interface Window {
    chrome: any;
  }
}

