import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from 'src/main';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, switchMap } from 'rxjs/operators'; // Corrected import statement
import { Promotor, Puertas } from '../modals/puertas.interface';
import { Proyecto } from '../modals/proyecto';
import { Profesional } from '../modals/Profesional.interface';
import { BehaviorSubject, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiServices {
  
  
  constructor(private http: HttpClient, private auth: AuthService) { }
  getProyectos() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        return this.http.get<Proyecto[]>(URL_API + '/proyectos', httpOptions);
      }
    }));
  }
  deleteProyecto(proyectoId: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        return this.http.delete(URL_API + '/proyectos/' + proyectoId, httpOptions);
      }
    }));
  }
  getProyectoById(projectId: string) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(
        switchMap(accessToken => {
            if (accessToken && accessToken != undefined) {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'Authorization': 'Bearer ' + accessToken.__raw,
                        'Access-Control-Allow-Origin': '*'
                    })
                };
                return this.http.get<Puertas[]>(URL_API + '/proyectos/' + projectId, httpOptions).pipe(
                    catchError(error => {
                        // Si el error es un 404, lanzar un error personalizado o devolver un valor observable.
                        if (error.status === 404) {
                            return of({ notFound: true });
                        }
                        throw error;
                    })
                );
            } else {
                return of(null); // Manejar caso donde no haya accessToken
            }
        })
    );
}
  getPuertas(projectId) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get<Puertas[]>(URL_API + '/proyectos/puertas/' + projectId, httpOptions)
      }
    }));
  }
  getVentana(projectId) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get<Puertas[]>(URL_API + '/proyectos/ventanas/' + projectId, httpOptions)
      }
    }));
  }



  //project data page apis
  getLanguage() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get<Puertas[]>(URL_API + '/idioma', httpOptions)
      }
    }));
  }

  getPromotor() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get<Promotor[]>(URL_API + '/promotor', httpOptions)
      }
    }));
  }

  updatePromotor(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.post(URL_API + '/promotor/proyecto', data, httpOptions);
      }
    }));
  }
  updateLanguage(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.put(URL_API + '/proyectos/idioma', data, httpOptions);
      }
    }));
  }
  updateQuality(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.put(URL_API + '/proyectos/calidades', data, httpOptions);
      }
    }));
  }
  updateEnergeticCertification(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.put(URL_API + '/proyectos/certificacion', data, httpOptions);
      }
    }));
  }
  updateStandardSpecifications(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.put(URL_API + '/proyectos/libroblanco', data, httpOptions);
      }
    }));
  }



  getAir_conditions() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get<Puertas[]>(URL_API + '/marcas/climatizacion', httpOptions)
      }
    }));
  }
  UpdateAirconditions(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.put(URL_API + '/proyectos/marca/climatizacion', data, httpOptions);
      }
    }));
  }


  getDomestics() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get<Puertas[]>(URL_API + '/marcas/acs', httpOptions)
      }
    }));
  }
  UpdateDomestics(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.put(URL_API + '/proyectos/marca/acs', data, httpOptions);
      }
    }));
  }


  getVentilations() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get<Puertas[]>(URL_API + '/marcas/ventilacion', httpOptions)
      }
    }));
  }
  UpdateVentilations(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.put(URL_API + '/proyectos/marca/ventilacion', data, httpOptions);
      }
    }));
  }



  //geting userinfo
  getUserInfo() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get<Profesional>(URL_API + '/profesional', httpOptions);
      }
    }));
  }


  //updating user data
  addUserInfo(data: Profesional) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.put(URL_API + `/profesional`, data, httpOptions);
      }
    }));
  }


  //puerta page apis
  updatedStair(data) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.post(URL_API + '/proyectos/puertaescalera', data, httpOptions);
      }
    }));
  }
  updatedPrinciple(data) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.post(URL_API + '/proyectos/puertaprincipal', data, httpOptions);
      }
    }));
  }
  updatedVentana(data) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.post(URL_API + '/proyectos/puertaventana', data, httpOptions);
      }
    }));
  }
  updatedIndividualStair(data) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.post(URL_API + '/proyectos/puertaescaleraindividual', data, httpOptions);
      }
    }));
  }
  updatedIndividualPrinciple(data) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.post(URL_API + '/proyectos/puertaprincipalindividual', data, httpOptions);
      }
    }));
  }
  updatedIndividualVentana(data) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.post(URL_API + '/proyectos/puertaventanaindividual', data, httpOptions);
      }
    }));
  }

  AddLocation(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.put(URL_API + '/proyectos/map', data, httpOptions);
      }
    }));
  }
  UpdateProjectName(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.put(URL_API + '/proyectos/tituloproyecto', data, httpOptions);
      }
    }));
  }

  //getLocation
  getLocation(lat: number, lang: number) {
    const gooleApiKey = 'AIzaSyB6bsHfMS42qmcAFApz96bGNV5uHFh3dYI'
    const googleLocationApi = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lang}&key=${gooleApiKey}`;
    return this.http.get(googleLocationApi);
  }

  //plants
  getSobrerasante(projectId: string) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get(URL_API + '/proyectos/plantas/sobrerasante/' + projectId, httpOptions)
      }
    }));
  }
  getBajorasante(projectId: string) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get(URL_API + '/proyectos/plantas/bajorasante/' + projectId, httpOptions)
      }
    }));
  }
  getBajorasanteDatos(projectId: string) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined){
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        return this.http.get(URL_API + '/proyectos/plantas/bajorasantedatos/' + projectId, httpOptions);
      }
    }));
  }
  postSobrerasante(idProyect: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.post(URL_API + '/proyectos/plantas/bajorasante/', idProyect, httpOptions);
      }
    }));
  }

  postCambioSobrerasante(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined){
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        return this.http.post(URL_API + '/proyectos/plantas/cambiarasobrerasante/', data, httpOptions);
      }
    }));
  }

  postCambioBajorasante(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined){
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        return this.http.post(URL_API + '/proyectos/plantas/cambiarabajorasante/', data, httpOptions);
      }
    }));
  }

  getUrbanizacion(projectId: string) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get(URL_API + '/proyectos/urbanizacion/' + projectId, httpOptions)
      }
    }));
  }

  postUrbanizacion(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined){
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        return this.http.post(URL_API + '/proyectos/urbanizacion/', data, httpOptions);
      }
    }));
  }
  postBajorasante(idProyect: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.post(URL_API + '/proyectos/plantas/bajorasante/', idProyect, httpOptions);
      }
    }));
  }
  calculateProject(payload: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.post(URL_API + '/proyectos/calculateproject/', payload, httpOptions);
      }
    }));
  }
  UpdateInstallation(data: any) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.put(URL_API + '/proyectos/instalaciones', data, httpOptions);
      }
    }));
  }
  getInstallationById(id: string) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/' + id, httpOptions)
      }
    }));
  }
  getInstallationPlumbingGroupPressure() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/fontaneria/marcas/grupopresion', httpOptions)
      }
    }));
  }
  getInstallationPlumbingMaterial() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/fontaneria/marcas/material', httpOptions)
      }
    }));
  }

  getInstallationSalubrityType() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/salubridad/tipo', httpOptions)
      }
    }));
  }
  getInstallationSalubrityMaterial() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/salubridad/sistema', httpOptions)
      }
    }));
  }
  getInstallationSalubrityBrand() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/salubridad/marca', httpOptions)
      }
    }));
  }
  getInstallationSanitationType() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/saneamiento/tipored', httpOptions)
      }
    }));
  }

  getidInstalaciones_Tipo() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/electricidad/tipocuadro', httpOptions)
      }
    }));
  }

  getInstallationClimatizacionType() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/climatizacion/tiposistema', httpOptions);
      }
    }));
  }

  getInstallationClimatizacionSistema() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined){
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/climatizacion/sistema', httpOptions);
      }
    }));
  }

  getInstallationClimatizacionMarca() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined){
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/climatizacion/marca', httpOptions);
      }
    }));
  }

  getInstallationClimatizacionCargas() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined){
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/climatizacion/cargas', httpOptions);
      }
    }));
  }

  getInstallationClimatizacionEmisorCalor() {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined){
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        return this.http.get(URL_API + '/proyectos/instalaciones/climatizacion/emisorcalor', httpOptions);
      }
    }));
  }



  getProjectResume(id:string) {
    const a = this.auth.idTokenClaims$;
    return a.pipe(switchMap(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        return this.http.get(URL_API + '/proyectos/resume/'+id, httpOptions)
      }
    }));
  }
}
