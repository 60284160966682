import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { URL_API } from 'src/main';
import { ProjectService, TipoPatinejo } from '../services/project.service';
import { element } from 'protractor';
import { ToastrService } from 'ngx-toastr';
import { Patinejo, ProyectoPatinejo_TipoPatinejoMontante_Web } from 'src/app/Shared/modals/patinejo.interface';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-patinejos',
  templateUrl: './patinejos.component.html',
  styleUrls: ['./patinejos.component.scss'],
})



export class PatinejosComponent implements OnInit {

  patinejos: Patinejo[] = [];
  filteredPatinejos: Patinejo[] = [];

  montantes: ProyectoPatinejo_TipoPatinejoMontante_Web[] = [];
  // tipoPatinejoMontanteSeleccionado: TipoPatinejoMontante[][] = [];
  isLoader: boolean = false;

  nombreInstalaciones: string[] = []; // Array para almacenar los nombres de instalaciones
  selectedNombresInstalaciones: string[] = []; // Array para almacenar las selecciones del usuario

  nombreBloques: string[] = []; // Array para almacenar los nombres de instalaciones
  selectedBloques: string[] = []; // Array para almacenar las selecciones del usuario

  nombreEscaleras: string[] = []; // Array para almacenar los nombres de instalaciones
  selectedEscaleras: string[] = []; // Array para almacenar las selecciones del usuario

  nombrePlantas: string[] = []; // Array para almacenar los nombres de instalaciones
  selectedPlantas: string[] = []; // Array para almacenar las selecciones del usuario

  nombreViviendas: string[] = []; // Array para almacenar los nombres de instalaciones
  selectedViviendas: string[] = []; // Array para almacenar las selecciones del usuario


  constructor(public auth: AuthService, private route: ActivatedRoute, private http: HttpClient, public projectService: ProjectService, private toastr: ToastrService) {
    window?.chrome?.webview?.addEventListener('message', event => {
      this.messageFromRevit(event)
    });
  }

  messageFromRevit(event: any) {
    
    if(event.data == "recalcularPatinejos"){
      if(!this.patinejos || this.patinejos.length == 0){

        this.recalcular();
      }
      else{
        this.isLoader = true;
        this.inicializarDatos();
      }
    }
  }

  async ngOnInit() {
    this.isLoader = true;
    this.projectService.setProjectId(this.route.snapshot.paramMap.get('id'));
    //this.recalcular();
    this.inicializarDatos();
  }
  private inicializarDatos() {
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        this.http.get<Patinejo[]>(URL_API + '/proyectos/patinejos/' + this.projectService.projectId.getValue(), httpOptions).subscribe(async (result) => {
          this.patinejos = await result;
          this.patinejos.forEach(patinejo => {
            patinejo.numeroUbicaciones = 0;
            patinejo.montantes.forEach(montante => {
             montante.numeroUbicaciones = 0;
              montante.ubicaciones.forEach(ubicacion => {
                if (ubicacion.bloque != null) {
                  montante.numeroUbicaciones = montante.numeroUbicaciones + 1;
                  //comprobar si ya existe el bloque
                  if (!this.nombreBloques.includes(ubicacion.bloque.nombre)) {
                    this.nombreBloques.push(ubicacion.bloque.nombre);
                  }
                }
                if (ubicacion.escalera != null) {
                  //comprobar si ya existe la escalera
                  if (!this.nombreEscaleras.includes(ubicacion.escalera.nombre)) {
                    this.nombreEscaleras.push(ubicacion.escalera.nombre);
                  }
                }
                if (ubicacion.planta != null) {
                  //comprobar si ya existe la planta
                  if (!this.nombrePlantas.includes(ubicacion.planta.nombre)) {
                    this.nombrePlantas.push(ubicacion.planta.nombre);
                  }
                }
                if (ubicacion.vivienda != null) {
                  //comprobar si ya existe la vivienda
                  if (!this.nombreViviendas.includes(ubicacion.vivienda.nombre)) {
                    this.nombreViviendas.push(ubicacion.vivienda.nombre);
                  }
                }
              });
              patinejo.numeroUbicaciones += montante.numeroUbicaciones;
            });
            this.nombreInstalaciones.push(patinejo.nombreInstalacion);
          });
          this.applyFilters();
          this.isLoader = false;
        });
      } else {
        this.isLoader = false;
      }
    });
  }

  // Eliminar un elemento seleccionado
  removeInstalacion(item) {
    this.selectedNombresInstalaciones = this.selectedNombresInstalaciones.filter(i => i !== item);
    this.onFilterChange();
  }
  removeBloque(item) {
    this.selectedBloques = this.selectedBloques.filter(i => i !== item);
    this.onFilterChange();
  }
  removeEscalera(item) {
    this.selectedEscaleras = this.selectedEscaleras.filter(i => i !== item);
    this.onFilterChange();
  }
  removePlanta(item) {
    this.selectedPlantas = this.selectedPlantas.filter(i => i !== item);
    this.onFilterChange();
  }
  removeVivienda(item) {
    this.selectedViviendas = this.selectedViviendas.filter(i => i !== item);
    this.onFilterChange();
  }

  handleSelectionChange(event: Event) {
    const selectedOptions = (event.target as HTMLSelectElement).selectedOptions;
    this.selectedNombresInstalaciones = Array.from(selectedOptions).map(option => option.value);
  }

  applyFilters() {
    // Filtrar los patinejos
    var a = this.patinejos
      .map(patinejo => {
        // Crear una copia de los montantes filtrados
        const filteredMontantes = patinejo.montantes.map(montante => {
          // Filtrar ubicaciones dentro de cada montante
          const filteredUbicaciones = montante.ubicaciones.filter(ubicacion => {
            // Aplicar filtros individuales
            const matchInstalaciones = this.selectedNombresInstalaciones.length === 0 || this.selectedNombresInstalaciones.includes(patinejo.nombreInstalacion);
            const matchBloques = this.selectedBloques.length === 0 || this.selectedBloques.includes(ubicacion.bloque?.nombre);
            const matchEscaleras = this.selectedEscaleras.length === 0 || this.selectedEscaleras.includes(ubicacion.escalera?.nombre);
            const matchPlantas = this.selectedPlantas.length === 0 || this.selectedPlantas.includes(ubicacion.planta?.nombre);
            const matchViviendas = this.selectedViviendas.length === 0 || this.selectedViviendas.includes(ubicacion.vivienda?.nombre);

            // Devolver true solo si todas las condiciones coinciden
            return matchInstalaciones && matchBloques && matchEscaleras && matchPlantas && matchViviendas;
          });

          // Retornar el montante con las ubicaciones filtradas
          return { ...montante, ubicaciones: filteredUbicaciones };
        }).filter(montante => montante.ubicaciones.length > 0); // Filtrar montantes vacíos

        // Retornar el patinejo con los montantes filtrados
        return { ...patinejo, montantes: filteredMontantes };
      }).filter(patinejo => patinejo.montantes.length > 0); // Filtrar patinejos sin montantes
    this.filteredPatinejos = a;
    return a;
  }

  onFilterChange() {
    this.applyFilters();
  }

  resetFilters() {
    this.onFilterChange();
  }

  openPatinejos() {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'openPatinejos' }));
  }

  searchRoom(data) {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchRoom', 'obj': data }));
  }

  toggled: boolean = false;

  toggleAllDetails() {
    this.filteredPatinejos.forEach(patinejo => {
      patinejo.detailsVisible = !this.toggled;
      patinejo.montantes.forEach(montante => {
        montante.showLocations = !this.toggled;
      });
    });
    this.toggled = !this.toggled;
  }

  recalcular() {
    this.isLoader = true;
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        console.log(httpOptions);
        this.http.patch(URL_API + '/proyectos/patinejos/' + this.projectService.projectId.getValue(),null, httpOptions).subscribe((result) => {
          this.toastr.success('Recálculo realizado correctamente');
          this.inicializarDatos();
        });
      }
    });
  }

  onReloadChild() {
    console.log('onReloadChild');
    this.isLoader = true;
    this.inicializarDatos();
  }

}
