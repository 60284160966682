<app-loading *ngIf="isLoader"></app-loading>
<div class="page-section" *ngIf="!isLoader">
 <div class="page-section-div">
  <h2>{{'types_of_families'| tr}}</h2>
  <button mat-raised-button color="primary" style="margin-bottom: 20px;" (click)="filtrar()">{{filter===true?('textoFiltro1' |tr):('textoFiltro2' |tr)}}</button>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let familia of filtrarfamiliasConNombreNoVacio()" hideToggle>
      <mat-expansion-panel-header style="height: fit-content;">
        <mat-panel-title>
          <div>
            <h4 >{{familia.nombreFamilia}}</h4>
            <p>{{familia.nombre}}</p>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          {{getNombre(familia)}}
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <div class="container">
        <div class="header">
          <h4>{{'familias_heading_select1' | tr}}</h4>
          <p>{{'familias_text_select1' | tr}}.</p>
        </div>
        <div class="form-field">
          <mat-form-field>
            <mat-label>{{'familias_label_select1' | tr}}</mat-label>
            <mat-select [(ngModel)]="selectedTipoFamilia[familia.nombreFamilia+familia.nombre]"
            (selectionChange)="storeFamilyType(familia, selectedTipoFamilia[familia.nombreFamilia+familia.nombre])">
              <mat-option *ngFor="let tipo of projectService.tipoFamilia" [value]="tipo">
                {{tipo.nombre}}
              </mat-option>
          </mat-select>
          </mat-form-field>
        </div>
      
      </div>
    </mat-expansion-panel>
  </mat-accordion>
 </div>
</div>