<app-loading *ngIf="isLoader || isRevitLoad"></app-loading>
<div *ngIf="!isLoader && !isRevitLoad">
  <section class="page-section">
    <div class="page-section-div">
      <div class="container">
        <div class="header">
          <h4>{{'paramSelection_heading1' | tr}}</h4>
          <p>{{'paramSelection_text1' | tr}}</p>
        </div>
        <div class="form-field">
          <mat-form-field class="mat-form-field">
            <mat-label>{{'paramSelection_placeholder'| tr}}</mat-label>
            <input type="text"
           aria-label="Number4"
           matInput
           required 
           [formControl]="bloqueControl"
           [matAutocomplete]="auto1">
           <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
             <mat-option *ngFor="let option5 of filteredOptionsBloque | async" [value]="option5">
               {{option5}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="bloqueError">{{bloqueError}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="container">
        <div class="header">
          <h4>{{'paramSelection_heading2' | tr}}</h4>
          <p>{{'paramSelection_text2' | tr}}</p>
        </div>
        <div class="form-field">
          <mat-form-field class="mat-form-field">
            <mat-label>{{'paramSelection_placeholder'| tr}}</mat-label>
            <input type="text"
           aria-label="Number3"
           matInput
           required 
           [formControl]="escaleraControl"
           [matAutocomplete]="auto2">
              <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                <mat-option *ngFor="let option4 of filteredOptionsEscalera | async" [value]="option4">
                  {{option4}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="escaleraError">{{ escaleraError }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="container">
        <div class="header">
          <h4>{{'paramSelection_heading3' | tr}}</h4>
          <p>{{'paramSelection_text3' | tr}}</p>
        </div>
        <div class="form-field">
          <mat-form-field class="mat-form-field">
            <mat-label>{{'paramSelection_placeholder'| tr}}</mat-label>
            <input type="text"
           aria-label="Number2"
           matInput
           required 
           [formControl]="plantaControl"
           [matAutocomplete]="auto3">
              <mat-autocomplete autoActiveFirstOption #auto3="matAutocomplete">
                <mat-option *ngFor="let option3 of filteredOptionsPlanta | async" [value]="option3">
                  {{option3}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="plantaError">{{ plantaError }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="container">
        <div class="header">
          <h4>{{'paramSelection_heading4' | tr}}</h4>
          <p>{{'paramSelection_text4' | tr}}</p>
        </div>
        <div class="form-field">
          <mat-form-field class="mat-form-field">
            <mat-label>{{'paramSelection_placeholder'| tr}}</mat-label>
            <input type="text"
           aria-label="Number1"
           matInput
           required 
           [formControl]="viviendaControl"
           [matAutocomplete]="auto4">
              <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete">
                <mat-option *ngFor="let option2 of filteredOptionsVivienda | async" [value]="option2">
                  {{option2}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="viviendaError">{{ viviendaError }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="container">
        <div class="header">
          <h4>{{'paramSelection_heading5' | tr}}</h4>
          <p>{{'paramSelection_text5' | tr}}</p>
        </div>
        <div class="form-field">
          <mat-form-field class="mat-form-field">
            <mat-label>{{'paramSelection_placeholder'| tr}}</mat-label>

            <input type="text"
           aria-label="Number"
           matInput
           required 
           [formControl]="habitacionControl"
           [matAutocomplete]="auto5">
              <mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete">
                <mat-option *ngFor="let option1 of filteredOptionsHabitacion | async" [value]="option1">
                  {{option1}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="habitacionError">{{ habitacionError }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="container">
        <button class="save-btn" 
          [disabled]="!bloqueControl.value || 
                     !escaleraControl.value || 
                     !plantaControl.value || 
                     !viviendaControl.value || 
                     !habitacionControl.value || 
                     bloqueError || 
                     escaleraError || 
                     plantaError || 
                     viviendaError || 
                     habitacionError" 
          [ngClass]="{'save-btn-disabled': !bloqueControl.value || 
                                             !escaleraControl.value || 
                                             !plantaControl.value || 
                                             !viviendaControl.value || 
                                             !habitacionControl.value || 
                                             bloqueError || 
                                             escaleraError || 
                                             plantaError || 
                                             viviendaError || 
                                             habitacionError}" 
          (click)="save()">
          {{'save' | tr}}
        </button>
      </div>
    </div>
  </section>
</div>