import { Component ,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { ProjectService } from '../services/project.service';
import { ResumeStatus, resume } from 'src/app/Shared/modals/allStructre.interface';

@Component({
  selector: 'app-project-resume',
  templateUrl: './project-resume.component.html',
  styleUrls: ['./project-resume.component.css']
})
export class ProjectResumeComponent implements OnInit{
  isLoader:boolean = false;
  projectId:string;
  projectResumeData: resume;
  constructor(private apiService: ApiServices , private route: ActivatedRoute , private toastr : ToastrService , public projectService : ProjectService,private router:Router){
    this.projectId = this.route.snapshot.paramMap.get('id');
  }
  ngOnInit(): void {
    if(this.projectId){
          this.isLoader = true;
          this.apiService.getProjectResume(this.projectId).subscribe((res:resume) =>{
            this.isLoader = false;
            this.projectResumeData = res;
            console.log('projectResumeData:',this.projectResumeData)
          })
    }
  }
  goTo(stepIndex:number){
    this.router.navigate(['/project-home/', this.projectService.projectId.getValue(),stepIndex]);
    setTimeout(() => {
        window.location.reload()
    }, 0);
  }
}
