import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { URL_API } from 'src/main';
import { ProjectService } from '../services/project.service';
import { Ventana } from 'src/app/Shared/modals/ventana.interface';
import { ApiServices } from 'src/app/Shared/services/api.service';

@Component({
  selector: 'app-ventanas',
  templateUrl: './ventanas.component.html',
  styleUrls: ['./ventanas.component.css'],
})



export class VentanasComponent implements OnInit {

  projectId: string = "";
  ventanas: Ventana[] = [];
  isLoader:boolean= false;

  filter: boolean = false;

  constructor(public auth: AuthService,
    private route: ActivatedRoute,
    private http: HttpClient,
    public projectService: ProjectService,
    private cdRef: ChangeDetectorRef,private apiService:ApiServices) {


  }

  ngOnInit() {
    this.isLoader = true;
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.projectService.setProjectId(this.projectId);
    this.apiService.getVentana(this.projectId).subscribe((result:any) => {
      this.isLoader = false;
      this.ventanas = this.getUniqueVentanas(result);
    },err =>{
      console.log(err)
      this.isLoader = false;
    }
    )
  }
  getUniqueVentanas(ventanas: Ventana[]): Ventana[] {
    const uniqueVentanas: Ventana[] = [];
    const uniqueKeys = new Set<string>();
    // Recorrer todas las ventanas recibidas en el resultado
    ventanas.forEach(ventana => {
      const key = ventana.nombreFamilia + ventana.nombre;
      // Si el nombreVentana + nombre aún no existe en el Set uniqueKeys, agregarlo al arreglo uniqueVentanas y al Set uniqueKeys

      if (!uniqueKeys.has(key)) {
        uniqueKeys.add(key);
        uniqueVentanas.push(ventana);
      }
    });
    
    return uniqueVentanas;
  }

  filtrarventanasConNombreNoVacio() {
    if (this.filter) {
      return this.ventanas.filter(ventana => {
        const nombre = this.getNombre(ventana);
        return nombre.trim() === ''; // Filtrar solo si el nombre no está vacío (sin espacios)
      });
    } else {
    }
    return this.ventanas;
  }

  textoFiltro: string = "Quitar asignados";
  filtrar() {
    this.filter = !this.filter;
    if (this.filter) {
      this.textoFiltro = "Mostrar todos";
    } else {
      this.textoFiltro = "Quitar asignados";
    }
    this.cdRef.detectChanges();
  }



  getNombre(ventana: Ventana) {
    return "";
  }


  onInputBlur(event: any, ventana: Ventana) {
    const a = this.auth.idTokenClaims$;
        a.subscribe(accessToken => {
            if (accessToken && accessToken != undefined) {

                const httpOptions = {
                    headers: new HttpHeaders({
                        'Authorization': 'Bearer ' + accessToken.__raw,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    })
                };
                const dataToSerialize = {
                  idProyecto: this.projectService.projectId.getValue(),
                  nombreFamilia: ventana.nombreFamilia,
                  nombre: ventana.nombre,
                  u: event.target.value,
                };
                this.http.post<any>(URL_API + '/proyectos/ventanau', JSON.stringify(dataToSerialize), httpOptions).subscribe(result => {
                },
                error => {
                  console.log(error);
                });
            }
        })
  }


}