<app-loading *ngIf="isLoader"></app-loading>
<section class="page-section" *ngIf="!isLoader">
    <div class="over-plant-selection-main col-custome">
        <div class="">
            <div class="d-flex header-icon pb-lg-3 pb-sm-0">
                <img src="../../../../assets/icons/level-up.png" alt="level-down" srcset="" height="24"
                    class="mr-lg-3 mr-sm-1">
                <h4 class="main-heading">{{'installation' | tr}}</h4>
            </div>
            <div class="row pr-lg-5 pr-sm-0 pl-lg-5 pl-sm-0">
                <form [formGroup]="form" (submit)="SaveInstallation()">
                    <div class="col-12 ">
                        <div *ngIf="!projectService.project || projectService.project.tipoProyecto !== 'salubridad'">
                            <div class="row page-content-row">
                                <div class="col-md-6 col-sm-12">
                                    <p class="page-heading">{{'instal_label1' | tr}}</p>
                                </div>
                                <div class="col-md-5 col-sm-12 offset-md-1">
                                    <mat-form-field class="mat-field">
                                        <mat-label *ngIf="!form.value.plumbing">{{'si'| tr}}</mat-label>
                                        <select matNativeControl formControlName="plumbing"
                                            (change)="plumbingChange($event.target.value)">
                                            <option [value]="true">{{'yes' | tr}}</option>
                                            <option [value]="false">{{'no' | tr}}</option>
                                        </select>
                                    </mat-form-field>

                                </div>
                            </div>
                            <div *ngIf="form.value.plumbing">
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label2' | tr}}</p>
                                        <p class="page-text">{{'instal_sub_label2' | tr}}</p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.pressure">{{'instal_placeholder2' |
                                                tr}}</mat-label>
                                            <input matInput type="text" formControlName="pressure">
                                        </mat-form-field>

                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label3' | tr}}</p>
                                        <p class="page-text"></p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.cistren">{{'si'| tr}}</mat-label>
                                            <select matNativeControl formControlName="cistren">
                                                <option [value]="true">{{'yes' | tr}}</option>
                                                <option [value]="false">{{'no' | tr}}</option>
                                            </select>
                                        </mat-form-field>

                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label4' | tr}}</p>
                                        <p class="page-text">{{'instal_placeholder3' | tr}}</p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.pressureGroup">{{'instal_placeholder3' |
                                                tr}}</mat-label>
                                            <select matNativeControl formControlName="pressureGroup">
                                                <option *ngFor="let item of plumbingPressureList" [value]="item.id">
                                                    {{item.nombre}}</option>
                                            </select>
                                        </mat-form-field>

                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label5' | tr}}</p>
                                        <p class="page-text">{{'instal_placeholder4' | tr}}</p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.piping">{{'instal_placeholder4' |
                                                tr}}</mat-label>
                                            <select matNativeControl formControlName="piping">
                                                <option *ngFor="let item of plumbingMaterialList" [value]="item.id">
                                                    {{item.nombre}}</option>
                                            </select>
                                        </mat-form-field>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row page-content-row">
                                <div class="col-md-6 col-sm-12">
                                    <p class="page-heading">{{'salubridad' | tr}}</p>
                                </div>
                                <div *ngIf="!projectService.project || projectService.project.tipoProyecto !== 'salubridad'" class="col-md-5 col-sm-12 offset-md-1">
                                    <mat-form-field class="mat-field">
                                        <mat-label *ngIf="!form.value.salubrity">{{'si'| tr}}</mat-label>
                                        <select matNativeControl formControlName="salubrity"
                                            (change)="salubrityChange($event.target.value)">
                                            <option [value]="true">{{'yes' | tr}}</option>
                                            <option [value]="false">{{'no' | tr}}</option>
                                        </select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="form.value.salubrity">
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label7' | tr}}</p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.type">{{'instal_placeholder5' |
                                                tr}}</mat-label>
                                            <select matNativeControl formControlName="type">
                                                <option *ngFor="let item of salubrityTypeList" [value]="item.id">
                                                    {{item.nombre}}</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label8' | tr}}</p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.sistem">{{'instal_placeholder6' |
                                                tr}}</mat-label>
                                            <select matNativeControl formControlName="sistem">
                                                <option *ngFor="let item of salubrityMaterialList" [value]="item.id">
                                                    {{item.nombre}}</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label9' | tr}}</p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.brand">{{'instal_placeholder7' |
                                                tr}}</mat-label>
                                            <select matNativeControl formControlName="brand">
                                                <option *ngFor="let item of salubrityBrand" [value]="item.id">
                                                    {{item.nombre}}</option>
                                            </select>
                                        </mat-form-field>
                                        <!-- <div class="error-message"
                                            *ngIf="form.get('brand').hasError('required') && form.get('brand').touched">
                                            *Field is required.
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!projectService.project || projectService.project.tipoProyecto !== 'salubridad'">
                            <div class="row page-content-row">
                                <div class="col-md-6 col-sm-12">
                                    <p class="page-heading">{{'saneamiento' | tr}}</p>
                                </div>
                                <div  class="col-md-5 col-sm-12 offset-md-1">
                                    <mat-form-field class="mat-field">
                                        <mat-label *ngIf="!form.value.sanitation">{{'si'| tr}}</mat-label>
                                        <select matNativeControl formControlName="sanitation"
                                            (change)="sanitationChange($event.target.value)">
                                            <option [value]="true">{{'yes' | tr}}</option>
                                            <option [value]="false">{{'no' | tr}}</option>
                                        </select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="form.value.sanitation">
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label10' | tr}}</p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.typeRed">{{'instal_placeholder8' |
                                                tr}}</mat-label>
                                            <select matNativeControl formControlName="typeRed">
                                                <option *ngFor="let item of sanitationTypeList" [value]="item.id">
                                                    {{item.nombre}}</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!projectService.project || projectService.project.tipoProyecto !== 'salubridad'">
                            <div class="row page-content-row">
                                <div class="col-md-6 col-sm-12">
                                    <p class="page-heading">{{'instal_label12' | tr}}</p>
                                </div>
                                <div class="col-md-5 col-sm-12 offset-md-1">
                                    <mat-form-field class="mat-field">
                                        <mat-label *ngIf="!form.value.electricity">{{'si'| tr}}</mat-label>
                                        <select matNativeControl formControlName="electricity"
                                            (change)="electricityChange($event.target.value)">
                                            <option [value]="true">{{'yes' | tr}}</option>
                                            <option [value]="false">{{'no' | tr}}</option>
                                        </select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="form.value.electricity">
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'potenciaVehiculoElectrico' | tr}}</p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.potenciaVehiculosElectricos">{{'potenciaVehiculoElectrico_placeholder' | tr}}</mat-label>
                                            <input matInput type="text" formControlName="potenciaVehiculosElectricos">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label13' | tr}}</p>
                                        <p class="page-text"></p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.borna">{{'si'| tr}}</mat-label>
                                            <select matNativeControl formControlName="borna">
                                                <option [value]="true">{{'yes' | tr}}</option>
                                                <option [value]="false">{{'no' | tr}}</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label16' | tr}}</p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.IdElectricidad_TipoCuadro">{{'instal_placeholder9' |
                                                tr}}</mat-label>
                                            <select matNativeControl formControlName="IdElectricidad_TipoCuadro">
                                                <option *ngFor="let item of tipoCuadroList" [value]="item.id">
                                                    {{item.nombre}}</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label14' | tr}}</p>
                                        <p class="page-text"></p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.toalleroElectrico">{{'si'| tr}}</mat-label>
                                            <select matNativeControl formControlName="toalleroElectrico">
                                                <option [value]="true">{{'yes' | tr}}</option>
                                                <option [value]="false">{{'no' | tr}}</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label15' | tr}}</p>
                                        <p class="page-text"></p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.persianaElectrica">{{'si'| tr}}</mat-label>
                                            <select matNativeControl formControlName="persianaElectrica">
                                                <option [value]="true">{{'yes' | tr}}</option>
                                                <option [value]="false">{{'no' | tr}}</option>
                                            </select>
                                        </mat-form-field>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!projectService.project || projectService.project.tipoProyecto !== 'salubridad'">
                            <div class="row page-content-row">
                                <div class="col-md-6 col-sm-12">
                                    <p class="page-heading">{{'clima' | tr}}</p>
                                </div>
                                <div class="col-md-5 col-sm-12 offset-md-1">
                                    <mat-form-field class="mat-field">
                                        <mat-label *ngIf="!form.value.climatizacion">{{'si'| tr}}</mat-label>
                                        <select matNativeControl formControlName="climatizacion"
                                            (change)="climaChange($event.target.value)">
                                            <option [value]="true">{{'yes' | tr}}</option>
                                            <option [value]="false">{{'no' | tr}}</option>
                                        </select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="form.value.climatizacion">
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label7' | tr}}</p> <!-- tipo-->
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.idClimatizacion_Tipo">{{'instal_placeholder5' | tr}}</mat-label>
                                            <select matNativeControl formControlName="idClimatizacion_Tipo">
                                                <option *ngFor="let item of climatizacionTypeList" [value]="item.id">
                                                    {{item.nombre}}</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label8' | tr}}</p> <!-- sistema-->
                                        <p class="page-text"></p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.idClimatizacion_Sistema">{{'instal_placeholder6' | tr}}</mat-label>
                                            <select matNativeControl formControlName="idClimatizacion_Sistema">
                                                <option *ngFor="let item of climatizacionSistemaList" [value]="item.id">
                                                    {{item.nombre}}</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'instal_label9' | tr}}</p> <!-- marca-->
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.idClimatizacion_Marca">{{'instal_placeholder7' |
                                                tr}}</mat-label>
                                            <select matNativeControl formControlName="idClimatizacion_Marca">
                                                <option *ngFor="let item of climatizacionMarcaList" [value]="item.id">
                                                    {{item.nombre}}</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'calculo_cargas' | tr}}</p> <!-- calculo cargas -->
                                        <p class="page-text"></p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.idClimatizacion_Cargas">{{'calculo_cargas_placeholder'| tr}}</mat-label>
                                            <select matNativeControl formControlName="idClimatizacion_Cargas">
                                                <option *ngFor="let item of climatizacionCargasList" [value]="item.id">
                                                    {{item.nombre}}</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'climatizacion_cocina' | tr}}</p> <!-- climatización en cocina -->
                                        <p class="page-text"></p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.climaCocina">{{'si'| tr}}</mat-label>
                                            <select matNativeControl formControlName="climaCocina">
                                                <option [value]="true">{{'yes' | tr}}</option>
                                                <option [value]="false">{{'no' | tr}}</option>
                                            </select>
                                        </mat-form-field>

                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'emisor_frio' | tr}}</p> <!-- emisor frio -->
                                        <p class="page-text">{{'emisor_frio_sub' | tr}}</p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.frioClima">{{'si'| tr}}</mat-label>
                                            <select matNativeControl formControlName="frioClima">
                                                <option [value]="true">{{'yes' | tr}}</option>
                                                <option [value]="false">{{'no' | tr}}</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 offset-md-1 col-sm-12">
                                        <p class="page-heading">{{'emisor_calor' | tr}}</p> <!-- emisor calor -->
                                        <p class="page-text"></p>
                                    </div>
                                    <div class="col-md-5 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label *ngIf="!form.value.idClimatizacion_EmisorCalor">{{'emisor_calor_placeholder'| tr}}</mat-label>
                                            <select matNativeControl formControlName="idClimatizacion_EmisorCalor">
                                                <option *ngFor="let item of climatizacionEmisorCalorList" [value]="item.id">
                                                    {{item.nombre}}</option>
                                            </select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="save-btn mt-3">
                        {{'save'| tr}}
                    </button>
                </form>
            </div>
        </div>
    </div>
</section>