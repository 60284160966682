<app-loading *ngIf="isLoader"></app-loading>
<section class="page-section" *ngIf="!isLoader">
  <div class="page-section-div">
    <mat-accordion>
      <mat-expansion-panel class="unexpend-header-expension" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title
            class="hide-mobile"
            style="flex: 0 0 15%; font-size: medium; font-weight: bold"
            (click)="$event.stopPropagation(); sortFamilias('categoria')"
          >
            {{ 'categoria' | tr }}
            <mat-icon *ngIf="sortedColumn === 'categoria'">
              {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
            </mat-icon>
          </mat-panel-title>
          <mat-panel-title
            class="show-mobile"
            style="flex: 0 0 15%; font-size: medium; font-weight: bold"
            (click)="$event.stopPropagation(); sortFamilias('nombreFamilia')"
          >
            {{ 'door_col1' | tr }}
            <mat-icon *ngIf="sortedColumn === 'nombreFamilia'">
              {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
            </mat-icon>
          </mat-panel-title>

          <mat-panel-title
            class="show-mobile"
            style="flex: 0 0 15%; font-size: medium; font-weight: bold"
            (click)="$event.stopPropagation(); sortFamilias('door_col6')"
          >
            {{ 'door_col6' | tr }}
            <mat-icon *ngIf="sortedColumn === 'door_col6'">
              {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
            </mat-icon>
          </mat-panel-title>

          <mat-panel-title
            class="hide-mobile"
            style="flex: 0 0 10%; font-size: medium; font-weight: bold"
            (click)="$event.stopPropagation(); sortFamilias('conectoresMEP')"
          >
            Conectores MEP
            <mat-icon *ngIf="sortedColumn === 'conectoresMEP'">
              {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
            </mat-icon>
          </mat-panel-title>

          <mat-panel-title
            class="hide-mobile"
            style="flex: 0 0 8%; font-size: medium; font-weight: bold"
            (click)="$event.stopPropagation(); sortFamilias('count')"
          >
            {{ 'door_col2' | tr }}
            <mat-icon *ngIf="sortedColumn === 'count'">
              {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
            </mat-icon>
          </mat-panel-title>

          <mat-panel-title
            class="show-mobile"
            style="flex: 0 0 30%; font-size: medium; font-weight: bold"
            (click)="$event.stopPropagation()"
          >
            Equivalente Letsmep
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <mat-expansion-panel *ngFor="let familia of familias; let index = index">
        <mat-expansion-panel-header style="min-height: 60px; height: auto">
          <mat-panel-title class="hide-mobile" style="flex: 0 0 15%">
            <span
              style="
                word-wrap: break-word;
                width: 100%;
                display: block;
                white-space: normal;
                word-break: break-all;
              "
              >{{ familia.categoria }}</span
            >
          </mat-panel-title>
          <mat-panel-title class="show-mobile" style="flex: 0 0 15%">
            <span
              style="
                word-wrap: break-word;
                width: 100%;
                display: block;
                white-space: normal;
                word-break: break-all;
              "
              >{{ familia.nombreFamilia }}</span
            >
          </mat-panel-title>
          <mat-panel-title class="show-mobile" style="flex: 0 0 15%">
            <span
              style="
                word-wrap: break-word;
                width: 100%;
                display: block;
                white-space: normal;
                word-break: break-all;
              "
              >{{ familia.nombre }}</span
            >
          </mat-panel-title>
          <mat-panel-title class="hide-mobile" style="flex: 0 0 10%">
            {{ familia.conectores ? 'Si' : 'No' }}
          </mat-panel-title>
          <mat-panel-title class="hide-mobile" style="flex: 0 0 8%">
            {{ familia.count }}
          </mat-panel-title>
          <mat-panel-title class="show-mobile" style="flex: 0 0 25%">
            <div class="form-field">
              <mat-form-field class="mat-form-field">
                <mat-label>{{ 'familias_label_select1' | tr }}</mat-label>
                <mat-select
                  (click)="$event.stopPropagation()"
                  [(ngModel)]="
                    selectedTipoFamilia[familia.nombreFamilia + familia.nombre]
                  "
                  (selectionChange)="
                    storeFamilyType(
                      familia,
                      selectedTipoFamilia[
                        familia.nombreFamilia + familia.nombre
                      ]
                    )
                  "
                >
                  <mat-option
                    *ngFor="let tipo of projectService.tipoFamilia"
                    [value]="tipo"
                  >
                    {{ tipo.nombre }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <table class="ubicaciones-table">
          <thead>
            <tr>
              <th *ngIf="projectService.isRevitData">
                {{ 'look_for' | tr }}
              </th>
              <th>
                {{ 'location' | tr }}
              </th>
              <th>
                {{ 'paramSelection_heading5' | tr }}
              </th>
            </tr>
          </thead>
          <tbody
            *ngFor="
              let familiaInstancia of getInstanciasFamilias(familia);
              let isFirst = first;
              let index2 = index
            "
          >
            <tr>
              <td *ngIf="projectService.isRevitData" style="width: 100px">
                <button
                  mat-icon-button
                  (click)="searchFamily(familiaInstancia)"
                >
                  <mat-icon class="mat-icon-rtl-mirror"> search </mat-icon>
                </button>
              </td>
              <td
                [ngClass]="{ clickavaliable: projectService.isRevitData }"
                (click)="searchVivienda(familiaInstancia.vivienda)"
              >
                {{
                  familiaInstancia.bloque +
                    '/' +
                    familiaInstancia.escalera +
                    '/' +
                    familiaInstancia.planta +
                    '/' +
                    familiaInstancia.vivienda.nombre
                }}
              </td>
              <td
                [ngClass]="{ clickavaliable: projectService.isRevitData }"
                (click)="searchRoom(familiaInstancia.habitacion)"
              >
                {{ familiaInstancia.habitacion.nombre }}
              </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>

<!-- <div class="page-section" *ngIf="!isLoader">
 <div class="page-section-div">
  <h2>{{'types_of_families'| tr}}</h2>
  <button mat-raised-button color="primary" style="margin-bottom: 20px;" (click)="filtrar()">{{filter===true?('textoFiltro1' |tr):('textoFiltro2' |tr)}}</button>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let familia of filtrarfamiliasConNombreNoVacio()" hideToggle>
      <mat-expansion-panel-header style="height: fit-content;">
        <mat-panel-title>
          <div>
            <h4 >{{familia.nombreFamilia}}</h4>
            <p>{{familia.nombre}}</p>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          {{getNombre(familia)}}
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <div class="container">
        <div class="header">
          <h4>{{'familias_heading_select1' | tr}}</h4>
          <p>{{'familias_text_select1' | tr}}.</p>
        </div>
        <div class="form-field">
          <mat-form-field class="mat-form-field">
            <mat-label>{{'familias_label_select1' | tr}}</mat-label>
            <mat-select [(ngModel)]="selectedTipoFamilia[familia.nombreFamilia+familia.nombre]"
            (selectionChange)="storeFamilyType(familia, selectedTipoFamilia[familia.nombreFamilia+familia.nombre])">
              <mat-option *ngFor="let tipo of projectService.tipoFamilia" [value]="tipo">
                {{tipo.nombre}}
              </mat-option>
          </mat-select>
          </mat-form-field>
        </div>
      
      </div>
    </mat-expansion-panel>
  </mat-accordion>
 </div>
</div> -->
