<h1 mat-dialog-title>{{'new_promotor' | tr}}</h1>
<div mat-dialog-content>
  <p>{{'confirm_new_promotor_text1' | tr}} ?</p>
  <mat-form-field>
    <mat-label>{{'confirm_new_promotor_placeholder1' | tr}}</mat-label>
    <input matInput [(ngModel)]="data.nombre">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'confirm_new_promotor_btn2' | tr}}</button>
  <button mat-button [mat-dialog-close]="data.nombre" cdkFocusInitial>{{'confirm_new_promotor_btn1' | tr }}</button>
</div>