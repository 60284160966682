<div class="project-pages">
    <app-stepper (dataEmitter)="loaderFromRevit($event)"></app-stepper>
    <app-loading *ngIf="isLoader"></app-loading>
    <div *ngIf="!isLoader">
        <mat-stepper *ngIf="!projectService.project || projectService.project.tipoProyecto !== 'salubridad'" class="stepper" [selectedIndex]="selectedIndex" orientation="vertical" [linear]="false" #stepper
            animationDuration="300" labelPosition="bottom">
            <mat-step class="step" [ngClass]="{'mat-step-div':stepper.selectedIndex === 0}">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 0}">
                        <span (click)="onStep1($event,0)">
                            {{'project_data' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep1"
                            [ngClass]="{'d-none':stepper.selectedIndex != 0}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep1 && stepper.selectedIndex === 0">
                    <div>
                        <app-project-data></app-project-data>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 1}">
                        <span (click)="onStep2($event,1)">
                            {{'location' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep2"
                            [ngClass]="{'d-none':stepper.selectedIndex != 1}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep2 && stepper.selectedIndex === 1">
                    <div>
                        <app-nuevo-proyecto></app-nuevo-proyecto>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 2}">
                        <span (click)="onStep3($event,2)">
                            {{'parameters' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep3"
                            [ngClass]="{'d-none':stepper.selectedIndex != 2}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep3 && stepper.selectedIndex === 2">
                    <div>
                        <app-param-selection></app-param-selection>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 3}">
                        <span (click)="onStep4($event,3)">
                            {{'bedrooms'|tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep4"
                            [ngClass]="{'d-none':stepper.selectedIndex != 3}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep4 && stepper.selectedIndex === 3">
                    <div>
                        <app-habitaciones></app-habitaciones>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 4}">
                        <span (click)="onStep5($event,4)">
                            {{'families' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep5"
                            [ngClass]="{'d-none':stepper.selectedIndex != 4}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep5 && stepper.selectedIndex === 4">
                    <div>
                        <app-familias></app-familias>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 5}">
                        <span (click)="onStep6($event,5)">
                            {{'project_structure' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep6"
                            [ngClass]="{'d-none':stepper.selectedIndex != 5}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep6 && stepper.selectedIndex === 5">
                    <div>
                        <app-project></app-project>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 6}">
                        <span (click)="onStep7($event,6)">
                            {{'males' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep7"
                            [ngClass]="{'d-none':stepper.selectedIndex != 6}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep7 && stepper.selectedIndex === 6">
                    <div>
                        <app-patinejos></app-patinejos>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 7}">
                        <span (click)="onStep8($event,7)">
                            {{'doors' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep8"
                            [ngClass]="{'d-none':stepper.selectedIndex != 7}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep8 && stepper.selectedIndex === 7">
                    <div>
                        <app-doors></app-doors>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 8}">
                        <span (click)="onStep9($event,8)">
                            {{'windows'| tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep9"
                            [ngClass]="{'d-none':stepper.selectedIndex != 8}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep9 && stepper.selectedIndex === 8">
                    <div>
                        <app-ventanas></app-ventanas>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 9}">
                        <span (click)="onStep10($event,9)">
                            {{'plant-stepper' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep10"
                            [ngClass]="{'d-none':stepper.selectedIndex != 9}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep10 && stepper.selectedIndex === 9">
                    <div>
                        <app-plant-selection></app-plant-selection>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 10}">
                        <span (click)="onStep11($event,10)">
                            {{'gl-plant-stepper' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep11"
                            [ngClass]="{'d-none':stepper.selectedIndex != 10}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep11 && stepper.selectedIndex === 10">
                    <div>
                        <app-ground-plants></app-ground-plants>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 11}">
                        <span (click)="onStep12($event,11)">
                            {{'ol-plant-stepper' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep12"
                            [ngClass]="{'d-none':stepper.selectedIndex != 11}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep12 && stepper.selectedIndex === 11">
                    <div>
                        <app-over-ground-selection></app-over-ground-selection>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 12}">
                        <span (click)="onStep13($event,12)">
                            {{'installation' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep13"
                            [ngClass]="{'d-none':stepper.selectedIndex != 12}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep13 && stepper.selectedIndex === 12">
                    <div>
                        <app-installations></app-installations>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 13}">
                        <span (click)="onStep14($event,13)">
                            {{'resume' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep14"
                            [ngClass]="{'d-none':stepper.selectedIndex != 13}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep14 && stepper.selectedIndex === 13">
                    <div>
                        <app-project-resume></app-project-resume>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 14}">
                        <span (click)="onStep15($event,14)">
                            {{'upload-project-stepper' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep15"
                            [ngClass]="{'d-none':stepper.selectedIndex != 14}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep15 && stepper.selectedIndex === 14">
                    <div>
                        <app-upload-project></app-upload-project>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
        </mat-stepper>


        


        <mat-stepper *ngIf="projectService.project && projectService.project.tipoProyecto === 'salubridad'" class="stepper" [selectedIndex]="selectedIndex" orientation="vertical" [linear]="false" #stepper
            animationDuration="300" labelPosition="bottom">
            <mat-step class="step" [ngClass]="{'mat-step-div':stepper.selectedIndex === 0}">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 0}">
                        <span (click)="onStep1($event,0)">
                            {{'project_data' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep1"
                            [ngClass]="{'d-none':stepper.selectedIndex != 0}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep1 && stepper.selectedIndex === 0">
                    <div>
                        <app-project-data></app-project-data>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 1}">
                        <span (click)="onStep2($event,1)">
                            {{'location' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep2"
                            [ngClass]="{'d-none':stepper.selectedIndex != 1}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep2 && stepper.selectedIndex === 1">
                    <div>
                        <app-nuevo-proyecto></app-nuevo-proyecto>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 2}">
                        <span (click)="onStep3($event,2)">
                            {{'parameters' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep3"
                            [ngClass]="{'d-none':stepper.selectedIndex != 2}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep3 && stepper.selectedIndex === 2">
                    <div>
                        <app-param-selection></app-param-selection>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 3}">
                        <span (click)="onStep4($event,3)">
                            {{'bedrooms'|tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep4"
                            [ngClass]="{'d-none':stepper.selectedIndex != 3}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep4 && stepper.selectedIndex === 3">
                    <div>
                        <app-habitaciones></app-habitaciones>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 4}">
                        <span (click)="onStep5($event,4)">
                            {{'families' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep5"
                            [ngClass]="{'d-none':stepper.selectedIndex != 4}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep5 && stepper.selectedIndex === 4">
                    <div>
                        <app-familias></app-familias>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 5}">
                        <span (click)="onStep6($event,5)">
                            {{'project_structure' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep6"
                            [ngClass]="{'d-none':stepper.selectedIndex != 5}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep6 && stepper.selectedIndex === 5">
                    <div>
                        <app-project></app-project>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 6}">
                        <span (click)="onStep7($event,6)">
                            {{'males' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep7"
                            [ngClass]="{'d-none':stepper.selectedIndex != 6}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep7 && stepper.selectedIndex === 6">
                    <div>
                        <app-patinejos></app-patinejos>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 7}">
                        <span (click)="onStep8($event,7)">
                            {{'installation' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep8"
                            [ngClass]="{'d-none':stepper.selectedIndex != 7}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep8 && stepper.selectedIndex === 7">
                    <div>
                        <app-installations></app-installations>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 8}">
                        <span (click)="onStep9($event,8)">
                            {{'resume' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep9"
                            [ngClass]="{'d-none':stepper.selectedIndex != 8}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep9 && stepper.selectedIndex === 8">
                    <div>
                        <app-project-resume></app-project-resume>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
            <mat-step class="step">
                <ng-template matStepLabel>
                    <div class="d-flex align-items-center mat-step-label"
                        [ngClass]="{'mat-step-label-active':stepper.selectedIndex === 9}">
                        <span (click)="onStep10($event,9)">
                            {{'upload-project-stepper' | tr}}
                        </span>
                        <div class="vertical-icons" *ngIf="!closedStep10"
                            [ngClass]="{'d-none':stepper.selectedIndex != 9}">
                            <i (click)="stepperPrevious($event)" class="bi bi-arrow-up custom-arrow mr-3 ml-3"></i>
                            <i (click)="stepperNext($event)" class="bi bi-arrow-down custom-arrow"></i>
                        </div>
                    </div>
                </ng-template>
                <div class="steper-content" *ngIf="!closedStep10 && stepper.selectedIndex === 9">
                    <div>
                        <app-upload-project></app-upload-project>
                        <div class="stepper-btns">
                            <button class="btn-back mr-3" mat-flat-button
                                (click)="stepperPrevious($event)">{{'back' | tr}}</button>
                            <button class="btn-next" mat-flat-button (click)="stepperNext($event)">{{'next' | tr}}</button>
                        </div>
                    </div>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>