import { FlatTreeControl } from '@angular/cdk/tree';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Subject } from 'rxjs';
import { URL_API } from 'src/main';
import { ProjectService, TipoFamilia, TipoHabitacion, } from '../../services/project.service';
import { Habitacion, InstanciaFamilia } from 'src/app/Shared/modals/allStructre.interface';
import { Puertas } from 'src/app/Shared/modals/puertas.interface';
import { Ventana } from 'src/app/Shared/modals/ventana.interface';

@Component({
  selector: 'app-habitacion-info',
  templateUrl: './habitacioninfo.component.html',
  styleUrls: ['./habitacioninfo.component.css'],
})


export class HabitacioninfoComponent implements OnInit,OnDestroy {

  public webViewMessage = new Subject<any>();

  @Input() habitacion: Habitacion ;

  elementos: InstanciaFamilia[] = [];  
  puertas: Puertas[] = [];
  ventanas: Ventana[] = [];

  selectedTipoHabitacion : {[key: string]: TipoHabitacion} = {};
  selectedTipoFamilia: {[key: string]: TipoFamilia} = {};


  constructor(public auth: AuthService, public projectService: ProjectService) {

    this.selectedTipoFamilia = this.projectService.familyType;
    this.selectedTipoHabitacion = this.projectService.habitacionType;

    window?.chrome?.webview?.addEventListener('message', event => {
      this.webViewMessage.next(event.data);


    });

  }
  ngOnDestroy(): void {
    window?.chrome?.webview?.removeEventListener('message', event => {
      this.webViewMessage.next(event.data);
    });
  }

  ngOnInit() {
    this.elementos = this.habitacion.elementos;
    this.puertas = this.habitacion.puertas;
    this.ventanas = this.habitacion.ventanas;
  }

  ngAfterViewInit(): void {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'RoomsFamilys' }));
  }

  searchFamily(family) {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchFamily', 'obj': family }));
  }

  storeFamilyType(familia: InstanciaFamilia, tipo: TipoFamilia) {
    this.projectService.familyType[familia.nombreFamilia+familia.nombre] = tipo;
    this.projectService.CambiarTipoFamilia(familia.nombreFamilia+familia.nombre, tipo.id);
    this.projectService.storeFamiliaType(familia.nombreFamilia, familia.nombre, tipo);
  }

  storeHabitacionType(habitacion: Habitacion, tipo: TipoHabitacion) {
    this.projectService.habitacionType[habitacion.nombre] = tipo;
    this.projectService.CambiarTipoHabitacion(habitacion.nombre, tipo.id);
    this.projectService.storeHabitacionType(habitacion.nombre, tipo);
  }


}

