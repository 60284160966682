import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { ProjectService } from '../services/project.service';
import { Nivel, Selections } from 'src/app/Shared/modals/allStructre.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ground-plants',
  templateUrl: './ground-plants.component.html',
  styleUrls: ['./ground-plants.component.css']
})
export class GroundPlantsComponent {
  @Input() nombreNivel: string;
  projectId: string;
  isLoader: boolean = false;
  expandedIndex = 0;
  bajorasante: Nivel;
  isDisable:boolean = true;
  form:FormGroup
  idProyectoPlanta:string;
  constructor(private fb:FormBuilder,private route: ActivatedRoute, private router: Router, private api: ApiServices, private toastr: ToastrService, private projectService: ProjectService) {
    this.projectId = this.route.snapshot.paramMap.get('id');
    if (this.projectId) {
      this.projectService.setProjectId(this.projectId);
    }
    this.form = this.fb.group({
          superficieConstruida: ['',[Validators.required,Validators.pattern('^[0-9,.]*$')]],
          plazasCoche: ['',[Validators.required]],
          plazasMoto: ['',[Validators.required]]
    })
  }
  ngOnInit(): void {
    if (this.projectId) {
      this.isLoader = true;
      this.api.getBajorasanteDatos(this.projectId, this.nombreNivel).subscribe((res: any) => {
        this.isLoader = false;
        this.bajorasante = res;
        this.patchForm(res);
        console.log(res)
      }, err => {
        this.isLoader = false;
        console.log('error', err)
      })
    } 
  }
  patchForm(item:any){
    this.idProyectoPlanta = item.idProyectoPlanta;
    this.form.patchValue({
      superficieConstruida: item.superficieConstruida,
      plazasCoche: item.plazasCoche,
      plazasMoto: item.plazasMoto,
    })
  }
 
  SavePlanteBajorasante(){
    if(this.form.valid){
      if (this.bajorasante && this.bajorasante.isBajoRasante) {
        const payload = {
          Id: this.bajorasante.id,
          IdProyecto: this.idProyectoPlanta,
          superficieConstruida: this.form.value.superficieConstruida,
          plazasCoche: this.form.value.plazasCoche,
          plazasMoto: this.form.value.plazasMoto,
        }
        console.log(payload)
          this.api.postBajorasante(payload).subscribe((res:Selections) => {
            this.toastr.success('Below Ground Plants Saved.')
          }, err => {
            this.toastr.error(err.error.title);
          })
      } else {
        this.toastr.info('Something went wrong');
      }
    }
  }
  
}
