import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { URL_API } from 'src/main';
import { ProjectService } from '../services/project.service';
import { Ventana } from 'src/app/Shared/modals/ventana.interface';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-project',
  templateUrl: './upload-project.component.html',
  styleUrls: ['./upload-project.component.css'],
})



export class UploadProjectComponent implements OnInit , OnDestroy {

  projectId: string = "";
  isLoader:boolean= false;

  filter: boolean = false;
  title: string;
  message: string;


  constructor(public auth: AuthService,
    private route: ActivatedRoute,
    public projectService: ProjectService,
    private toastr:ToastrService,
    private apiService:ApiServices,
    private router:Router) {


  }

  ngOnInit() {
    //this.isLoader = true;
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.projectService.setProjectId(this.projectId);
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {
        window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'userToken', 'obj': accessToken.__raw}));
        const payload = {
          idProject: this.projectService.projectId.getValue(),
          ifc: ""
        }
        console.log(payload)
        this.apiService.calculateProject(payload).subscribe(res=>{
          this.toastr.success('project successfully calculated');
        },err =>{
          this.toastr.error('Error:',err.errors.title)
        })
      }
    })
  }
  onProcess(){

    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'createIfc' }));
    window?.chrome?.webview?.addEventListener('message', event => this.messageFromRevit(event));
  
  }

  messageFromRevit(event): void {
    // Now the data is save on Revit
  }
  ngOnDestroy(): void {
    window?.chrome?.webview?.removeEventListener('message', event => this.messageFromRevit(event));
  }
}