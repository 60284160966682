import { Component ,HostListener,OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { ProjectService } from '../services/project.service';
import { ResumeStatus, Vivienda, resume } from 'src/app/Shared/modals/allStructre.interface';

@Component({
  selector: 'app-project-resume',
  templateUrl: './project-resume.component.html',
  styleUrls: ['./project-resume.component.css']
})
export class ProjectResumeComponent implements OnInit{
  isLoader: boolean = false;
  projectId: string;
  enRevit: boolean = false;
  projectResumeData: resume;
  showViviendasModal: boolean = false;
  esHabitacion: boolean = false;
  viviendasList: Vivienda[] = [];
  modalTitle: string = 'Lista de Viviendas';

  constructor(
    private apiService: ApiServices,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public projectService: ProjectService,
    private router: Router,
    private renderer: Renderer2
  ){
    this.projectId = this.route.snapshot.paramMap.get('id');
    
  }

  ngOnInit(): void {
    if(this.projectId){
      this.isLoader = true;
      this.apiService.getProjectResume(this.projectId).subscribe((res: resume) =>{
        this.isLoader = false;
        this.projectResumeData = res;
        console.log('projectResumeData:', this.projectResumeData);
      });
    }
    this.enRevit = this.projectService.isRevitData;
  }

  goTo(stepIndex: number){
    this.router.navigate(['/project-home/', this.projectService.projectId.getValue(), stepIndex]);
    setTimeout(() => {
      window.location.reload();
    }, 0);
  }

  searchRoom(habitacion) {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchRoom', 'obj': habitacion }));
  }

  searchVivienda(vivienda) {
    console.log(vivienda);
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchVivienda', 'obj': vivienda }));
  }

  openViviendasModal(viviendas: Vivienda[], errorMessage?: string, esHabitacion?: boolean){
    console.log('Abriendo modal con viviendas:', viviendas);
    if (errorMessage) {
      this.modalTitle = errorMessage;
    } else {
      this.modalTitle = 'Lista de Viviendas';
    }
    this.esHabitacion = esHabitacion;
    this.viviendasList = viviendas;
    this.showViviendasModal = true;
    this.renderer.addClass(document.body, 'body-modal-open');
  }

  closeViviendasModal(){
    console.log('Cerrando modal de viviendas');
    this.showViviendasModal = false;
    this.esHabitacion = false;
    this.renderer.removeClass(document.body, 'body-modal-open');
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent) {
    if (this.showViviendasModal) {
      this.closeViviendasModal();
    }
  }
}
