import { Component, Inject, Input, OnInit } from '@angular/core';
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { ProjectService } from 'src/app/pages/project/services/project.service';
import { ActivatedRoute } from '@angular/router';
import { Translator } from "../../lang-translator/index";

@Component({
  selector: 'app-new-nav-bar',
  templateUrl: './new-nav-bar.component.html',
  styleUrls: ['./new-nav-bar.component.css']
})
export class NewNavBarComponent {
  isCollapsed = true;
  faUser = faUser;
  faPowerOff = faPowerOff;
  selectLang:string;
  // page:string = '';
  @Input() page:string

  constructor(
    public auth: AuthService, public proyectoService: ProjectService,
    private route:ActivatedRoute,
    public translator: Translator,
    
    @Inject(DOCUMENT) private doc: Document
  ) {
      this.selectLang = this.translator.getLanguage();
    }


    ngOnInit(){
      const a = this.auth.idTokenClaims$;
      a.subscribe(accessToken => {
        if (accessToken && accessToken != undefined) {
          window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'userToken', 'obj': accessToken.__raw}));
        }
      });
    }
  loginWithRedirect() {
    this.auth.loginWithRedirect().subscribe(result => {
    });
  }

  logout() {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'removeUser', 'obj': ''}));
  }
  
  onLanguageChange(selectedLanguage: string) {
    this.selectLang = selectedLanguage
    this.translator.setLanguage(selectedLanguage);
  }
}
