
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-superficie-habitacion',
  templateUrl: './superficieSinHabitacion.component.html',
  styleUrls: ['./superficieSinHabitacion.component.css'],
})



export class SuperficiesSinHabitacionComponent implements OnInit, OnDestroy {

  public webViewMessage = new Subject<any>();
  isLoader:boolean = false;

  projectId: string = "";
  displayedColumns: string[] = ['Nivel', 'Area', 'NumeroLados'];
  dataSource: HabitacionesSinSuperficie[] = [];
  @Output() dataEmitter = new EventEmitter<boolean>();
  constructor(public auth: AuthService, private route: ActivatedRoute, private http: HttpClient, private router: Router) {
    window?.chrome?.webview?.addEventListener('message', (event: { data: string; }) => {
      this.isLoader = true;
      this.webViewMessage.next(event.data);
      if (!event.data.startsWith("ProjectData")) {
        this.dataSource = JSON.parse(event.data);
      }
      this.isLoader = false;
    });
    window?.chrome?.webview?.addEventListener('messageerror',  err => {
      this.isLoader = false;
    });
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id');

  }

  ngAfterViewInit(): void {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'HabitacionesSinSuperficie' }));
  }


  save() {
    this.SendToRevit();
  }

  next() {
    this.router.navigate(['/project', this.projectId]);
  }

  SendToRevit() {
    this.isLoader = true;
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'CrearHabitacionesSinSuperficie' }));
    setTimeout(() => {
      this.isLoader = false;
    }, 120000);
  }
  ngOnDestroy(): void {
    window?.chrome?.webview?.removeEventListener('message', (event: { data: string; }) => {
      this.webViewMessage.next(event.data);
      this.dataSource = JSON.parse(event.data);
    });
  }
}

class HabitacionesSinSuperficie {
  Nivel: string;
  Area: string;
  ReadOnly: boolean;
  TieneSuperfice: boolean
  NumeroLados: string;
}