import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { URL_API } from 'src/main';
import { ProjectService } from '../services/project.service';
import { Puertas } from 'src/app/Shared/modals/puertas.interface';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ThemePalette } from '@angular/material/core';
import { Nivel } from 'src/app/Shared/modals/allStructre.interface';
@Component({
  selector: 'app-levels',
  templateUrl: './levels.component.html',
  styleUrls: ['./levels.component.css']
})
export class LevelsComponent {
  projectId: string = "";
  uniqNiveles: Nivel[] = [];
  niveles: Nivel[] = [];
  filter: boolean = false;
  isLoader: boolean = false;
  constructor(public auth: AuthService,
    private route: ActivatedRoute,
    private http: HttpClient,
    public projectService: ProjectService,
    private cdRef: ChangeDetectorRef, private apiService: ApiServices, private toastr: ToastrService) {
    window?.chrome?.webview?.addEventListener('message', event => {
      this.messageFromRevit(event)
    });
      
  }

  ngOnInit() {
    this.isLoader = true;
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.projectService.setProjectId(this.projectId);
    if(this.projectService.isRevitData === true){
      this.cargarNivelesRevit();
    }
    this.apiService.getNiveles(this.projectId).subscribe(
      (result) => {
        this.isLoader = false;
        this.niveles = result;
        if (this.niveles) {
          this.uniqNiveles = this.niveles;
        }
      },
      (err) => {
        this.isLoader = false;
        console.error(err);
      });

      
  }

  cargarNivelesRevit() {
    console.log("cargarNivelesRevit");
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'getLevels', 'obj': '' }));

  }
  messageFromRevit(event: any) {
      console.log("datos",event.data)
      
      this.apiService.updateNiveles(event.data).subscribe(
        (result) => {
          this.isLoader = false;
          this.niveles = result;
          if (this.niveles) {
            this.uniqNiveles = this.niveles;
          }
        },
        (err) => {
          this.isLoader = false;
          console.error(err);
        });
      
    }

  guardarNivel(nivel: Nivel) {
    let postData = {
      id: nivel.id,
      idProyecto: this.projectService.projectId.getValue(),
      exportar: nivel.exportar,
      isBajoRasante: nivel.isBajoRasante
    }
    this.apiService.updatedNivel(postData).subscribe(res => {
      this.toastr.success('Level updated');
    }, error => {
      this.toastr.error('data did not updated ! error:', error);
    })
  }

  exportarChange(e, nivel: Nivel) {
    if (e.checked === false) {
      nivel.exportar = null;
    } else {
      nivel.exportar = true;
    }
    this.guardarNivel(nivel);
  }
  bajoRasanteChange(e, nivel: Nivel) {
    if (e.checked === false) {
      nivel.isBajoRasante = null;
    } else {
      nivel.isBajoRasante = true;
    }
    this.guardarNivel(nivel);
  }
  stairIndividualChange(e, puerta: Puertas, subPureta: Puertas) {
    subPureta.isEscalera = e;
    const hasTrueEscalera = puerta.subPuertas.some(item => item.isEscalera === true);
    const hasFalseEscalera = puerta.subPuertas.some(item => item.isEscalera === false);
    puerta.isEscalera = hasTrueEscalera ? (hasFalseEscalera ? false : true) : null;
    let postData = {
      idProyecto: this.projectService.projectId.getValue(),
      idElement: subPureta.idElement,
      nombre: subPureta.nombre,
      nombreFamilia: subPureta.nombreFamilia,
      isEscalera: e
    }
    this.apiService.updatedIndividualStair(postData).subscribe(res => {
      this.toastr.success('Sub Puerta stair updated');
    }, error => {
      this.toastr.error('data did not updated ! error:', error);
    });
  }
  principalIndividualChange(e, puerta: Puertas, subPureta: Puertas) {
    subPureta.principal = e;
    const hasTruePrincipal = puerta.subPuertas.some(item => item.principal === true);
    const hasFalsePrincipal = puerta.subPuertas.some(item => item.principal === false);
    puerta.principal = hasTruePrincipal ? (hasFalsePrincipal ? false : true) : null;

    let postData = {
      idProyecto: this.projectService.projectId.getValue(),
      idElement: subPureta.idElement,
      nombreFamilia: subPureta.nombreFamilia,
      nombre: subPureta.nombre,
      principal: e
    }
    this.apiService.updatedIndividualPrinciple(postData).subscribe(res => {
      this.toastr.success('Sub Puerta principal updated');
    }, error => {
      this.toastr.error('data did not updated ! error:', error);
    });
  }
  ventanaIndividualChange(e, puerta: Puertas, subPureta: Puertas) {
    subPureta.ventana = e;
    const hasTrueVentana = puerta.subPuertas.some(item => item.ventana === true);
    const hasFalseVentana = puerta.subPuertas.some(item => item.ventana === false);
    puerta.ventana = hasTrueVentana ? (hasFalseVentana ? false : true) : null;
    let postData = {
      idProyecto: this.projectService.projectId.getValue(),
      idElement: subPureta.idElement,
      nombreFamilia: subPureta.nombreFamilia,
      nombre: subPureta.nombre,
      ventana: e
    }
    this.apiService.updatedIndividualVentana(postData).subscribe(res => {
      this.toastr.success('Sub Puerta ventana updated');
    }, error => {
      this.toastr.error('data did not updated ! error:', error);
    })
  }

  searchFamily(family) {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchFamily', 'obj': family }));
  }
}


