import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { ProjectService } from '../services/project.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Selections } from 'src/app/Shared/modals/allStructre.interface';

@Component({
  selector: 'app-plant-selection',
  templateUrl: './plant-selection.component.html',
  styleUrls: ['./plant-selection.component.css']
})
export class PlantSelectionComponent implements OnInit {
  projectId:string;
  isLoader:boolean =false;
  expandedIndex = 0;
  sobrerasante:Selections[]=[];
  bajorasante:Selections[]=[];
  constructor(private route:ActivatedRoute,private router:Router,private api:ApiServices,private toastr:ToastrService,private projectService:ProjectService){
    this.projectId = this.route.snapshot.paramMap.get('id');
    if(this.projectId){
      this.projectService.setProjectId(this.projectId);
    }
  }
  ngOnInit(): void {
    this.isLoader = true;
    if(this.projectId){
      this.api.getSobrerasante(this.projectId).subscribe((res:any) =>{
        this.isLoader =false;
        this.sobrerasante = res;
      }, err =>{
        this.isLoader =false;
        console.log('error',err)
      })
      this.api.getBajorasante(this.projectId).subscribe((res:any) =>{
        this.isLoader =false;
        this.bajorasante = res;
      }, err =>{
        this.isLoader =false;
        console.log('error',err)
      }) 
    }
  }
  async drop(event: CdkDragDrop<string[]>,dataName:string,addedData:any[]) {
    const planta = await addedData[event.previousIndex];
    console.log(planta)
    const idProyectoPlanta = planta.idProyectoPlanta;
    //Dado el idProyectoPlanta, obtener el nombre de la planta


    const nombre = planta.nombre;
    const payload = {
      idPlanta:idProyectoPlanta,
      idProyecto:this.projectService.projectId.getValue(),
      nombre: nombre,
    }
    if(dataName==='sobrerasante'){
       this.api.postCambioSobrerasante(payload).subscribe(res=>{
        this.toastr.success('Sobrerasante Updated !');
      },err =>{
        console.log(err);
      })
    }
    else if(dataName==='bajorasante'){
      this.api.postCambioBajorasante(payload).subscribe(res=>{
        this.toastr.success('Bajorasante Updated !');
      },err =>{
        console.log(err);
      })
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    
    this.bajorasante.sort((a:any, b:any) => (a.nombre > b.nombre ? 1 : -1));
    this.sobrerasante.sort((a:any, b:any) => (a.nombre > b.nombre ? 1 : -1));
  }
}
