<div class="py-3 px-3">
    <h1 mat-dialog-title>
        {{title}}
      </h1>
      
      <div mat-dialog-content>
        <p>{{message}}</p>
      </div>
      
      <div mat-dialog-actions class="modal-btn">
        <button mat-button (click)="onDismiss()">No</button>
        <button mat-raised-button 
        class="btn-yes" (click)="onConfirm()">Yes</button>
      </div>
</div>