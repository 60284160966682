import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeroComponent } from './components/hero/hero.component';
import { HomeContentComponent } from './components/home-content/home-content.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ExternalApiComponent } from './pages/external-api/external-api.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { CardComponent } from './components/home-content/project-card.component';
import { ProjectComponent } from './pages/project/project/project.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import {  HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ParamSelectionComponent } from './pages/project/paramSelection/paramselection.component';
import { SuperficiesSinHabitacionComponent } from './pages/project/superficieSinHabitacion/superficieSinHabitacion.component';
import { HabitacioninfoComponent } from './pages/project/components/habitacioninfo/habitacioninfo.component';
import { FormsModule } from '@angular/forms';
import { PatinejosComponent } from './pages/project/patinejos/patinejos.component';
import { HabitacionesComponent } from './pages/project/habitaciones/habitaciones.component';
import { FamiliasComponent } from './pages/project/familias/familias.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { VentanasComponent } from './pages/project/ventanas/ventanas.component';
import { NewNavBarComponent } from './components/new-nav-bar/new-nav-bar.component';
import {NuevoProyectoComponent} from './pages/project/nuevo-proyecto/nuevo-proyecto.component';
import { StepperComponent } from './components/stepper/stepper.component'
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { DoorsComponent } from './pages/project/doors/doors.component';
import { StartRevitComponent } from './pages/start-revit/start-revit.component';
import { SettingComponent } from './pages/setting/setting.component';
import { LangTranslatorModule } from './lang-translator/lang-translator.module';
import { ProjectDataComponent } from './pages/project/project-data/project-data.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import {MatListModule} from '@angular/material/list';
import {MatSliderModule} from '@angular/material/slider';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { PlantSelectionComponent } from './pages/project/plant-selection/plant-selection.component';
import { GroundPlantsComponent } from './pages/project/ground-plants/ground-plants.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatStepperModule} from '@angular/material/stepper';
import { ProjectHomeComponent } from './pages/project/project-home/project-home.component';
import { ProjectProcessedComponent } from './pages/project-processed/project-processed.component';
import { OverGroundSelectionComponent } from './pages/project/over-ground-selection/over-ground-selection.component';
import { UploadProjectComponent } from './pages/project/upload-project/upload-project.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { InstallationsComponent } from './pages/project/installations/installations.component';
import { ProjectResumeComponent } from './pages/project/project-resume/project-resume.component';
import { LoginComponent } from './components/login/login.component';
import { InstalacionPatinejoCardComponent } from './pages/project/patinejos/instalacion-patinejo-card/instalacion-patinejo-card.component';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectComponent,
    ParamSelectionComponent,
    SuperficiesSinHabitacionComponent,
    CardComponent,
    PatinejosComponent,
    InstalacionPatinejoCardComponent, 
    HabitacionesComponent,
    VentanasComponent,
    ProfileComponent,
    HabitacioninfoComponent,
    FamiliasComponent,
    LoginComponent,
    NavBarComponent,
    FooterComponent,
    HeroComponent,
    HomeContentComponent,
    LoadingComponent,
    ExternalApiComponent,
    ErrorComponent,
    NewNavBarComponent,
    NuevoProyectoComponent,
    StepperComponent,
    ConfirmationComponent,
    DoorsComponent,
    StartRevitComponent,
    SettingComponent,
    ProjectDataComponent,
    PlantSelectionComponent,
    GroundPlantsComponent,
    ProjectHomeComponent,
    UploadProjectComponent,
    ProjectProcessedComponent,
    OverGroundSelectionComponent,
    InstallationsComponent,
    ProjectResumeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    MatTreeModule,
    MatExpansionModule,
    MatSliderModule,
    MatTableModule,
    CdkTableModule,
    CdkTreeModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    HttpClientModule,
    NgbModule,
    MatDividerModule,
    HighlightModule,
    MatSelectModule,
    MatAutocompleteModule,
    FontAwesomeModule,
    MatSlideToggleModule,
    UiSwitchModule,
    ReactiveFormsModule,
    CarouselModule,
    MatCheckboxModule,
    DragDropModule,
    MatStepperModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
    BrowserAnimationsModule,
    LangTranslatorModule.forRoot({
      defaultLang: 'de',
      storagePrefix: 'ferhado-language'
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    MatListModule,
    CdkAccordionModule,
    MatProgressBarModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
