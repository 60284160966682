import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslatorPipe } from './translator.pipe';
export interface NgFerhadoTranslatorConfig {
  defaultLang?: string,
  storagePrefix?: string
}

@NgModule({
  declarations: [TranslatorPipe],
  exports: [TranslatorPipe
  ]
})

export class LangTranslatorModule {
  public static forRoot(config: NgFerhadoTranslatorConfig): ModuleWithProviders<LangTranslatorModule> {
    return {
      ngModule: LangTranslatorModule,
      providers: [    
        { provide: 'config', useValue: config }
      ]
    };
  }
}