<app-loading *ngIf="isLoader"></app-loading>
<section class="page-section" *ngIf="!isLoader">
    <div class="over-plant-selection-main col-custome">
        <div class="">
            <div class="d-flex header-icon pb-lg-3 pb-sm-0">
                <img src="../../../../assets/icons/level-up.png" alt="level-down" srcset="" height="24" class="mr-3">
                <h4 class="main-heading">{{'Sobre_heading' | tr}}</h4>
            </div>
            <div class="row pr-lg-5 pr-sm-0 pl-lg-5 pl-sm-0">
               <form [formGroup]="form" (submit)="SavePlanteSobre()">
                <div class="col-12 ">
                    <div>
                     <div class="row page-content-row">
                         <div class="col-md-6 col-sm-12">
                             <p  class="page-heading">{{'overPlantDetail_label1' | tr}}</p>
                             <p class="page-text">{{'subOverPlantDetail_label1' | tr}}</p>
                         </div>
                         <div class="col-md-5 col-sm-12 offset-md-1">
                             <mat-form-field class="mat-field">
                                 <mat-label >{{'enter_height'| tr}}</mat-label>
                                 <input matInput type="text" formControlName="height" (change)="heightChange($event.target.value)">
                               </mat-form-field>
                         </div>
                     </div>
                     <div class="row page-content-row">
                         <div class="col-md-6 col-sm-12">
                             <p  class="page-heading">{{'overPlantDetail_label2' | tr}}</p>
                             <p class="page-text">{{'subOverPlantDetail_label2' | tr}}</p>
                         </div>
                         <div class="col-md-5 col-sm-12 offset-md-1">
                             <mat-form-field class="mat-field">
                                 <mat-label >{{'si'| tr}}</mat-label>
                                 <select matNativeControl formControlName="urbanization" (change)="urbanizationChange($event.target.value)">
                                    <option [value]="true">{{'yes' | tr}}</option>
                                    <option [value]="false">{{'no' | tr}}</option>
                                 </select>
                               </mat-form-field>
                         </div>
                     </div>
                     <div *ngIf="form.value.urbanization">
                         <div class="row align-items-center">
                             <div class="col-md-6 offset-md-1 col-sm-12">
                                 <p  class="page-heading">{{'overPlantDetail_label3' | tr}}</p>
                                 <p class="page-text">{{'subOverPlantDetail_label3' | tr}}</p>
                             </div>
                             <div class="col-md-5 col-sm-12">
                                 <mat-form-field class="mat-field">
                                     <mat-label>{{'enter_a_surface' | tr}}</mat-label>
                                     <input matInput type="text" formControlName="urbanArea" (change)="urbanAreaChange($event.target.value)">
                                 </mat-form-field>
                             </div>
                         </div>
                         <div class="row align-items-center">
                             <div class="col-md-6 offset-md-1 col-sm-12">
                                 <p  class="page-heading">{{'overPlantDetail_label4' | tr}}</p>
                                 <p class="page-text">{{'subOverPlantDetail_label4' | tr}}</p>
                             </div>
                             <div class="col-md-5 col-sm-12">
                                 <mat-form-field class="mat-field">
                                     <mat-label>{{'enter_a_volume' | tr}}</mat-label>
                                     <input matInput type="text" formControlName="pool" (change)="poolChange($event.target.value)">
                                   </mat-form-field>
                             </div>
                         </div>
                         <div class="row align-items-center">
                             <div class="col-md-6 offset-md-1 col-sm-12">
                                 <p  class="page-heading">{{'overPlantDetail_label5' | tr}}</p>
                                 <p class="page-text">{{'subOverPlantDetail_label5' | tr}}</p>
                             </div>
                             <div class="col-md-5 col-sm-12">
                                 <mat-form-field class="mat-field">
                                     <mat-label>{{'enter_a_surface' | tr}}</mat-label>
                                     <input matInput type="text" formControlName="irrigation" (change)="irrigationChange($event.target.value)">
                                   </mat-form-field>
                             </div>
                         </div>
                         <div class="row align-items-center">
                             <div class="col-md-6 offset-md-1 col-sm-12">
                                 <p  class="page-heading">{{'overPlantDetail_label6' | tr}}</p>
                                 <p class="page-text">{{'subOverPlantDetail_label6' | tr}}</p>
                             </div>
                             <div class="col-md-5 col-sm-12">
                                 <mat-form-field class="mat-field">
                                     <mat-label>{{'enter_a_surface' | tr}}</mat-label>
                                     <input matInput type="text" formControlName="lighting" (change)="lightingChange($event.target.value)">
                                   </mat-form-field>
                             </div>
                         </div>
                     </div>
                    </div>
                 </div>
                 <button type="submit" class="btn-setting-bottom-1">{{'save' | tr}}</button>
               </form>
            </div>
        </div>
    </div>
</section>
<!-- <comment>
    <div *ngIf="bajorasante.length">
        <div class="mb-5">
            <div class="d-flex header-icon">
                <img src="../../../../assets/icons/level-down.png" alt="level-down" srcset="" height="24" class="mr-3">
                <h4 class="main-heading">{{'Sobre_heading' | tr}}</h4>
              </div>
            <cdk-accordion class="example-accordion">
                <cdk-accordion-item *ngFor="let item of sobrerasante; let index = index;"
                    #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button" tabindex="0"
                    [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded"
                    [attr.aria-controls]="'accordion-body-' + index">
                    <div class="example-accordion-item-header"
                        (click)="accordionItem.toggle();patchForm(item)">
                        <div class="inner-heading">
                            <p>{{ item.planta.nombre }}</p>
                        </div>
                    </div>
                    <div class="example-accordion-item-body" role="region"
                        [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                        [attr.aria-labelledby]="'accordion-header-' + index">
                        <div class="selectors" (submit)="SavePlanteBajorasante()">
                            <form [formGroup]="form">
                                <div class="row align-items-center">
                                    <div class="col-md-6 col-sm-12">
                                        <p class="page-sub-heading">{{'plantDetail_label1' | tr}}</p>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label>{{'plantDetail_placeholder' | tr}}</mat-label>
                                            <input matInput type="text" formControlName="superficieConstruida">
                                        </mat-form-field>
                                        <div class="error-message" *ngIf="form.get('superficieConstruida').hasError('required') && form.get('superficieConstruida').touched">
                                            {{'plantDetail_label1' | tr}} is required.
                                        </div>
                                        <div class="error-message" *ngIf="form.get('superficieConstruida').hasError('pattern') && form.get('superficieConstruida').touched">
                                            Only numbers, commas, and decimal points are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 col-sm-12">
                                        <p class="page-sub-heading">{{'plantDetail_label2' | tr}}</p>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label>{{'plantDetail_placeholder' | tr}}</mat-label>
                                            <input matInput type="number" formControlName="plazasCoche">
                                        </mat-form-field>
                                        <div class="error-message" *ngIf="form.get('plazasCoche').hasError('required') && form.get('plazasCoche').touched">
                                            {{'plantDetail_label2' | tr}} is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 col-sm-12">
                                        <p class="page-sub-heading">{{'plantDetail_label3' | tr}}</p>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label>{{'plantDetail_placeholder' | tr}}</mat-label>
                                            <input matInput type="number" formControlName="plazasMoto" >
                                        </mat-form-field>
                                        <div class="error-message" *ngIf="form.get('plazasMoto').hasError('required') && form.get('plazasMoto').touched">
                                            {{'plantDetail_label3' | tr}} is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 col-sm-12">
                                        <p class="page-sub-heading">{{'plantDetail_label4' | tr}}</p>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label>{{'plantDetail_placeholder' | tr}}</mat-label>
                                            <input matInput type="number"  formControlName="trasteros">
                                        </mat-form-field>
                                        <div class="error-message" *ngIf="form.get('trasteros').hasError('required') && form.get('trasteros').touched">
                                            {{'plantDetail_label4' | tr}} is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 col-sm-12">
                                        <p class="page-sub-heading">{{'plantDetail_label5' | tr}}</p>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label>{{'plantDetail_placeholder' | tr}}</mat-label>
                                            <input matInput type="text" formControlName="superficieConstruidaTrasteros">
                                        </mat-form-field>
                                        <div class="error-message" *ngIf="form.get('superficieConstruidaTrasteros').hasError('required') && form.get('superficieConstruidaTrasteros').touched">
                                            {{'plantDetail_label5' | tr}} is required.
                                        </div>
                                        <div class="error-message" *ngIf="form.get('superficieConstruidaTrasteros').hasError('pattern') && form.get('superficieConstruidaTrasteros').touched">
                                            Only numbers, commas, and decimal points are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 col-sm-12">
                                        <p class="page-sub-heading">{{'plantDetail_label6' | tr}}</p>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label>{{'plantDetail_placeholder' | tr}}</mat-label>
                                            <input matInput type="number" formControlName="localesResiduos">
                                        </mat-form-field>
                                        <div class="error-message" *ngIf="form.get('localesResiduos').hasError('required') && form.get('localesResiduos').touched">
                                            {{'plantDetail_label6' | tr}} is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-md-6 col-sm-12">
                                        <p class="page-sub-heading">{{'plantDetail_label7' | tr}}</p>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <mat-form-field class="mat-field">
                                            <mat-label>{{'plantDetail_placeholder' | tr}}</mat-label>
                                            <input matInput type="text" formControlName="superficieConstruidaResiduos">
                                        </mat-form-field>
                                        <div class="error-message" *ngIf="form.get('superficieConstruidaResiduos').hasError('required') && form.get('superficieConstruidaResiduos').touched">
                                            {{'plantDetail_label7' | tr}} is required.
                                        </div>
                                        <div class="error-message" *ngIf="form.get('superficieConstruidaResiduos').hasError('pattern') && form.get('superficieConstruidaResiduos').touched">
                                            Only numbers, commas, and decimal points are allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                       <button class="btn-save" type="submit" [disabled]="form.invalid">{{'save' | tr}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </cdk-accordion-item>
            </cdk-accordion>
        </div>
    </div>
    <div *ngIf="!bajorasante.length">
        <h3 class="main-heading">{{'emptyPlants'| tr}}</h3>
    </div>
</comment> -->