import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { URL_API } from 'src/main';
import { ProjectService, TipoFamilia, TipoPatinejo } from '../services/project.service';

@Component({
  selector: 'app-familias',
  templateUrl: './familias.component.html',
  styleUrls: ['./familias.component.css'],
})



export class FamiliasComponent implements OnInit {

  projectId: string = "";
  familias: Familia[] = [];
  tipofamilia: TipoFamilia[] = [];
  isLoader:boolean = false;
  selectedTipoFamilia: { [key: string]: TipoFamilia } = {};
  filter: boolean = false;

  constructor(public auth: AuthService,
    private route: ActivatedRoute,
    private http: HttpClient,
    public projectService: ProjectService,
    private cdRef: ChangeDetectorRef) {


    this.selectedTipoFamilia = this.projectService.familyType;
  }

  ngOnInit() {
    this.isLoader = true;
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.projectService.setProjectId(this.projectId);
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };

        this.http.get<Familia[]>(URL_API + '/proyectos/familias/' + this.projectService.projectId.getValue(), httpOptions).subscribe(result => {
          this.isLoader = false;
          this.familias = this.getUniqueFamilias(result);
        },err =>{
          this.isLoader = false;
        });

      }else{
        this.isLoader = false;
      }
    })
  }
  getUniqueFamilias(familias: Familia[]): Familia[] {
    const uniqueFamilias: Familia[] = [];
    const uniqueKeys = new Set<string>();
    // Recorrer todas las familias recibidas en el resultado
    familias.forEach(familia => {
      const key = familia.nombreFamilia + familia.nombre;
      // Si el nombreFamilia + nombre aún no existe en el Set uniqueKeys, agregarlo al arreglo uniqueFamilias y al Set uniqueKeys
      
      this.projectService.familyType[key] = this.projectService.tipoFamilia.find(x => x.id == familia.idTipoFamilia);
      if (!uniqueKeys.has(key)) {
        uniqueKeys.add(key);
        uniqueFamilias.push(familia);
      }
    });

    return uniqueFamilias;
  }

  filtrarfamiliasConNombreNoVacio() {
    if (this.filter) {
      return this.familias.filter(familia => {
        const nombre = this.getNombre(familia);
        return nombre.trim() === ''; // Filtrar solo si el nombre no está vacío (sin espacios)
      });
    } else {
    }
    return this.familias;
  }

  textoFiltro: string = "Quitar asignados";
  filtrar() {
    this.filter = !this.filter;
    if (this.filter) {
      this.textoFiltro = "Mostrar todos";
    } else {
      this.textoFiltro = "Quitar asignados";
    }
    this.cdRef.detectChanges();
  }

  storeFamilyType(familia: Familia, tipo: TipoFamilia) {
    this.projectService.familyType[familia.nombreFamilia+familia.nombre] = tipo;
    this.projectService.CambiarTipoFamilia(familia.nombreFamilia+familia.nombre, tipo.id);
    this.projectService.storeFamiliaType(familia.nombreFamilia, familia.nombre, tipo);
  }

  getNombre(familia: Familia) {
    if (this.projectService.familyType[familia.nombreFamilia + familia.nombre] == undefined) {

      return "";
    }
    return this.projectService.familyType[familia.nombreFamilia + familia.nombre].nombre;
  }

}

interface Familia {
  id: string;
  nombre: string;
  nombreFamilia: string;
  IdVivienda: string;
  IdPlanta: string;
  IdEscalera: string;
  IdBloque: string;
  Vivienda: string;
  Planta: string;
  Escalera: string;
  Bloque: string;
  idTipoFamilia: string;
}
