import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Translator } from './lang-translator';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'LetsMEP';
constructor(public auth: AuthService,private translator:Translator){
}
  ngOnInit(){
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {
        window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'userToken', 'obj': accessToken.__raw}));
      }
    })
  }
}
