<app-loading *ngIf="isLoader"></app-loading>
<section class="page-section" *ngIf="!isLoader">
 <div class="page-section-div">
  <mat-accordion>
    <mat-expansion-panel class="unexpend-header-expension">
      <mat-expansion-panel-header>
        <mat-panel-title style="flex: 0 0 25%;font-size: medium; font-weight: bold;" >
          {{'door_col1' | tr}}
        </mat-panel-title>
        <mat-panel-title style="flex: 0 0 25%;font-size: medium; font-weight: bold;">
          {{'door_col6' | tr}}
        </mat-panel-title>
        <mat-panel-title style="flex: 0 0 20%;font-size: medium; font-weight: bold;">
          {{'door_col2' | tr}}
        </mat-panel-title>
        <mat-panel-title style="flex: 0 0 10%;font-size: medium; font-weight: bold;">
          {{'door_col3' | tr}}
        </mat-panel-title>
        <mat-panel-title style="flex: 0 0 10%;font-size: medium; font-weight: bold;">
          {{'door_col4' | tr}}
        </mat-panel-title>
        <!-- <mat-panel-title>
          {{'door_col5' | tr}}
        </mat-panel-title> -->
      </mat-expansion-panel-header>
    </mat-expansion-panel>
    <mat-expansion-panel *ngFor="let puerta of uniqPuertas;let index = index">
      <mat-expansion-panel-header style="min-height: 60px; height: auto;">
        <mat-panel-title style="flex: 0 0 25%;">
          <span style="word-wrap: break-word; width: 100%; display: block; white-space: normal; word-break: break-all;">{{puerta.nombreFamilia}}</span>
        </mat-panel-title>
        <mat-panel-title style="flex: 0 0 25%;">
          <span style="word-wrap: break-word; width: 100%; display: block; white-space: normal; word-break: break-all;">{{puerta.nombre}}</span>
        </mat-panel-title>
        <mat-panel-title style="flex: 0 0 20%;">
          <span style="word-wrap: break-word; width: 100%; display: block; white-space: normal; word-break: break-all;">{{puerta.count}}</span>
        </mat-panel-title>
        <mat-panel-title style="flex: 0 0 10%;">
          <div (click)="$event.stopPropagation();" style="cursor: pointer;">
            <mat-checkbox class="example-margin" color="primary" [checked]="puerta.isEscalera"  [indeterminate]="puerta.isEscalera==null?puerta.isEscalera: !puerta.isEscalera" 
            (change)="stairChange($event , puerta)"
            >
            </mat-checkbox>
          </div>
        </mat-panel-title>
        <mat-panel-title style="flex: 0 0 10%;">
          <div (click)="$event.stopPropagation();" style="cursor: pointer;">
            <mat-checkbox class="example-margin"
            [checked]="puerta.principal"
            color="primary"
            [indeterminate]="puerta.principal==null?puerta.principal: !puerta.principal"
            (change)="principalChange($event , puerta)">
            </mat-checkbox>
          </div>
        </mat-panel-title>
        <!-- <mat-panel-title>
          <div (click)="$event.stopPropagation();" style="cursor: pointer;">
            <mat-checkbox class="example-margin"
            [checked]="puerta.ventana"
            color="primary"
            [indeterminate]="puerta.ventana==null?puerta.ventana: !puerta.ventana"
            (change)="ventanaChange($event,puerta)">
            </mat-checkbox>
          </div>
        </mat-panel-title> -->
      </mat-expansion-panel-header>
      <mat-expansion-panel class="unexpend-header-expension" *ngIf="puerta?.subPuertas?.length > 1">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'look_for' | tr}}
          </mat-panel-title>
          <mat-panel-title>
           {{'location' | tr}}
          </mat-panel-title>
          <mat-panel-title>
            {{'door_col3' | tr}}
           </mat-panel-title>
          <mat-panel-title>
            {{'door_col4' | tr}}
          </mat-panel-title>
          <!-- <mat-panel-title>
            {{'door_col5' | tr}}
          </mat-panel-title> -->
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <mat-expansion-panel  *ngFor="let subPuerta of puerta.subPuertas ; let isFirst = first ; let index2 = index">
        <mat-expansion-panel-header style="min-height: 60px; height: auto;">
          <mat-panel-title>
            <button mat-icon-button (click)="searchFamily(subPuerta)">
              <mat-icon class="mat-icon-rtl-mirror">
                search
              </mat-icon>
            </button>
          </mat-panel-title>
          <mat-panel-title>
            {{subPuerta.location}}
          </mat-panel-title>
          <mat-panel-title>
            <div style="cursor: pointer;">
              <mat-checkbox [(ngModel)]="subPuerta.isEscalera" color="primary" (ngModelChange)="stairIndividualChange($event,puerta,subPuerta)">
              </mat-checkbox>
            </div>
          </mat-panel-title>
          <mat-panel-title>
            <div style="cursor: pointer;">
              <mat-checkbox [(ngModel)]="subPuerta.principal" color="primary" (ngModelChange)="principalIndividualChange($event,puerta,subPuerta)">
              </mat-checkbox>
            </div>
          </mat-panel-title>
          <!-- <mat-panel-title>
            <div (click)="$event.stopPropagation();" style="cursor: pointer;">
              <mat-checkbox [(ngModel)]="subPuerta.ventana"
                color="primary"
                (ngModelChange)="ventanaIndividualChange($event,puerta,subPuerta)">
              </mat-checkbox>
            </div>
          </mat-panel-title> -->
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-expansion-panel>
  </mat-accordion>
 </div>
</section>