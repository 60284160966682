<app-loading *ngIf="isLoader"></app-loading>
<section class="page-section" *ngIf="!isLoader">
    <div class="plant-selection-main">
        <div cdkDropListGroup>
            <div class="mb-5">
               <div class="d-flex header-icon">
                  <img src="../../../../assets/icons/level-up.png" alt="level-up" srcset="" height="24" class="mr-3">
                  <h4 class="main-heading">{{'Sobre_heading' | tr}}</h4>
               </div>
                <cdk-accordion class="example-accordion" cdkDropList
                [cdkDropListData]="sobrerasante"
                class="example-list"
                (cdkDropListDropped)="drop($event,'sobrerasante',$event.previousContainer.data)" [ngClass]="{'none':sobrerasante.length===0}">
                    <cdk-accordion-item
                      *ngFor="let item of sobrerasante; let index = index;"
                      #accordionItem="cdkAccordionItem"
                      class="example-accordion-item"
                      role="button"
                      tabindex="0"
                      [attr.id]="'accordion-header-' + index"
                      [attr.aria-expanded]="accordionItem.expanded"
                      [attr.aria-controls]="'accordion-body-' + index" cdkDrag>
                      <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                        <div class="inner-heading">
                            <p>{{ item.nombre }}</p>
                        </div>
                      </div>
                      <div
                        class="example-accordion-item-body"
                        role="region"
                        [style.display]="accordionItem.expanded ? '' : 'none'"
                        [attr.id]="'accordion-body-' + index"
                        [attr.aria-labelledby]="'accordion-header-' + index">
                      </div>
                    </cdk-accordion-item>
                    <p *ngIf="!sobrerasante.length">{{'drag&drop' | tr}}</p>
                </cdk-accordion>
            </div>
            <div class="mb-5">
                <div class="d-flex header-icon">
                  <img src="../../../../assets/icons/level-down.png" alt="level-down" srcset="" height="24" class="mr-3">
                  <h4 class="main-heading">{{'bajo_heading' | tr}}</h4>
                </div>
                <cdk-accordion class="example-accordion"
                cdkDropList
                [cdkDropListData]="bajorasante"
                class="example-list"
                (cdkDropListDropped)="drop($event,'bajorasante',$event.previousContainer.data)"
                [ngClass]="{'none':bajorasante.length===0}">
                    <cdk-accordion-item
                      *ngFor="let item of bajorasante; let index = index;"
                      #accordionItem="cdkAccordionItem"
                      class="example-accordion-item"
                      role="button"
                      tabindex="0"
                      [attr.id]="'accordion-header-' + index"
                      [attr.aria-expanded]="accordionItem.expanded"
                      [attr.aria-controls]="'accordion-body-' + index" cdkDrag>
                      <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                        <div class="inner-heading">
                            <p>{{ item.nombre }}</p>
                        </div>
                      </div>
                      <div
                        class="example-accordion-item-body"
                        role="region"
                        [style.display]="accordionItem.expanded ? '' : 'none'"
                        [attr.id]="'accordion-body-' + index"
                        [attr.aria-labelledby]="'accordion-header-' + index">
                      </div>
                    </cdk-accordion-item>
                    <p *ngIf="!bajorasante.length">{{'drag&drop' | tr}}</p>
                </cdk-accordion>
            </div>
        </div>
    </div>
</section>
