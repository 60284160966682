import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { URL_API } from 'src/main';
import { ProjectService, TipoHabitacion, TipoPatinejo } from '../services/project.service';

@Component({
  selector: 'app-habitaciones',
  templateUrl: './habitaciones.component.html',
  styleUrls: ['./habitaciones.component.css'],
})



export class HabitacionesComponent implements OnInit {

  habitaciones: string[] = [];
  tipohabitacion: TipoHabitacion[] = [];
  isLoader:boolean = false;

  selectedTipoHabitacion : {[key: string]: TipoHabitacion} = {};
  filter: boolean = false;

  constructor(public auth: AuthService, 
    private route: ActivatedRoute, 
    private http: HttpClient,
    public projectService: ProjectService,
    private cdRef: ChangeDetectorRef) {

   
    this.selectedTipoHabitacion = this.projectService.habitacionType;
  }

  ngOnInit() {
    this.isLoader = true;
    this.projectService.setProjectId(this.route.snapshot.paramMap.get('id'));

    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };

        this.http.get<Habitacion[]>(URL_API + '/proyectos/habitaciones/' + this.projectService.projectId.getValue(), httpOptions).subscribe(result => {
          this.isLoader = false;
          result.forEach(element => {
            this.projectService.habitacionType[element.nombre] = this.projectService.tipoHabitacion.find(tipo => tipo.id == element.idTipoHabitacion);
          });
          this.habitaciones = [...new Set(result.map(item => item.nombre))];
        },err=>{
          this.isLoader = false;
        });

      }else{
        this.isLoader = false;
      }
    },err =>{
      this.isLoader = false;
    })
  }

  filtrarHabitacionesConNombreNoVacio() {
    if (this.filter){
      return this.habitaciones.filter(habitacion => {
        const nombre = this.getNombre(habitacion);
        return nombre.trim() === ''; // Filtrar solo si el nombre no está vacío (sin espacios)
      });
    }else{
    }
    return this.habitaciones;
  }

  textoFiltro:string = "Quitar asignados";
  filtrar(){
    this.filter = !this.filter;
    if (this.filter){
      this.textoFiltro = "Mostrar todos";
    }else{
      this.textoFiltro = "Quitar asignados";
    }
    this.cdRef.detectChanges();
  }

  storeHabitacionType(habitacion: string, tipo: TipoHabitacion) {
    this.projectService.habitacionType[habitacion] = tipo;
    this.projectService.CambiarTipoHabitacion(habitacion, tipo.id);
    this.projectService.storeHabitacionType(habitacion, tipo);
  }

  getNombre(habitacion: string) {
    if (this.projectService.habitacionType[habitacion] == undefined){

      return "";
    }
    return this.projectService.habitacionType[habitacion].nombre;
  }
  
}

interface Habitacion {
  id: string;
  nombre: string;
  IdVivienda: string;
  IdPlanta: string;
  IdEscalera: string;
  IdBloque: string;
  Vivienda: string;
  Planta: string;
  Escalera: string;
  Bloque: string;
  idTipoHabitacion: string;
}
