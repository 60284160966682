<div class="navbar-top">
    <div class="navbar-top-sec-1" >
      <img src="../../../assets/Cancel.png" alt="" srcset="" width="24" routerLink="/">
      <div class="horizental-line"></div>
      <div *ngIf="!editMode" class="d-flex align-items-center">
        <p class="navbar-top-heading">{{project?.tituloProyecto}}</p>
        <p class="navbar-top-heading" *ngIf="title">{{title}}</p>
        <img src="../../../assets/edit_FILL0_wght400_GRAD0_opsz48.png" alt="" srcset="" class="mb-2 ml-2" (click)="editMode =true">
      </div>
      <div *ngIf="editMode" class="d-flex edit-mode">
          <input #selectInput (click)="onClicInput(selectInput)"  contenteditable="true" type="text" class="navbar-top-heading"  [(ngModel)]="tituloProyecto" [style.width.px]="tituloProyecto.length*15+100">
          <img src="../../../assets/icons/save-icon.png" alt="" srcset="" class="" (click)="updateProjectName()" width="24">
      </div>
    </div>
    
    <div class="d-flex">
      <button *ngIf="project?.idEstado === 'c71adf43-afb3-49a5-9c79-e50ee74a45ef'" class="main-btn" (click)="downloadProject()">{{'download' | tr}}</button>
      <button *ngIf="proyectoService.isRevitData" class="main-btn" (click)="onProcess()">{{'get_rooms_revit' | tr}}</button>
      <div class="flag-icons ml-3">
        <select *ngIf="selectLang" [(ngModel)]="selectLang" class="language-select" (change)="onLanguageChange($event.target.value)">
          <option *ngFor="let x of ['en', 'es']" [value]="x">
            {{ x}}
          </option>
        </select>
        <img class="ml-1" *ngIf="selectLang === 'en'" src="../../../assets/english1.png" alt="English" style="width: 16px !important;">
        <img class="ml-1" *ngIf="selectLang === 'es'" src="../../../assets/spanish2.png" alt="Spanish" style="width: 16px !important;">
      </div>
    </div>
  </div>
  <!-- <div class="navbar-bottom">
    <div class="d-flex align-items-center">
        <carousel *ngIf="isMobile" [itemsPerSlide]="2" [singleSlideOffset]="true" [interval]="0" [(activeSlide)]="activeSlideIndex">
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/ubicacion',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'location' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/paramselection/',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'parameters' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/habitaciones/',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn" style="background-color: #5479F757 0% 0% no-repeat padding-box !important;">
                  <img src="../../../assets/Group 16.png"> {{'bedrooms'|tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button  [routerLink]="['/project/familias/',proyectoService.projectId]" [routerLinkActive]="['active-link']"   class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'families' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/',proyectoService.projectId]"  [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'project_structure' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button  [routerLink]="['/project/patinejos/',proyectoService.projectId]" [routerLinkActive]="['active-link']"   class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'males' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/ventanas/',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'windows'| tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/puertas/',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'doors' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/project-data',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'project_data' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/projecto/plant-selection',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'plant-stepper' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/projecto/ground-plants',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'gl-plant-stepper' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/projecto/over-ground-plants',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png">  {{'ol-plant-stepper' | tr}}
              </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/projecto/upload-project',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png">  {{'upload-project-stepper' | tr}}
              </button>
            </div>
          </slide>
        </carousel>
        <carousel *ngIf="!isMobile && isTablet" [itemsPerSlide]="4" [singleSlideOffset]="true" [interval]="0" [(activeSlide)]="activeSlideIndex">
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/ubicacion',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'location' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/paramselection/',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'parameters' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/habitaciones/',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn" style="background-color: #5479F757 0% 0% no-repeat padding-box !important;">
                  <img src="../../../assets/Group 16.png"> {{'bedrooms'|tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button  [routerLink]="['/project/familias/',proyectoService.projectId]" [routerLinkActive]="['active-link']"   class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'families' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/',proyectoService.projectId]"  [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'project_structure' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button  [routerLink]="['/project/patinejos/',proyectoService.projectId]" [routerLinkActive]="['active-link']"   class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'males' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/ventanas/',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'windows'| tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/puertas/',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'doors' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/project-data',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'project_data' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/projecto/plant-selection',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'plant-stepper' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/projecto/ground-plants',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'gl-plant-stepper' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/projecto/over-ground-plants',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png">  {{'ol-plant-stepper' | tr}}
              </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/projecto/upload-project',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png">  {{'upload-project-stepper' | tr}}
              </button>
            </div>
          </slide>
        </carousel>
        <carousel *ngIf="!isMobile && !isTablet" [itemsPerSlide]="6" [singleSlideOffset]="true" [interval]="0" [(activeSlide)]="activeSlideIndex">
            
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/ubicacion',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'location' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/paramselection/',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'parameters' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/habitaciones/',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn" style="background-color: #5479F757 0% 0% no-repeat padding-box !important;">
                  <img src="../../../assets/Group 16.png"> {{'bedrooms'|tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button  [routerLink]="['/project/familias/',proyectoService.projectId]" [routerLinkActive]="['active-link']"   class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'families' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/',proyectoService.projectId]"  [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'project_structure' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button  [routerLink]="['/project/patinejos/',proyectoService.projectId]" [routerLinkActive]="['active-link']"   class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'males' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/ventanas/',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'windows'| tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/puertas/',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'doors' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/project-data',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png"> {{'project_data' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/plant-selection',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png">  {{'plant-stepper' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/project/ground-plants',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png">  {{'gl-plant-stepper' | tr}}
                </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/projecto/over-ground-plants',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png">  {{'ol-plant-stepper' | tr}}
              </button>
            </div>
          </slide>
          <slide>
            <div class="responsive-m">
              <button [routerLink]="['/projecto/upload-project',proyectoService.projectId]" [routerLinkActive]="['active-link']" class="navbar-bottom-btn">
                  <img src="../../../assets/Group 16.png">  {{'upload-project-stepper' | tr}}
              </button>
            </div>
          </slide>
        </carousel>
    </div>
  </div> -->
  