<div class="patinejo-card" [ngClass]="{'expanded': patinejo.detailsVisible}">
    <div class="patinejo-header" >
        <div style="display: flex; align-items: center; gap: 15px; cursor: pointer; width: 200px; font-weight: bold;" (click)="toggleDetails()">
            <mat-icon
                [ngStyle]="{ transform: patinejo.detailsVisible ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s ease' }">
                expand_more
            </mat-icon>
            <div class="patinejo-noc">
                <span>{{ patinejo.nombreInstalacion }}</span>
            </div>
        </div>
        <div class="patinejo-name">
            <span>{{ 'Patinejos: ' + patinejo.montantes.length }}</span>
        </div>
        <div class="patinejo-name">
            <span>{{ (patinejo.nombreInstalacion == 'Saneamiento fecal' ? 'Habitaciones: ' : 'Viviendas: ') +
                (patinejo.numeroUbicaciones) }}</span>
        </div>
        <mat-icon style="cursor: pointer;" (click)="recalcularInstalacion(patinejo)">
            replay
        </mat-icon>
    </div>
    <div class="patinejo-details" *ngIf="patinejo.detailsVisible">
        <div cdkDropListGroup>
            <div *ngFor="let montante of patinejo.montantes" class="montante-card"
                [ngClass]="{'expanded': montante.showLocations}" cdkDropList [cdkDropListData]="montante.ubicaciones"
                (cdkDropListDropped)="drop($event, montante)">

                <div style="display: flex; align-items: center; gap: 15px; cursor: pointer;"
                    (click)="montante.showLocations = !montante.showLocations">
                    <mat-icon
                        [ngStyle]="{ transform: montante.showLocations ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s ease' }">
                        expand_more
                    </mat-icon>
                    <span>{{ montante.nombre }}</span>
                    <mat-icon *ngIf="enRevit" (click)="searchPatinejo(montante)">
                        search
                    </mat-icon>
                    <span>
                        {{ (patinejo.nombreInstalacion == 'Saneamiento fecal' ? 'Habitaciones: ' : 'Viviendas: ') + getUbicacionesConBloque(montante) }}
                    </span>
                </div>
                <div colspan="4" [hidden]="!montante.showLocations">
                    <table class="ubicaciones-table">
                        <thead>
                            <tr>
                                <th>Bloque</th>
                                <th>Escalera</th>
                                <th>Planta</th>
                                <th>Vivienda</th>
                                <th *ngIf="patinejo.nombreInstalacion == 'Saneamiento fecal'">
                                    Habitación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="montante.numeroUbicaciones > 0; else emptyRow">
                                <tr *ngFor="let tipo of montante.ubicaciones" class="example-box" cdkDrag>
                                    <td *ngIf="tipo.bloque">{{ tipo.bloque.nombre }}</td>
                                    <td *ngIf="tipo.escalera">{{ tipo.escalera.nombre }}</td>
                                    <td *ngIf="tipo.planta">{{ tipo.planta.nombre }}</td>
                                    <td *ngIf="tipo.vivienda" [ngClass]="{ 'clickavaliable': enRevit }"
                                        (click)="searchVivienda(tipo.vivienda)">
                                        {{ tipo.vivienda.nombre }}
                                    </td>
                                    <td *ngIf="tipo.habitacion" [ngClass]="{ 'clickavaliable': enRevit }"
                                        (click)="searchRoom(tipo.habitacion)">
                                        {{ tipo.habitacion.nombre }}
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-template #emptyRow>
                                <tr class="empty-row">
                                    <td colspan="5">Arrastra elementos aquí</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>