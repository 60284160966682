<h1 mat-dialog-title>{{'new_project' | tr}}</h1>
<div mat-dialog-content>
  <p>{{'confirm_new_project_text1' | tr}} ?</p>
  <mat-form-field style="width: 100%;">
    <mat-label>{{'confirm_new_project_placeholder1' | tr}}</mat-label>
    <input matInput [(ngModel)]="data.nombre">
  </mat-form-field>
  <div>
    <mat-form-field style="width: 100%;">
      <mat-label>{{'select_project_type' | tr}}</mat-label>
      <mat-select [(ngModel)]="data.tipoProyecto">
        <mat-option value="todasLasInstalaciones">{{'todas_las_instalaciones' | tr}}</mat-option>
        <mat-option value="salubridad">{{'salubridad' | tr}}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">{{'confirm_new_project_btn2' | tr}}</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{'confirm_new_project_btn1' | tr }}</button>
</div>