import { AfterContentInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../services/project.service';
@Component({
  selector: 'app-project-home',
  templateUrl: './project-home.component.html',
  styleUrls: ['./project-home.component.css']
})
export class ProjectHomeComponent implements OnInit {
  
  isLoader:boolean = false;

  loaderFromRevit(data: boolean) {
    this.isLoader = data;
  }
  @ViewChild('stepper') stepper: MatStepper;
  closedStep1: boolean = false;
  closedStep2: boolean = false;
  closedStep3: boolean = false;
  closedStep4: boolean = false;
  closedStep5: boolean = false;
  closedStep6: boolean = false;
  closedStep7: boolean = false;
  closedStep8: boolean = false;
  closedStep9: boolean = false;
  closedStep10: boolean = false;
  closedStep11: boolean = false;
  closedStep12: boolean = false;
  closedStep13: boolean = false;
  closedStep14: boolean = false;
  closedStep15:boolean = false;
  selectedIndex: number=0;
  projectId:string;
  stepperOrientation: Observable<StepperOrientation>;
  constructor(private _formBuilder: FormBuilder, breakpointObserver: BreakpointObserver,private apiService:ApiServices,private route:ActivatedRoute,private router:Router,public projectService: ProjectService) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 1200px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
    }
    ngOnInit() {
      const stepIndex = this.route.snapshot.paramMap.get('stepIndex');
      this.projectId = this.route.snapshot.paramMap.get('id');
      this.projectService.setprojectData(this.projectId);
    if(stepIndex){
      this.selectedIndex = Number(stepIndex);
    }
    setTimeout(() => {
      this.initialScrollToStep();
    }, 0);
  }
  onStep1(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    event.stopPropagation();
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep1 = !this.closedStep1;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
    console.log('selected step:', this.stepper.selectedIndex)
  }
  onStep2(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep2 = !this.closedStep2;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  onStep3(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep3 = !this.closedStep3;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  onStep4(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep4 = !this.closedStep4;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  onStep5(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep5 = !this.closedStep5;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  onStep6(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep6 = !this.closedStep6;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  onStep7(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep7 = !this.closedStep7;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  onStep8(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep8 = !this.closedStep8;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  onStep9(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep9 = !this.closedStep9;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  onStep10(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep10 = !this.closedStep10;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  onStep11(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep11 = !this.closedStep11;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  onStep12(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep12 = !this.closedStep12;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  onStep13(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep13 = !this.closedStep13;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  onStep14(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep14= !this.closedStep14;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
      
    }
  }
  onStep15(event: any, currentIndex: number) {
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),currentIndex]);
    if (this.stepper.selectedIndex === currentIndex) {
      this.closedStep15= !this.closedStep15;
    } else {
      this.stepper.selectedIndex = currentIndex;
      this.scrollToStep();
      this.closedStep1 = false;
      this.closedStep2 = false;
      this.closedStep3 = false;
      this.closedStep4 = false;
      this.closedStep5 = false;
      this.closedStep6 = false;
      this.closedStep7 = false;
      this.closedStep8 = false;
      this.closedStep9 = false;
      this.closedStep10 = false;
      this.closedStep11 = false;
      this.closedStep12 = false;
      this.closedStep13 = false;
      this.closedStep14 = false;
      this.closedStep15 = false;
    }
  }
  stepperPrevious(event) {
    event.stopPropagation();
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),this.stepper.selectedIndex-1]);
    this.stepper.previous();
    this.scrollToStep();
  }
  stepperNext(event) {
    event.stopPropagation();
    this.router.navigate(['/project-home/',this.projectService.projectId.getValue(),this.stepper.selectedIndex+1]);
    this.stepper.next();
    this.scrollToStep();
  }

  scrollToStep(){
    const scrollPositions = [100, 175, 245,320, 390,460, 530,600, 670, 740, 810, 880,950,1020,1090];
    const selectedIndex = this.stepper.selectedIndex;
    const scrollPosition = scrollPositions[selectedIndex] || 0;
    window.scrollTo(0, scrollPosition);
  }
  initialScrollToStep(){
    const scrollPositions = [100, 175, 245,320, 390,460, 530,600, 670, 740, 810, 880,950,1020,1090];
    const selectedIndex = this.selectedIndex;
    const scrollPosition = scrollPositions[selectedIndex] || 0;
    window.scrollTo(0, scrollPosition);
  }
}
