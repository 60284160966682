<app-loading *ngIf="isLoader"></app-loading>
<div class="page-section" *ngIf="!isLoader">
  <button *ngIf="projectService.isRevitData" class="main-btn" (click)="openPatinejos()">{{'open_patinejos_revit' |
    tr}}</button>
  <p *ngIf="!projectService.isRevitData">Herramienta de creación solo está disponible desde Revit</p>

  <div *ngIf="patinejos && patinejos.length > 0" class="page-section-div">
    <div class="filters-container">
      <div class="filter-block">
        <!-- Filtro de Instalaciones -->
        <div class="filter-section">
          <ng-select [items]="nombreInstalaciones" [multiple]="true" bindLabel="nombreInstalacion"
            [(ngModel)]="selectedNombresInstalaciones" (ngModelChange)="onFilterChange()" [closeOnSelect]="false"
            [hideSelected]="true" [searchable]="true" [clearSearchOnAdd]="true" placeholder="Instalaciones">
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="chip">
                <span>{{ item }}</span>
                <button (click)="removeInstalacion(item)">x</button>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div class="custom-option">
                <input type="checkbox" [checked]="selectedNombresInstalaciones.includes(item)" /> {{ item }}
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="filter-block">
        <!-- Filtro de Bloques -->
        <div class="filter-section">
          <ng-select [items]="nombreBloques" [multiple]="true" bindLabel="nombreBloque" [(ngModel)]="selectedBloques"
            (ngModelChange)="onFilterChange()" [closeOnSelect]="false" [hideSelected]="true" [searchable]="true"
            [clearSearchOnAdd]="true" placeholder="Bloques">
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="chip">
                <span>{{ item }}</span>
                <button (click)="removeBloque(item)">x</button>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div class="custom-option">
                <input type="checkbox" [checked]="selectedBloques.includes(item)" /> {{ item }}
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="filter-block">
        <!-- Filtro de Escaleras -->
        <div class="filter-section">
          <ng-select [items]="nombreEscaleras" [multiple]="true" bindLabel="nombreEscalera"
            [(ngModel)]="selectedEscaleras" (ngModelChange)="onFilterChange()" [closeOnSelect]="false"
            [hideSelected]="true" [searchable]="true" [clearSearchOnAdd]="true" placeholder="Escaleras">
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="chip">
                <span>{{ item }}</span>
                <button (click)="removeEscalera(item)">x</button>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div class="custom-option">
                <input type="checkbox" [checked]="selectedEscaleras.includes(item)" /> {{ item }}
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="filter-block">
        <!-- Filtro de Plantas -->
         
        <div class="filter-section">
          <ng-select [items]="nombrePlantas" [multiple]="true" bindLabel="nombrePlanta" [(ngModel)]="selectedPlantas"
            (ngModelChange)="onFilterChange()" [closeOnSelect]="false" [hideSelected]="true" [searchable]="true"
            [clearSearchOnAdd]="true" placeholder="Plantas">
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="chip">
                <span>{{ item }}</span>
                <button (click)="removePlanta(item)">x</button>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div class="custom-option">
                <input type="checkbox" [checked]="selectedPlantas.includes(item)" /> {{ item }}
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="filter-block">
        <!-- Filtro de Viviendas -->
         
        <div class="filter-section">
          <ng-select #selectViviendas [items]="nombreViviendas" [multiple]="true" bindLabel="nombreVivienda"
            [(ngModel)]="selectedViviendas" (ngModelChange)="onFilterChange()" [closeOnSelect]="false"
            [hideSelected]="true" [searchable]="true" [clearSearchOnAdd]="true" placeholder="Viviendas">
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <div class="chip">
                <span>{{ item }}</span>
                <button (click)="removeVivienda(item)">x</button>
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div class="custom-option">
                <input type="checkbox" [checked]="selectedViviendas.includes(item)" /> {{ item }}
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="expand-all">
      <button (click)="toggleAllDetails()">Expandir/Retractar Todo</button>
      <button (click)="recalcular()">Recalcular</button>
    </div>

    <div *ngFor="let patinejo of filteredPatinejos">
      <app-instalacion-patinejo-card (notifyParent)="onReloadChild()" [patinejo]="patinejo"></app-instalacion-patinejo-card>
    </div>
  </div>
</div>