import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AuthService } from '@auth0/auth0-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_API } from 'src/main';
import { identifierName } from '@angular/compiler';
import { ProyectoPatinejo_TipoPatinejoMontante_Ubicacion, Patinejo } from 'src/app/Shared/modals/patinejo.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-instalacion-patinejo-card',
  templateUrl: './instalacion-patinejo-card.component.html',
  styleUrls: ['./instalacion-patinejo-card.component.css']
})
export class InstalacionPatinejoCardComponent {
  @Input() patinejo: any;
  @Output() notifyParent = new EventEmitter<void>();
  enRevit: boolean = true;
  toggleDetails() {
    this.patinejo.detailsVisible = !this.patinejo.detailsVisible;
  }

  constructor(
    public auth: AuthService,
    private http: HttpClient,
    private toastr:ToastrService,
    public projectService: ProjectService) {
      this.enRevit = this.projectService.isRevitData;

   }

  ngOnInit() {
    // Inicializa la propiedad showLocations en false para cada montante
    this.patinejo.montantes.forEach((montante: any) => {
      montante.showLocations = false;
    });
  }

  searchRoom(habitacion) {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchRoom', 'obj': habitacion }));
  }
  searchVivienda(vivienda) {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchVivienda', 'obj': vivienda }));
  }

  searchPatinejo(idElement){
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchFamily', 'obj': idElement }));
  }

  recalcularInstalacion(patinejo: Patinejo) {
    //this.isLoader = true;
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };
        
        var nombreInstalacion = patinejo.nombreInstalacion;
        switch (patinejo.nombreInstalacion) {  
          case "Saneamiento fecal":
            nombreInstalacion = "saneamiento";
            break;
          case "Fontanería":
            nombreInstalacion = "fontaneria";
            break;
          case "Electricidad":
            nombreInstalacion = "electricidad";
            break;
          case "Telecomunicaciones":
            nombreInstalacion = "telecomunicaciones";
            break;
          case "Climatización":
            nombreInstalacion = "climatizacion";
            break;
          case "Ventilación":
            nombreInstalacion = "ventilacion";
            break;
          case "Saneamiento pluvial":
            nombreInstalacion = "saneamientopluvial";
            break;
          case "ACS Centralizada":
            nombreInstalacion = "acscentralizada";
            break;
          case "Salubridad individual":
            nombreInstalacion = "salubridad";
            break;
          case "Campana cocina":
            nombreInstalacion = "cocina";
            break;
          case "Saneamiento fecal":
            nombreInstalacion = "saneamiento";
            break;
        }
        this.http.patch(URL_API + '/proyectos/patinejos/' + this.projectService.projectId.getValue() + '/' + nombreInstalacion,null, httpOptions).subscribe((result) => {
          this.toastr.success('Recálculo ' + patinejo.nombreInstalacion + ' realizado correctamente');
          this.notifyParent.emit();
        });
      }
    });
  }

  drop(event: CdkDragDrop<ProyectoPatinejo_TipoPatinejoMontante_Ubicacion[]>, montanteDestino: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(montanteDestino.ubicaciones, event.previousIndex, event.currentIndex);
    } else {
      montanteDestino.showLocations = true;
      montanteDestino.numeroUbicaciones++;
      event.previousContainer.data[event.previousIndex].idPatinejo = montanteDestino.ubicaciones[0].idPatinejo;
      this.changeUbicacionPatinejo(montanteDestino.ubicaciones[0].idPatinejo,event.previousContainer.data[event.previousIndex]);
      transferArrayItem(
        event.previousContainer.data,
        montanteDestino.ubicaciones,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  changeUbicacionPatinejo(idPatinejoNuevo, patiUbicacion) {
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          })
        };
        this.http.post<any>(URL_API + '/proyectos/patinejos/changeMontanteUbicacion/'+ 
          this.projectService.projectId.getValue() + '/' +
          idPatinejoNuevo + '/' + patiUbicacion.id ,null, httpOptions).subscribe(result => {

        },
          error => {
            console.log(error);
          });
      }
    })
  }

  getUbicacionesConBloque(montante){
    if (montante == null || montante.ubicaciones == null) return 0;
    montante.numeroUbicaciones = 0;
    montante.ubicaciones.forEach(ubicacion => {
      if (ubicacion.idBloque) {
        montante.numeroUbicaciones++;
      }
    });
    return montante.numeroUbicaciones;
  }
}

