<app-loading *ngIf="isLoader"></app-loading>
<div class="page-section" *ngIf="!isLoader">
<div class="page-section-div">
  <h2>{{'room_types' | tr}}</h2>
  <button mat-raised-button color="primary" (click)="filtrar()">{{filter===true?('textoFiltro1' |tr):('textoFiltro2' |tr)}}</button>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let habitacion of filtrarHabitacionesConNombreNoVacio()" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{habitacion}}
        </mat-panel-title>
        <mat-panel-description>
          {{getNombre(habitacion)}}
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <div class="container">
        <div class="header">
          <h4>{{'habitcaion_heading_select1' | tr}}</h4>
          <p>{{'habitcaion_text_select1' | tr}}.</p>
        </div>
        <div class="form-field">
          <mat-form-field>
            <mat-label>{{'habitcaion_label_select1' | tr}}</mat-label>
            <mat-select [(ngModel)]="selectedTipoHabitacion[habitacion]"
            (selectionChange)="storeHabitacionType(habitacion, selectedTipoHabitacion[habitacion])">
              <mat-option *ngFor="let tipo of projectService.tipoHabitacion" [value]="tipo">
                {{tipo.nombre}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      
      </div>
      <!-- <h2>{{'location' | tr}}</h2> -->
      <!-- <mat-nav-list>
        <a mat-list-item href="..." *ngFor="let link of habitacion" [activated]="link.isActive">{{ link }}</a>
      </mat-nav-list> -->
      <mat-divider></mat-divider>
    </mat-expansion-panel>
  </mat-accordion>
</div>
</div>
