<div class="project-data-page">
    <app-loading *ngIf="isLoader"></app-loading>
    <section class="page-section" *ngIf="!isLoader">
      <div class="page-section-div">
        <div class="row">
            <div class="col-12 col-custome">
               <div>
                <!-- <div class="row page-content-row">
                    <div class="col-md-6 col-sm-12">
                        <p  class="page-heading">{{'language' | tr}}</p>
                        <p class="page-text">{{'project_language' | tr}}</p>
                    </div>
                    <div class="col-md-5 col-sm-12 offset-md-1">
                        <mat-form-field class="mat-field">
                            <mat-label *ngIf="!idIdioma">Español</mat-label>
                            <select matNativeControl [(ngModel)]="idIdioma" (change)="changeLang($event)">
                                <option *ngFor="let lang of languages" [value]="lang.id">{{lang.nombre}}</option>
                            </select>
                          </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p  class="page-heading">{{'qualities' | tr}}</p>
                        <p class="page-text">{{'build_quality' | tr}}</p>
                    </div>
                </div>
                <div class="row page-content-row">
                    <div class="col-md-4 col-sm-12 mb-2">
                        <input type="button" name="basic" class="page-btn" (click)="qualities('fbb4b910-7f2a-4009-a773-1467e2fc59ec')" [value]="'basic' | tr" [ngClass]="{ 'page-btn-cond': idCalidad === 'fbb4b910-7f2a-4009-a773-1467e2fc59ec' }">
                    </div>
                    <div class="col-md-4 col-sm-12 mb-2">
                        <input type="button" name="standard" class="page-btn" (click)="qualities('530de6cd-de5e-4894-9bce-606a7ae3b487')" [value]="'standard' | tr" [ngClass]="{ 'page-btn-cond': idCalidad === '530de6cd-de5e-4894-9bce-606a7ae3b487' }">
                    </div>
                    <div class="col-md-4 col-sm-12 mb-2">
                        <input type="button" name="premium" class="page-btn" (click)="qualities('4a6349fb-6751-4a3d-9cb3-7d4b2717194b')" [value]="'premium' | tr" [ngClass]="{ 'page-btn-cond': idCalidad === '4a6349fb-6751-4a3d-9cb3-7d4b2717194b' }">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p  class="page-heading">{{'Energetic_certification' | tr}}</p>
                        <p class="page-text">{{'build_quality' | tr}}</p>
                    </div>
                </div>
                <div class="row page-content-row">
                    <div class="col-md-4 col-sm-12 mb-2">
                        <input type="button" name="AA" class="page-btn" (click)="energeticCertification('1d7be149-de39-4552-b011-80163c536c44')" value="AA" [ngClass]="{ 'page-btn-cond': idCertificacion === '1d7be149-de39-4552-b011-80163c536c44' }">
                    </div>
                    <div class="col-md-4 col-sm-12 mb-2">
                        <input type="button" name="BB" class="page-btn" (click)="energeticCertification('2a1a0a5e-fccd-4132-92a3-86696a3c1a3f')" value="BB" [ngClass]="{ 'page-btn-cond': idCertificacion === '2a1a0a5e-fccd-4132-92a3-86696a3c1a3f' }">
                    </div>
                    <div class="col-md-4 col-sm-12 mb-2">
                        <input type="button" name="BA" class="page-btn" (click)="energeticCertification('942cd3d5-0ee3-45f2-8337-b0ad12ff9f55')" value="BA" [ngClass]="{ 'page-btn-cond': idCertificacion === '942cd3d5-0ee3-45f2-8337-b0ad12ff9f55' }">
                    </div>
                </div> -->
                <div class="row page-content-row">
                    <div class="col-md-6 col-sm-12">
                        <p  class="page-heading">{{'titular' | tr}}</p>
                        <p class="page-text">{{'promotor' | tr}}</p>
                    </div>
                    <div class="col-md-5 col-sm-12 offset-md-1">
                        <mat-form-field class="mat-field">
                            <mat-label>{{'promotor' | tr}}</mat-label>
                            <select matNativeControl [(ngModel)]="idPromotor" (change)="changePromotor($event)">
                                <option *ngFor="let promotor of promotores" [value]="promotor.id">{{promotor.nombre}}</option>
                            </select>
                        </mat-form-field>
                        <button mat-raised-button class="btn btn-primary" (click)="addPromotor()">{{'new_promotor' | tr}}</button>
                    </div>
                </div>
                <div class="row page-content-row">
                    <div class="col-md-6 col-sm-12">
                        <p  class="page-heading">{{'imagen' | tr}}</p>
                        <p class="page-text">{{'imagen' | tr}}</p>
                    </div>
                    <div class="col-md-5 col-sm-12 offset-md-1">
                        <input type="file" (change)="onFileSelected($event)" class="form-control" accept="image/*" />
                        <img *ngIf="image" [src]="image" alt="image" class="img-fluid" />
                    </div>
                </div>
                <!-- <div class="row page-content-row">
                    <div class="col-lg-10 col-md-9 col-sm-12">
                        <p  class="page-heading">{{'standard_specifications' | tr}}</p>
                        <p class="page-text">{{'white_book' | tr}}</p>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-12">
                        <ui-switch [(ngModel)]="libroBlanco" (changeEvent)="standardSpecifications(libroBlanco)" class="switch-btn" checkedLabel="✔" uncheckedLabel="X"></ui-switch>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p  class="page-heading">{{'standard_marks' | tr}}</p>
                        <p class="page-text">{{'choice' | tr}}</p>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-6 offset-md-1 col-sm-12">
                        <p  class="page-sub-heading">{{'air_conditioning' | tr}}</p>
                    </div>
                    <div class="col-md-5 col-sm-12">
                        <mat-form-field class="mat-field">
                            <mat-label>{{'placeholder' | tr}}</mat-label>
                            <select matNativeControl (change)="UpdateAirconditions($event)" [(ngModel)]="idMarcaClimatizacion">
                                <option *ngFor="let item of air_conditions" [value]="item.id" >{{item.nombre}}</option>
                            </select>
                          </mat-form-field>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-6 offset-md-1 col-sm-12">
                        <p  class="page-sub-heading">{{'domestic_hot_water' | tr}}</p>
                    </div>
                    <div class="col-md-5 col-sm-12">
                        <mat-form-field class="mat-field">
                            <mat-label>{{'placeholder' | tr}}</mat-label>
                            <select matNativeControl (change)="UpdateDomestics($event)" [(ngModel)]="idMarcaACS">
                                <option *ngFor="let item of domestics" [value]="item.id" >{{item.nombre}}</option>
                            </select>
                          </mat-form-field>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-6 offset-md-1 col-sm-12">
                        <p  class="page-sub-heading">{{'ventilation' | tr}}</p>
                    </div>
                    <div class="col-md-5 col-sm-12">
                        <mat-form-field class="mat-field">
                            <mat-label>{{'placeholder' | tr}}</mat-label>
                            <select matNativeControl (change)="UpdateVentilations($event)" [(ngModel)]="idMarcaVentilacion">
                                <option *ngFor="let item of ventilations" [value]="item.id" >{{item.nombre}}</option>
                            </select>
                        </mat-form-field>
                    </div>
                </div> -->
               </div>
            </div>
        </div>
      </div>
    </section>
</div>