<app-new-nav-bar [page]="'user-info'"></app-new-nav-bar>
<div class="setting-page">
    <section class="page-section">
      <div class="row">
        <div class="col-lg-5 col-md-12">
            <form [formGroup]="form"  (ngSubmit)="addUserInfo()">
                <div class="form-group-div d-flex row">
                    <div class="col-lg-3 col-xl-2 col-sm-12">
                        <label for="" class="setting-label mr-3">{{'name' | tr}}</label>
                    </div>
                    <div class="col-lg-9 col-xl-10 col-sm-12">
                        <input type="text" class="setting-input" formControlName="nombre" required>
                    </div>
                </div>
                <div class="form-group-div d-flex row">
                    <div class="col-lg-3 col-xl-2 col-sm-12">
                        <label for="" class="setting-label mr-3">{{'surnames' | tr}}</label>
                    </div>
                    <div class="col-lg-9 col-xl-10 col-sm-12">
                        <input type="text" class="setting-input" formControlName="apellidos" required>
                    </div>
                </div>
                <div class="form-group-div d-flex row">
                    <div class="col-lg-4 col-xl-2 col-sm-12">
                        <label for="" class="setting-label mr-3" >{{'days' | tr}}</label>
                    </div>
                    <div class="col-lg-8 col-xl-6 col-sm-12">
                        <input type="text" class="setting-input" formControlName="nif" required>
                    </div>
                </div>
                <div class="form-group-div d-flex row">
                    <div class="col-lg-4 col-xl-2 col-sm-12">
                        <label for="" class="setting-label mr-3">{{'collegiate_number' | tr}}</label>
                    </div>
                    <div class="col-lg-8 col-xl-6 col-sm-12">
                        <input type="number" class="setting-input" formControlName="numero_Colegiado" required>
                    </div>
                </div>
                <div class="form-group-div d-flex row">
                    <div class="col-lg-3 col-xl-2 col-sm-12">
                        <label for="" class="setting-label mr-3">{{'address' | tr}}</label>
                    </div>
                    <div class="col-lg-9 col-xl-10 col-sm-12">
                        <input type="text" class="setting-input" formControlName="direccion" required>
                    </div>
                </div>
                <div class="form-group-div d-flex row">
                    <div class="col-lg-3 col-xl-2 col-sm-12">
                        <label for="" class="setting-label mr-3">{{'contact' | tr}}</label>
                    </div>
                    <div class="col-lg-9 col-xl-10 col-sm-12">
                        <input type="text" class="setting-input" formControlName="contacto" required>
                    </div>
                </div>
                <div class="form-group-div d-flex row">
                    <div class="col-lg-4 col-xl-2 col-sm-12">
                        <label for="" class="setting-label mr-3">{{'telephone' | tr}}</label>
                    </div>
                    <div class="col-lg-8 col-xl-6 col-sm-12">
                        <input type="text" class="setting-input" formControlName="telefono">
                    </div>
                </div>
                <div class="form-group-div d-flex row">
                    <div class="col-lg-4 col-xl-2 col-sm-12">
                        <label for="" class="setting-label mr-3">{{'email' | tr}}</label>
                    </div>
                    <div class="col-lg-8 col-xl-6 col-sm-12">
                        <input type="email" class="setting-input" formControlName="email" required>
                    </div>
                </div>
                <div class="form-group-div d-flex row">
                    <div class="col-lg-3 col-xl-2 col-sm-12">
                        <label for="" class="setting-label mr-3">{{'company' | tr}}</label>
                    </div>
                    <div class="col-lg-9 col-xl-10 col-sm-12">
                        <input type="text" class="setting-input" formControlName="empresa">
                    </div>
                </div>
                <div class="form-group-div d-flex row">
                    <div class="col-lg-4 col-xl-2 col-sm-12">
                        <label for="" class="setting-label mr-3">{{'cif' | tr}}</label>
                    </div>
                    <div class="col-lg-8 col-xl-6 col-sm-12">
                        <input type="text" class="setting-input" formControlName="cif">
                    </div>
                </div>
                <button type="submit" class="btn-setting-bottom-1">{{'save' | tr}}</button>
            </form>
        </div>
        <div class="col-lg-5 offset-lg-2  col-md-12">
            <div>
            
                <div class="d-flex justify-content-between">
                    <div class="mb-3">
                        <button class="setting-2nd-col-btn">{{'new_promoter' | tr}}</button>
                    </div>
                    <div>
                        <p class="setting-2nd-col-text">{{'promotores' | tr}}</p>
                    </div>
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <div class="text-div">
                </div>
                <button class="btn-setting-bottom-1">{{'keep' | tr}}</button>
            </div>
        </div>
      </div>
    </section>
  </div>