import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Proyecto } from 'src/app/Shared/modals/proyecto';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { ProjectService } from './../services/project.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_API } from 'src/main';
import { Promotor } from 'src/app/Shared/modals/promotor';
import { AuthService } from '@auth0/auth0-angular';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LangTranslatorModule, Translator } from 'src/app/lang-translator';

@Component({
  selector: 'app-project-data',
  templateUrl: './project-data.component.html',
  styleUrls: ['./project-data.component.css']
})
export class ProjectDataComponent implements OnInit {
  languages: any;
  promotores: any;
  selectedLang: any;
  selectedPromotor: any;
  idIdioma: string = '';
  idPromotor: string = '';
  air_conditions: any;
  air_condition: any;
  idMarcaClimatizacion: string = '';

  image: any;

  domestics: any;
  domestic: any;
  idMarcaACS: string = '';

  ventilations: any;
  ventilation: any;
  idMarcaVentilacion: string = '';

  projectId: any;
  project: Proyecto;
  idCalidad: string;
  idCertificacion: string;
  libroBlanco: boolean = false;
  isLoader: boolean = false;
  nombrePromotor: any;
  constructor(private apiService: ApiServices,
    public auth: AuthService,
    private http: HttpClient,
    public dialog: MatDialog, private route: ActivatedRoute, private toastr: ToastrService, private projectService: ProjectService) {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.projectService.setProjectId(this.projectId);
  }
  ngOnInit() {
    this.isLoader = true;
    this.apiService.getProyectoById(this.projectId).subscribe(async (res: any) => {
      this.isLoader = false;
      this.project = await res;
      if (this.project) {
        this.projectService.project = this.project;
        if (this.project.imagen && this.project.imagen.imagen) {
            this.image = 'data:image/jpeg;base64,' + this.project.imagen.imagen;
        }
        this.idCalidad = this.project.id_Calidades;
        this.idCertificacion = this.project.id_CertificacionEnergetica
        this.libroBlanco = this.project.libroBlanco;
        this.getAir_conditions();
        this.getDomestics();
        this.getVentilations();
        this.getLanguage();
        this.getPromotor();
      }
    }, error => {
      this.isLoader = false;
      this.toastr.error('something went wrong ! error:', error.error.title)
    })
  }
  getLanguage() {
    this.apiService.getLanguage().subscribe(async (res) => {
      this.languages = await res;
      this.selectedLang = this.languages.find((lang: any) => lang.id === this.project?.idIdioma);
      this.idIdioma = this.selectedLang?.id;
    })
  }

  getPromotor() {
    this.apiService.getPromotor().subscribe(async (res) => {
      this.promotores = await res;
      this.selectedPromotor = this.promotores.find((promotor: any) => promotor.id === this.project?.idPromotor);
      this.idPromotor = this.selectedPromotor?.id;
    })
  }
  getAir_conditions() {
    this.apiService.getAir_conditions().subscribe(async (res) => {
      this.air_conditions = await res;
      this.air_condition = this.air_conditions.find((lang: any) => lang.id === this.project?.idMarcaClimatizacion);
      this.idMarcaClimatizacion = this.air_condition?.id;
    })
  }
  UpdateAirconditions(e) {
    let idMarca = e.target.value;
    if (idMarca) {
      const data = {
        idProyecto: this.projectService.projectId.getValue(),
        idMarca: idMarca
      }
      this.apiService.UpdateAirconditions(data).subscribe(res => {
        console.log(res);
      }, err => {
        console.log(err);
      })
    }
  }
  UpdateDomestics(e) {
    let idMarca = e.target.value;
    if (idMarca) {
      const data = {
        idProyecto: this.projectService.projectId.getValue(),
        idMarca: idMarca
      }
      this.apiService.UpdateDomestics(data).subscribe(res => {
        console.log(res);
      }, err => {
        console.log(err);
      })
    }
  }
  UpdateVentilations(e) {
    let idMarca = e.target.value;
    if (idMarca) {
      const data = {
        idProyecto: this.projectService.projectId.getValue(),
        idMarca: idMarca
      }
      this.apiService.UpdateVentilations(data).subscribe(res => {
        console.log(res);
      }, err => {
        console.log(err);
      })
    }
  }

  getDomestics() {
    this.apiService.getDomestics().subscribe(async (res) => {
      this.domestics = await res;
      this.domestic = this.domestics.find((lang: any) => lang.id === this.project?.idMarcaACS);
      this.idMarcaACS = this.domestic?.id;
    })
  }
  getVentilations() {
    this.apiService.getVentilations().subscribe(async (res) => {
      this.ventilations = await res;
      this.ventilation = this.ventilations.find((lang: any) => lang.id === this.project?.idMarcaVentilacion);
      this.idMarcaVentilacion = this.ventilation?.id;
    })
  }
  // getProjectById(){

  // }

  changeLang(e: any) {
    console.log(e.target.value);
    let idLanguage = e.target.value;
    if (idLanguage) {
      const data = {
        idProyecto: this.projectService.projectId.getValue(),
        idLanguage: idLanguage
      }
      this.apiService.updateLanguage(data).subscribe(res => {
        console.log(res);
      }, err => {
        console.log(err);
      })
    }
  }

  changePromotor(e: any) {
    console.log(e.target.value);
    let idPromotor = e.target.value;
    if (idPromotor) {
      const data = {
        idProyecto: this.projectService.projectId.getValue(),
        idPromotor: idPromotor
      }
      this.apiService.updatePromotor(data).subscribe(res => {
        console.log(res);
      }, err => {
        console.log(err);
      })
    }
  }

  addPromotor() {
    this.nombrePromotor = "";
    const dialogRef = this.dialog.open(DialogOverviewNombrePromotor, {
      data: { nombre: this.nombrePromotor },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.nombrePromotor = result;
      if (this.nombrePromotor != "" && this.nombrePromotor != undefined) {
        this.crearPromotor();
      }
    });
  }
  crearPromotor() {
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        var nuevoPromotor = new Promotor();
        nuevoPromotor.nombre = this.nombrePromotor;
        this.http.put<Promotor>(URL_API + '/promotor', nuevoPromotor, httpOptions).subscribe(result => {
          this.promotores.push(result);
          this.idPromotor = result.id;
          this.nombrePromotor = result.nombre;
          this.selectedPromotor = result;
          this.changePromotor({ target: { value: result.id } });
        });
      }
    })
  }

  qualities(idCalidad: string) {
    if (idCalidad) {
      this.idCalidad = idCalidad;
      const data = {
        idProyecto: this.projectService.projectId.getValue(),
        idCalidad: idCalidad
      }
      this.apiService.updateQuality(data).subscribe(res => {
        console.log(res);
      }, err => {
        console.log(err);
      })
    }
  }
  energeticCertification(idCertificacion: string) {
    if (idCertificacion) {
      this.idCertificacion = idCertificacion;
      const data = {
        idProyecto: this.projectService.projectId.getValue(),
        idCertificacion: idCertificacion
      }
      this.apiService.updateEnergeticCertification(data).subscribe(res => {
        console.log(res);
      }, err => {
        console.log(err);
      })
    }
  }
  standardSpecifications(libroBlanco) {
    this.libroBlanco = !libroBlanco;
    const data = {
      idProyecto: this.projectService.projectId.getValue(),
      libroBlanco: this.libroBlanco
    }
    this.apiService.updateStandardSpecifications(data).subscribe(res => {
      console.log(res);
    }, err => {
      console.log(err);
    })
  }

  onFileSelected(event) {

    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('idProject', this.projectId);
        this.http.post(URL_API + '/proyectos/image', formData, httpOptions).subscribe((res: any) => {
          
          this.projectService.project.imagen = res.imagen.imagen;
          this.image = 'data:image/jpeg;base64,' + res.imagen.imagen;

        }, err => {
          console.log(err);
        })
      }
    })


  }

}

@Component({
  selector: 'dialog-overview-nombre-promotor',
  templateUrl: 'dialog-nombre-promotor.component.html',
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, LangTranslatorModule],
})
export class DialogOverviewNombrePromotor {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewNombrePromotor>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public translator: Translator
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface DialogData {
  nombre: string;
}

declare global {
  interface Window {
    chrome: any;
  }
}