import { Puertas } from './puertas.interface';
import { Ventana } from './ventana.interface';

export interface Project {
  bloques?: Bloque[];
  habitaciones?: Habitacion[];
  niveles?: Nivel[];
}

export interface Nivel {
  id: string;
  nombre: string;
  altura: number;
  exportar: boolean;
  isBajoRasante: boolean;
  superficieConstruida: number;
  plazasCoche: number;
  plazasMoto: number;
}

export interface Bloque {
  escaleras?: Escalera[];
  nombre: string;
}

export interface Escalera {
  plantas?: Planta[];
  nombre: string;
}

export interface Planta {
  id: string;
  viviendas?: Vivienda[];
  nombre: string;
  geometria: null;
  idEscalera: string;
}

export class Vivienda {
  habitaciones?: Habitacion[];
  nombre: string;
}

export interface Habitacion {
  nombre: string;
  idElement: string;
  nivel: string;
  elementos?: InstanciaFamilia[];
  ventanas?: Ventana[];
  puertas?: Puertas[];
  idTipoHabitacion: string;
}

export interface InstanciaFamilia {
  nombre: string;
  nombreFamilia: string;
  idElement: string;
  idTipoFamilia: string;
}
export interface Selections {
  id: string;
  idProyectoPlanta: string;
  localesResiduos: number;
  plazasCoche: number;
  plazasMoto: number;
  superficieConstruida: number;
  superficieConstruidaResiduos: number;
  superficieConstruidaTrasteros: number;
  trasteros: number;
  plantas?: Planta[];
  escalera: string;
  planta: string;
}
export interface Installation {
  id: string;
  idFontaneria: string;
  fontaneria: Fontaneria;
  idSalubridad: string;
  salubridad: Salubridad;
  idSaneamiento: string;
  saneamiento: Saneamiento;
  idElectricidad: string;
  electricidad: Electricidad;
  idClimatizacion: string;
  climatizacion: Climatizacion;
}
export interface Fontaneria {
  aljibe: boolean;
  idMarcaGrupoPresion: string;
  idMaterial_fontaneria: string;
  presion: number;
}
export interface Salubridad {
  idInstalaciones_Tipo: string;
  idSistema: string;
  idMarcaSalubridad: string;
}
export interface Saneamiento {
  idSaneamiento_TipoRed: string;
}

export interface Electricidad {
  toalleroElectrico: boolean;
  potenciaVehiculosElectricos: boolean;
  persianaElectrica: boolean;
  borna: boolean;
  idElectricidad_TipoCuadro: string;
}

export interface Climatizacion {
  idInstalaciones_Tipo: string;
  idClimatizacion_Sistema: string;
  idClimatizacion_Marca: string;
  idClimatizacion_Cargas: string;
  climaCocina: boolean;
  frioClima: boolean;
  idClimatizacion_EmisorCalor: string;
}

export interface resume {
  habitaciones: ResumeStatus;
  instalaciones: ResumeStatus;
  patinejos: ResumeStatus;
  puertas: ResumeStatus;
  elementos: ResumeStatus;
  viviendas: ResumeStatus;
  niveles: ResumeStatus;
  falsosTechos: ResumeStatus;
}
export interface ResumeStatus {
  error: resumeData[];
  warning: resumeData[];
  info: resumeData[];
}
export interface resumeData {
  codigo: number;
  mensaje: string;
  url: string;
}
