<div class="container">
  <div class="header">
    <h4>{{'habitcaion_heading_select1' | tr}}</h4>
    <p>{{'habitcaion_text_select1' | tr}}.</p>
  </div>
  <div class="form-field">
    <mat-form-field class="mat-form-field">
      <mat-label>{{'habitcaion_label_select1' | tr}}</mat-label>
      <mat-select [(ngModel)]="selectedTipoHabitacion[habitacion.nombre]"
        (selectionChange)="storeHabitacionType(habitacion, selectedTipoHabitacion[habitacion.nombre])">
        <mat-option *ngFor="let tipo of projectService.tipoHabitacion" [value]="tipo">
          {{tipo.nombre}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</div>
<div *ngIf="elementos?.length">
  <h2 class="h2">{{'families' | tr}}</h2>
  <mat-divider></mat-divider>
  <div *ngFor="let familia of elementos">
    <div class="container">
      <div class="header" (click)="searchFamily(familia)">
        <h4>{{familia.nombreFamilia}}</h4>
        <p>{{familia.nombre}}</p>
      </div>
      <div class="form-field">
        <mat-form-field class="mat-form-field">
          <mat-label>{{'habitcaion_label_select1' | tr}}</mat-label>
          <mat-select [(ngModel)]="selectedTipoFamilia[familia.nombreFamilia+familia.nombre]"
            (selectionChange)="storeFamilyType(familia, selectedTipoFamilia[familia.nombreFamilia+familia.nombre])">
            <mat-option *ngFor="let tipo of projectService.tipoFamilia" [value]="tipo">
              {{tipo.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>

<div *ngIf="puertas?.length">
  <h2 class="h2">{{'doors' | tr}}</h2>
  <mat-divider></mat-divider>
  <div *ngFor="let puerta of puertas">
    <div class="container">
      <div class="header-doors" (click)="searchFamily(puerta)">
        <h4>{{puerta.nombreFamilia}}</h4>
        <p>{{puerta.nombre}}</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="ventanas?.length">
  <h2 class="h2">{{'windows' | tr}}</h2>
  <mat-divider></mat-divider>
  <div *ngFor="let ventana of ventanas">
    <div class="container">
      <div class="header-windows" (click)="searchFamily(ventana)">
        <h4>{{ventana.nombreFamilia}}</h4>
        <p>{{ventana.nombre}}</p>
      </div>
    </div>
  </div>
</div>