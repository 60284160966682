<div class="project-resume-page">
  <app-loading *ngIf="isLoader"></app-loading>
  <section class="page-section" *ngIf="!isLoader">
    <div class="page-section-div">
      <div class="row">
        <div class="col-sm-12 col-lg-4" *ngIf="(!projectService.project || projectService.project.tipoProyecto !== 'salubridad') && (projectResumeData?.puertas.error.length || projectResumeData?.puertas.info.length || projectResumeData?.puertas.warning.length )">
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(7)">
              <i class="bi bi-door-open-fill"></i>
              <div class="py-2" >
                <h3>{{'puertas' | tr}}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div class="resume-card-box mt-3" *ngFor="let error of projectResumeData?.puertas.error">
                <div class="resume-card-box-content box-content-error">
                  {{error.mensaje}}
                </div>
                <div>
                </div>
              </div>
              <div class="resume-card-box mt-3" *ngFor="let info of projectResumeData?.puertas.info">
                <div class="resume-card-box-content box-content-info">
                  {{info.mensaje}}
                </div>
                <div>
                </div>
              </div>
              <div class="resume-card-box mt-3" *ngFor="let warning of projectResumeData?.puertas.warning">
                <div class="resume-card-box-content box-content-warning">
                  {{warning.mensaje}}
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4" *ngIf="projectResumeData?.habitaciones.error.length || projectResumeData?.habitaciones.info.length || projectResumeData?.habitaciones.warning.length ">
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(3)">
              <i class="fas fa-bed"></i>
              <div class="py-2">
                <h3>{{'habitaciones' | tr}}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div class="resume-card-box mt-3" *ngFor="let error of projectResumeData?.habitaciones.error">
                <div class="resume-card-box-content box-content-error">
                  {{error.mensaje}}
                </div>
                <div>
                </div>
              </div>
              <div class="resume-card-box mt-3" *ngFor="let info of projectResumeData?.habitaciones.info">
                <div class="resume-card-box-content box-content-info">
                  {{info.mensaje}}
                </div>
                <div>
                </div>
              </div>
              <div class="resume-card-box mt-3" *ngFor="let warning of projectResumeData?.habitaciones.warning">
                <div class="resume-card-box-content box-content-warning">
                  {{warning.mensaje}}
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4" *ngIf="(!projectService.project || projectService.project.tipoProyecto !== 'salubridad') && (projectResumeData?.ventanas.error.length || projectResumeData?.ventanas.info.length || projectResumeData?.ventanas.warning.length)">
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(8)">
              <i class="bi bi-border-all"></i>
              <div class="py-2">
                <h3>{{'ventanas' | tr}}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div class="resume-card-box mt-3" *ngFor="let error of projectResumeData?.ventanas.error">
                <div class="resume-card-box-content box-content-error">
                  {{error.mensaje}}
                </div>
                <div>
                </div>
              </div>
              <div class="resume-card-box mt-3" *ngFor="let info of projectResumeData?.ventanas.info">
                <div class="resume-card-box-content box-content-info">
                  {{info.mensaje}}
                </div>
                <div>
                </div>
              </div>
              <div class="resume-card-box mt-3" *ngFor="let warning of projectResumeData?.ventanas.warning">
                <div class="resume-card-box-content box-content-warning">
                  {{warning.mensaje}}
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4" *ngIf="projectResumeData?.instalaciones.error.length || projectResumeData?.instalaciones.info.length || projectResumeData?.instalaciones.warning.length">
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(12)">
              <i class="bi bi-patch-question-fill"></i>
              <div class="py-2">
                  <h3>{{'instalaciones' | tr}}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div class="resume-card-box mt-3" *ngFor="let error of projectResumeData?.instalaciones.error">
                <div class="resume-card-box-content box-content-error">
                  {{error.mensaje}}
                </div>
                <div>
                </div>
              </div>
              <div class="resume-card-box mt-3" *ngFor="let info of projectResumeData?.instalaciones.info">
                <div class="resume-card-box-content box-content-info">
                  {{info.mensaje}}
                </div>
                <div>
                </div>
              </div>
              <div class="resume-card-box mt-3" *ngFor="let warning of projectResumeData?.instalaciones.warning">
                <div class="resume-card-box-content box-content-warning">
                  {{warning.mensaje}}
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4" *ngIf="projectResumeData?.patinejos.error.length || projectResumeData?.patinejos.info.length || projectResumeData?.patinejos.warning.length">
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(6)">
              <i class="bi bi-layers"></i>
              <div class="py-2">
                <h3>{{'patinejos' | tr}}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div class="resume-card-box mt-3" *ngFor="let error of projectResumeData?.patinejos.error">
                <div class="resume-card-box-content box-content-error">
                  {{error.mensaje}}
                </div>
                <div>
                </div>
              </div>
              <div class="resume-card-box mt-3" *ngFor="let info of projectResumeData?.patinejos.info">
                <div class="resume-card-box-content box-content-info">
                  {{info.mensaje}}
                </div>
                <div>
                </div>
              </div>
              <div class="resume-card-box mt-3" *ngFor="let warning of projectResumeData?.patinejos.warning">
                <div class="resume-card-box-content box-content-warning">
                  {{warning.mensaje}}
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4" *ngIf="projectResumeData?.viviendas.error.length || projectResumeData?.viviendas.info.length || projectResumeData?.viviendas.warning.length">
          <div class="resume-card mt-4">
            <div class="resume-card-header" (click)="goTo(5)">
              <i class="bi bi-house-door"></i>
              <div class="py-2">
                <h3>{{'viviendas' | tr}}</h3>
              </div>
            </div>
            <div class="resume-card-body">
              <div class="resume-card-box mt-3" *ngFor="let error of projectResumeData?.viviendas.error">
                <div class="resume-card-box-content box-content-error">
                  {{error.mensaje}}
                </div>
                <div>
                  <i class="bi bi-arrow-right"></i>
                </div>
              </div>
              <div class="resume-card-box mt-3" *ngFor="let info of projectResumeData?.viviendas.info">
                <div class="resume-card-box-content box-content-info">
                  {{info.mensaje}}
                </div>
                <div>
                  <i class="bi bi-arrow-right"></i>
                </div>
              </div>
              <div class="resume-card-box mt-3" *ngFor="let warning of projectResumeData?.viviendas.warning">
                <div class="resume-card-box-content box-content-warning">
                  {{warning.mensaje}}
                </div>
                <div>
                  <i class="bi bi-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>