import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { } from 'googlemaps';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { Proyecto } from 'src/app/Shared/modals/proyecto';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '../services/project.service';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
@Component({
  selector: 'app-ubicacion-proyecto',
  templateUrl: './ubicacion-proyecto.component.html',
  styleUrls: ['./ubicacion-proyecto.component.css']
})
export class NuevoProyectoComponent implements AfterViewInit, OnDestroy {
  @ViewChild('map') mapElement: any;
  map: google.maps.Map;
  projectId: string;
  proyecto: Proyecto;
  isLoader: boolean = false;
  isRevitLoaded: boolean = false;
  location: Location;
  constructor(private router: Router, private projectService: ProjectService, private route: ActivatedRoute, private api: ApiServices, private toastr: ToastrService) {
  }
  ngAfterViewInit(): void {
    const isRevitData = this.projectService.isRevitData;
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.projectService.setProjectId(this.projectId);
    window?.chrome?.webview?.addEventListener('message', (event: MessageEvent) => this.onMessageReceived(event));
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'GetLocation' }));
    if (isRevitData) {
      //Map location from Revit
    } else {
      //Location from from API
      this.api.getProyectoById(this.projectId).subscribe((res: any) => {
        this.proyecto = res;
        if (this.proyecto.latitud && this.proyecto.longitud) {
          this.getLocation(Number(this.proyecto.latitud.replace(/,/g, '.')), Number(this.proyecto.longitud.replace(/,/g, '.'))).subscribe();
          const mapProperties = {
            center: new google.maps.LatLng(Number(this.proyecto.latitud.replace(/,/g, '.')), Number(this.proyecto.longitud.replace(/,/g, '.'))),
            zoom: 16,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          };
          this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(Number(this.proyecto.latitud), Number(this.proyecto.longitud)),
            map: this.map,
          });

        } else if (!this.isRevitLoaded) {
          //getting default Map Spain
          this.getLocation(40.4379543, -3.6795367).subscribe();
          const mapProperties = {
            center: new google.maps.LatLng(40.4379543, -3.6795367),
            zoom: 6,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          };
          this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(40.4379543, -3.6795367),
            map: this.map,
          });
        }
      }, error => {
        this.toastr.error('Something went wrong !');
      });
    }
  }

  //webview event callback
  onMessageReceived(event: MessageEvent): void {
    this.isRevitLoaded = true;
    console.log("data:\n" + event.data)
    if (!event.data.startsWith("ProjectData")) {
      const coordinates = JSON.parse(event.data);
      if (coordinates && coordinates.lat && coordinates.lng) {
        this.getLocation(coordinates.lat, coordinates.lng).subscribe((res: any) => {
          const userLocation = {
            idProyecto: this.projectService.projectId.getValue(),
            latitud: coordinates.lat,
            longitud: coordinates.lng,
            NombreVia: this.location.streetName || '',
            Numero: this.location.streetNumber || '',
            CodigoPostal: this.location.zipCode || '',
            Municipio: this.location.city || '',
            Provincia: this.location.province || '',
            Estado: this.location.state || '',
            Pais: this.location.country || ''
          };
          this.api.AddLocation(userLocation).subscribe(res => {
            console.log('location saved', res);
            this.toastr.success('User Location Saved..');
          }, error => {
            this.toastr.info('Something went wrong ! User location not saved.');
          });
          const mapProperties = {
            center: new google.maps.LatLng(coordinates.lat, coordinates.lng),
            zoom: 16,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          };
          this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(coordinates.lat, coordinates.lng),
            map: this.map,
          });
        });
      }
    }
  }
  getLocation(lat: number, lang: number): Observable<any> {
    return this.api.getLocation(lat, lang).pipe(
      tap((res: any) => {
        const result = res.results[0];
        const addressComponents = result.address_components;
        let streetNumber = '';
        let streetName = '';
        let zipCode = '';
        let city = '';
        let state = '';
        let province = '';
        let country = '';
        for (const component of addressComponents) {
          const componentType = component.types[0];
          console.log(componentType + ' ' + component.long_name);
          switch (componentType) {
            case 'street_number':
              streetNumber = component.long_name;
              break;
            case 'route':
              streetName = component.long_name;
              break;
            case 'postal_code':
              zipCode = component.long_name;
              break;
            case 'locality':
              city = component.long_name;
              break;
            case 'administrative_area_level_1':
              state = component.long_name;
              break;
            case 'administrative_area_level_2':
              province = component.long_name;
              break;
            case 'country':
              country = component.long_name;
              break;
            default:
              break;
          }
        }
        this.location = {
          streetName: streetName,
          streetNumber: streetNumber,
          zipCode: zipCode,
          city: city,
          province: province,
          state: state,
          country: country
        };
      }),
      catchError(err => {
        console.log(err);
        return of(null);
      })
    );
  }
  ngOnDestroy(): void {
    window?.chrome?.webview?.removeEventListener('message', (event: MessageEvent) => this.onMessageReceived(event))
  }
}
interface Location {
  streetName: string,
  streetNumber: string,
  zipCode: string,
  city: string,
  state: string,
  province: string,
  country: string,
}
