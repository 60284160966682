import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { ProjectService } from '../services/project.service';
import { Selections } from 'src/app/Shared/modals/allStructre.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-over-ground-selection',
  templateUrl: './over-ground-selection.component.html',
  styleUrls: ['./over-ground-selection.component.css']
})
export class OverGroundSelectionComponent {
  projectId: string;
  isLoader: boolean = false;
  expandedIndex = 0;
  sobrerasante: Selections[] = [];
  bajorasante: Selections[] = [];
  bajorasanteDetail: Selections;
  isDisable:boolean = true;
  form:FormGroup
  idProyectoPlanta:string;
  constructor(private fb:FormBuilder,private route: ActivatedRoute, private router: Router, private api: ApiServices, private toastr: ToastrService, private projectService: ProjectService) {
    this.projectId = this.route.snapshot.paramMap.get('id');
    if (this.projectId) {
      this.projectService.setProjectId(this.projectId);
    }
    this.form = this.fb.group({
      height:[''],
      urbanization:[false],
      urbanArea:[''],
      pool:[''],
      irrigation:[''],
      lighting:['']
    })
  }
  ngOnInit(): void {
    if (this.projectId) {
      this.isLoader = true;
      this.api.getSobrerasante(this.projectId).subscribe((res: any) => {
        this.isLoader = false;
        this.sobrerasante = res;
        console.log(res)
      }, err => {
        this.isLoader = false;
        console.log('error', err)
      })
      this.api.getUrbanizacion(this.projectId).subscribe((res: any) => {
        this.isLoader = false;
        this.form.patchValue({
          height:res.alturaMaxima,
          urbanArea:res.superficieZonaUrbana,
          pool:res.volumenPiscina,
          irrigation:res.superficieRiego,
          lighting:res.superficieIluminazion
        })
        if(res.superficieZonaUrbana !== null || res.volumenPiscina !== null || res.superficieRiego !== null || res.superficieIluminazion !== null){
          this.form.patchValue({
            urbanization:true
          })
        }
        console.log(res)
      }, err => {
        this.isLoader = false;
        console.log('error', err)
      })
    }
  }
 
  SavePlanteSobre(){
    if(this.form.valid){
      if (this.projectService.projectId.getValue()) {
        const payload = {
          IdProyecto: this.projectService.projectId.getValue(),
          AlturaMaxima: this.form.value.height,
        };
        if(this.form.value.urbanization === true){
          // add more values to payload
          payload['SuperficieZonaUrbana'] = this.form.value.urbanArea;
          payload['VolumenPiscina'] = this.form.value.pool;
          payload['SuperficieRiego'] = this.form.value.irrigation;
          payload['SuperficieIluminazion'] = this.form.value.lighting;
        }

        this.api.postUrbanizacion(payload).subscribe((res:Selections) => {
          this.bajorasanteDetail = res;
          this.toastr.success('Below Ground Plants Saved.')
        }, err => {
          this.toastr.error(err.error.title);
        })
      } else {
        this.toastr.info('Something went wrong');
      }
    }
  }
  heightChange(value:any){
    this.form.patchValue({
      height:value
    })
  }
  urbanizationChange(value:any){
    if(value === 'true'){
      this.form.patchValue({
        urbanization:true
      })
    }else if(value === 'false'){
      this.form.patchValue({
        urbanization:false
      })
    }else{
      this.form.patchValue({
        urbanization:value
      })
    }
  }
  urbanAreaChange(value:any){
    this.form.patchValue({
      urbanArea:value
    })
  }
  poolChange(value:any){
    this.form.patchValue({
      pool:value
    })
  }
  irrigationChange(value:any){
    this.form.patchValue({
      irrigation:value
    })
  }
  lightingChange(value:any){
    this.form.patchValue({
      lighting:value
    })
  }
}
