import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Proyecto } from 'src/app/Shared/modals/proyecto';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { Translator } from 'src/app/lang-translator';
import { Project, ProjectService } from 'src/app/pages/project/services/project.service';
import { URL_API } from 'src/main';
@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit {
  isMobile:boolean = false;
  isTablet:boolean = false;
  editMode:boolean = false;
  activeSlideIndex = 0;
  selectLang:string;
  tituloProyecto:string;
  @Output() dataEmitter = new EventEmitter<boolean>();
  @ViewChild('selectInput') selectInput: ElementRef<HTMLInputElement>;
  constructor(private toastr:ToastrService , private renderer: Renderer2,private router:Router,public proyectoService: ProjectService,private route: ActivatedRoute,private apiServices: ApiServices , private translator :Translator){
    this.selectLang = this.translator.getLanguage();
    this.onResize();
    this.proyectoService.dataEmitter = this.dataEmitter;
    this.onLoading(true);
    if (this.proyectoService.eventListenerSaveData == false) {
      this.proyectoService.eventListenerSaveData = true;
      window?.chrome?.webview?.addEventListener('message', event => {
        this.messageFromRevit(event)
      });
      window?.chrome?.webview?.addEventListener('messageerror',  err => {
        this.onLoading(false);
      });
    }
  }
  projectId:any;
  projects:Proyecto[]=[];
  project:Proyecto;
  title:string = '';
  ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.apiServices.getProyectos().subscribe( async res =>{
      this.projects =  await res;
      this.project = this.projects.find(project => project.id === this.projectId);
      this.tituloProyecto = this.project.tituloProyecto;
      if (this.project== undefined) {
        this.title = 'New Project';
      } 
    })
    window?.chrome?.webview?.postMessage("isRevit");
  }


  
  messageFromRevit(event: any) {
    this.onLoading(false);
    console.log("datos",event.data)
    if(event.data == "isRevit"){
      this.proyectoService.isRevitData = true;
    }else if(event.data.startsWith("ProjectData")){ 
      var data = new Project();
      var datosProject = event.data.substring("ProjectData".length);
      data = JSON.parse(datosProject);
      data.idProyecto = this.proyectoService.projectId.getValue();
      this.proyectoService.saveRevitData(data);
    }
  }

  onProcess(){
    this.onLoading(true);
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'getdataproject' }));
    setTimeout(() => {
      this.onLoading(false);
    }, 120000);
  }

  downloadProject(){
    window.open(URL_API + '/files/result/' + this.projectId + '/' + this.project.lastFolder,'_blank');
  }

  onLoading(isLoader:boolean) {
    this.dataEmitter.emit(isLoader);
  }

  goTo(url:string){
    this.router.navigate([`/${url}`]);
  }
  onLanguageChange(selectedLanguage: string) {
    this.selectLang = selectedLanguage
    this.translator.setLanguage(selectedLanguage);
  }
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    } else if(window.innerWidth <=992) {
      this.isMobile =false;
      this.isTablet = true;
    }
    else{
      this.isMobile = false;
      this.isTablet = false;
    }
  }
  updateProjectName(){
    if(this.projectId && this.tituloProyecto && this.tituloProyecto != this.project.tituloProyecto){
      this.project.tituloProyecto = this.tituloProyecto;
      const payload = {
        idproject:this.proyectoService.projectId.getValue(),
        tituloProyecto:this.tituloProyecto
      }
      this.apiServices.UpdateProjectName(payload).subscribe(res =>{
        this.editMode = false;
        this.toastr.success('Project title Updated !');
      },err =>{
        this.toastr.error(err.errors.title);
      })
    }else{
      this.editMode = false;
    }
  }
  onClicInput(selectInput: HTMLInputElement){
    selectInput.select();
  }
}
