import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import config from '../../auth_config.json';
import { AuthService } from '@auth0/auth0-angular';
import { URL_API } from 'src/main';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  getProfesional(): Observable<Profesional> {
    return this.auth.idTokenClaims$.pipe(
      mergeMap(token => this.http.get<Profesional>(URL_API + '/profesional', {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token.__raw}`,
          'Access-Control-Allow-Origin': '*',
        })
      }))
    );
  }

  public profesional: Profesional = null;

  constructor(private http: HttpClient,private auth: AuthService , private toastr :ToastrService) {

    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if(accessToken && accessToken != undefined){
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };

        this.http.get<Profesional>(URL_API + '/profesional',httpOptions).subscribe(result => {
          if (result == undefined){
            this.profesional = result;
          }
        },
        error => {
          this.http.post<Profesional>(URL_API + '/profesional',null,httpOptions).subscribe(result => {
            this.profesional = result;
          });
          this.toastr.error('Something went wrong ! error:',JSON.stringify(error));
        });

      }
    });

  }

  ping$() {
    return this.http.get(`${config.apiUri}/api/external`);
  }
}

export class Profesional {
  Id: string;
  Numero_Colegiado?: string;
  IdColegio_Profesional: string;
  FechaCreacion: Date;
  FechaUltimoAcceso: Date;
  Representante?: string;
  NIF?: string;
  CIF?: string;
  Direccion?: string;
  Contacto?: string;
  Telefono?: string;
  Email?: string;
  IdAuth: string;
  isValidated: boolean;
}
