import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { AuthClientConfig, AuthService } from '@auth0/auth0-angular';
import { ApiService } from 'src/app/api.service';
import { URL_API } from 'src/main';

@Component({
  selector: 'app-external-api',
  templateUrl: './external-api.component.html',
  styleUrls: ['./external-api.component.css'],
})
export class ExternalApiComponent {
  responseJson: string;
  public tipologia: Tipologia[] = [];
  public subtipologia: Subtipologia[] = [];
  public calidades: Calidades[] = [];
  public certificacion: Certificacion[] = [];
  public idioma: Idioma[] = [];
  public marcaAcs: MarcaACS[] = [];
  public marcaSalubridad: MarcaSalubridad[] = [];
  public marcaClimatizacion: MarcaClimatizacion[] = [];
  public marcaVentilacion: MarcaVentilacion[] = [];
  public municipios: Municipios[] = [];
  public comarcas: Comarcas[] = [];
  public provincias: Provincias[] = [];

  constructor(
    private api: ApiService,
    private auth: AuthService, private http: HttpClient
  ) {

    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if(accessToken && accessToken != undefined){
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
          })
        };
        this.http.get<Tipologia[]>(URL_API + '/tipologia',httpOptions).subscribe(result => {
          this.tipologia = result;
        });
        this.http.get<Subtipologia[]>(URL_API + '/tipologia/subtipologia',httpOptions).subscribe(result => {
          this.subtipologia = result;
        });
        this.http.get<Calidades[]>(URL_API + '/calidades',httpOptions).subscribe(result => {
          this.calidades = result;
        });
        this.http.get<Certificacion[]>(URL_API + '/certificacion',httpOptions).subscribe(result => {
          this.certificacion = result;
        });

        this.http.get<Idioma[]>(URL_API + '/idioma',httpOptions).subscribe(result => {
          this.idioma = result;
        });
        this.http.get<MarcaACS[]>(URL_API + '/marcas/acs',httpOptions).subscribe(result => {
          this.marcaAcs = result;
        });
        this.http.get<MarcaSalubridad[]>(URL_API + '/marcas/salubridad',httpOptions).subscribe(result => {
          this.marcaSalubridad = result;
        });
        this.http.get<MarcaClimatizacion[]>(URL_API + '/marcas/climatizacion',httpOptions).subscribe(result => {
          this.marcaClimatizacion = result;
        });
        this.http.get<MarcaVentilacion[]>(URL_API + '/marcas/ventilacion',httpOptions).subscribe(result => {
          this.marcaVentilacion = result;
        });
        this.http.get<Municipios[]>(URL_API + '/municipios',httpOptions).subscribe(result => {
          this.municipios = result;
        });
        this.http.get<Comarcas[]>(URL_API + '/municipios/comarcas',httpOptions).subscribe(result => {
          this.comarcas = result;
        });
        this.http.get<Provincias[]>(URL_API + '/municipios/provincias',httpOptions).subscribe(result => {
          this.provincias = result;
        });
      }
    })



  }


}

class Tipologia{
  id: string;
  nombre: string;
}
class Subtipologia{
  id: string;
  nombre: string;
}
class Calidades{
  id: string;
  nombre: string;
}
class Certificacion{
  id: string;
  nombre: string;
}
class Idioma{
  id: string;
  nombre: string;
}
class MarcaACS{
  id: string;
  nombre: string;
}
class MarcaSalubridad{
  id: string;
  nombre: string;
}
class MarcaClimatizacion{
  id: string;
  nombre: string;
}
class MarcaVentilacion{
  id: string;
  nombre: string;
}
class Municipios{
  id: string;
  nombre: string;
}
class Comarcas{
  id: string;
  nombre: string;
}
class Provincias{
  id: string;
  nombre: string;
}