<div class="project-data-page">
    <app-loading *ngIf="isLoader"></app-loading>
    <section class="page-section" *ngIf="!isLoader">
      <div class="page-section-div">
        <div class="row">
            <div class="col-12 col-custome">
               <div>
                <div class="row page-content-row">
                    <div class="col-md-6 col-sm-12">
                        <p  class="page-heading">{{'titular' | tr}}</p>
                        <p class="page-text">{{'promotor' | tr}}</p>
                    </div>
                    <div class="col-md-5 col-sm-12 offset-md-1">
                        <mat-form-field class="mat-field">
                            <mat-label>{{'promotor' | tr}}</mat-label>
                            <select matNativeControl [(ngModel)]="idPromotor" (change)="changePromotor($event)">
                                <option *ngFor="let promotor of promotores" [value]="promotor.id">{{promotor.nombre}}</option>
                            </select>
                        </mat-form-field>
                        <button mat-raised-button class="btn btn-primary" (click)="addPromotor()">{{'new_promotor' | tr}}</button>
                    </div>
                </div>
                <div class="row page-content-row">
                    <div class="col-md-6 col-sm-12">
                        <p  class="page-heading">{{'imagen' | tr}}</p>
                        <p class="page-text">{{'imagen' | tr}}</p>
                    </div>
                    <div class="col-md-5 col-sm-12 offset-md-1">
                        <input type="file" (change)="onFileSelected($event)" class="form-control" accept="image/*" />
                        <img *ngIf="image" [src]="image" alt="image" class="img-fluid" />
                    </div>
                </div>
               </div>
            </div>
        </div>
      </div>
    </section>
</div>