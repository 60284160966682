import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Profesional } from 'src/app/Shared/modals/Profesional.interface';
import { ApiServices } from 'src/app/Shared/services/api.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  profesional:Profesional;
  form:FormGroup
  constructor(private apiService:ApiServices , private fb:FormBuilder , private toastr:ToastrService){
    this.form = this.fb.group({
      nombre:[],
      apellidos:[],
      nif:[],
      numero_Colegiado: [],
      direccion:[],
      contacto:[],
      telefono:[],
      email: [],
      empresa:[],
      cif:[],
    })
    this.getUserInfo();
  }
  ngOnInit(){
  }
  getUserInfo(){
    this.apiService.getUserInfo().subscribe( (res: Profesional) =>{
      console.log('res:',res)
      this.profesional = res;
      this.form.patchValue(res);
    },error =>{
      this.toastr.error('User info not found !');
    })
  }
  addUserInfo(){
    let userData = {
      id:this.profesional.id,
      ...this.form.value
    }
    this.apiService.addUserInfo(userData).subscribe((res:any) =>{
      this.toastr.success('User Updated Successfully');
    },err =>{
      this.toastr.error('something went wrong:',err.error.title);
    })
  }

}
