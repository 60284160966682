<app-loading *ngIf="isLoader"></app-loading>
<section class="page-section" *ngIf="!isLoader">
  <div class="plant-selection-main">
    <div class="mb-5">
      <div class="selectors" (submit)="SavePlanteBajorasante()">
        <form [formGroup]="form">
          <div class="row align-items-center">
            <div class="col-md-6 col-sm-12">
              <p class="page-sub-heading">{{ 'plantDetail_label1' | tr }}</p>
            </div>
            <div class="col-md-6 col-sm-12">
              <mat-form-field class="mat-form-field">
                <mat-label>{{ 'plantDetail_placeholder' | tr }}</mat-label>
                <input
                  matInput
                  type="number"
                  step="any"
                  formControlName="superficieConstruida"
                />
              </mat-form-field>
              <div
                class="error-message"
                *ngIf="
                  form.get('superficieConstruida').hasError('required') &&
                  form.get('superficieConstruida').touched
                "
              >
                {{ 'plantDetail_label1' | tr }} is required.
              </div>
              <div
                class="error-message"
                *ngIf="
                  form.get('superficieConstruida').hasError('pattern') &&
                  form.get('superficieConstruida').touched
                "
              >
                Only numbers, commas, and decimal points are allowed.
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-6 col-sm-12">
              <p class="page-sub-heading">{{ 'plantDetail_label2' | tr }}</p>
            </div>
            <div class="col-md-6 col-sm-12">
              <mat-form-field class="mat-form-field">
                <mat-label>{{ 'plantDetail_placeholderCant' | tr }}</mat-label>
                <input matInput type="number" formControlName="plazasCoche" />
              </mat-form-field>
              <div
                class="error-message"
                *ngIf="
                  form.get('plazasCoche').hasError('required') &&
                  form.get('plazasCoche').touched
                "
              >
                {{ 'plantDetail_label2' | tr }} is required.
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-6 col-sm-12">
              <p class="page-sub-heading">{{ 'plantDetail_label3' | tr }}</p>
            </div>
            <div class="col-md-6 col-sm-12">
              <mat-form-field class="mat-form-field">
                <mat-label>{{ 'plantDetail_placeholderCant' | tr }}</mat-label>
                <input matInput type="number" formControlName="plazasMoto" />
              </mat-form-field>
              <div
                class="error-message"
                *ngIf="
                  form.get('plazasMoto').hasError('required') &&
                  form.get('plazasMoto').touched
                "
              >
                {{ 'plantDetail_label3' | tr }} is required.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <button class="btn-save" type="submit" [disabled]="form.invalid">
                {{ 'save' | tr }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
