<app-loading *ngIf="isLoader"></app-loading>
<div class="page-section" *ngIf="!isLoader">
  <div class="page-section-div">
    <h2>{{'window_of_window' | tr}}</h2>
    <button mat-raised-button color="primary" style="margin-bottom: 20px;" (click)="filtrar()">{{filter===true?('textoFiltro1' |tr):('textoFiltro2' |tr)}}</button>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let ventana of filtrarventanasConNombreNoVacio()" hideToggle>
        <mat-expansion-panel-header style="height: fit-content;">
          <mat-panel-title>
            <div>
              <h4 >{{ventana.nombreFamilia}}</h4>
              <p>{{ventana.nombre}}</p>
            </div>
          </mat-panel-title>
          <mat-panel-description>
            {{getNombre(ventana)}}
          </mat-panel-description>
        </mat-expansion-panel-header>
    
        <div class="container">
          <div class="header">
            <h4>{{'window_u' | tr}}</h4>
            <p>{{'window_u_text' | tr}}</p>
          </div>
          <div class="form-field">
            <mat-form-field class="example-full-width">
              <mat-label>U</mat-label>
              <input matInput placeholder="1.00" pattern="^[0-9]+(\.[0-9]*)?$" [value]="ventana.u" (blur)="onInputBlur($event, ventana)">
              <mat-hint>{{'decimal_accepted' | tr}}.</mat-hint>
              <mat-error>{{'incorect_u' | tr}}</mat-error>
            </mat-form-field>
          
          </div>
        
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>