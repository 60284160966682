<div class="container mt-5">
  <h2>Calidades</h2>
  <mat-form-field>
    <mat-label>Calidades</mat-label>
    <mat-select>
      <mat-option *ngFor="let cal of calidades" [value]="cal.id">
        {{cal.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h2>Certificación Energética</h2>
  <mat-form-field>
    <mat-label>Certificacion</mat-label>
    <mat-select>
      <mat-option *ngFor="let cer of certificacion" [value]="cer.id">
        {{cer.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h2>Idiomas</h2>
  <mat-form-field>
    <mat-label>Idioma</mat-label>
    <mat-select>
      <mat-option *ngFor="let x of idioma" [value]="x.id">
        {{x.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h2>Marcas</h2>
  <h4>ACS</h4>
  <mat-form-field>
    <mat-label>ACS</mat-label>
    <mat-select>
      <mat-option *ngFor="let x of marcaAcs" [value]="x.id">
        {{x.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h4>Salubridad</h4>
  <mat-form-field>
    <mat-label>Salubridad</mat-label>
    <mat-select>
      <mat-option *ngFor="let x of marcaSalubridad" [value]="x.id">
        {{x.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h4>Climatización</h4>
  <mat-form-field>
    <mat-label>Climatización</mat-label>
    <mat-select>
      <mat-option *ngFor="let x of marcaClimatizacion" [value]="x.id">
        {{x.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h4>Ventilación</h4>
  <mat-form-field>
    <mat-label>Ventilación</mat-label>
    <mat-select>
      <mat-option *ngFor="let x of marcaVentilacion" [value]="x.id">
        {{x.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h2>Municipios</h2>
  <h4>Provincia</h4>
  <mat-form-field>
    <mat-label>Provincia</mat-label>
    <mat-select>
      <mat-option *ngFor="let x of provincias" [value]="x.id">
        {{x.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h4>Comarca</h4>
  <mat-form-field>
    <mat-label>Comarca</mat-label>
    <mat-select>
      <mat-option *ngFor="let x of comarcas" [value]="x.id">
        {{x.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h4>Municipio</h4>
  <mat-form-field>
    <mat-label>Municipio</mat-label>
    <mat-select>
      <mat-option *ngFor="let x of municipios" [value]="x.id">
        {{x.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h2>Tipologia</h2>
  <h4>Tipologia</h4>
  <mat-form-field>
    <mat-label>Tipologia</mat-label>
    <mat-select>
      <mat-option *ngFor="let x of tipologia" [value]="x.id">
        {{x.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <h4>Subtipologia</h4>
  <mat-form-field>
    <mat-label>Subtipologia</mat-label>
    <mat-select>
      <mat-option *ngFor="let x of subtipologia" [value]="x.id">
        {{x.nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>
