import { Component } from '@angular/core';

@Component({
  selector: 'app-project-processed',
  templateUrl: './project-processed.component.html',
  styleUrls: ['./project-processed.component.css']
})
export class ProjectProcessedComponent {

}
