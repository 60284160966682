import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { BehaviorSubject, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Bloque, Habitacion, InstanciaFamilia, Nivel } from "src/app/Shared/modals/allStructre.interface";
import { Proyecto } from "src/app/Shared/modals/proyecto";
import { ApiServices } from "src/app/Shared/services/api.service";
import { URL_API } from "src/main";

@Injectable({
    providedIn: 'root',
})

export class ProjectService {
    
    
    dataEmitter: EventEmitter<boolean>;


    isRevitData: boolean = false;
    roomsData: BehaviorSubject<Bloque[]> = new BehaviorSubject<Bloque[]>([]);
    projectId = new BehaviorSubject<string>('');
    projectId$ = this.projectId.asObservable();
    project: Proyecto;
    familyType: { [key: string]: TipoFamilia } = {};
    habitacionType: { [key: string]: TipoHabitacion } = {};

    differences: string[] = [];
    tipoFamilia: TipoHabitacion[] = [];
    tipoHabitacion: AccesorioDomestico[] = [];

    tipoPatinejo: TipoPatinejo[] = [];
    eventListenerSaveData: Boolean = false;

    constructor(private http: HttpClient, public auth: AuthService,private apiService: ApiServices, private router: Router) {
        this.getTiposFamilias();
        this.getTiposHabitaciones();
        this.getTiposPatinejos();
        
    }

    //Return accessToken.__raw
    async getAccesToken() {
        const a = this.auth.idTokenClaims$;
        try {
            const accessToken = await a.toPromise();
            if (accessToken && accessToken !== undefined) {
                return accessToken.__raw;
            }
        } catch (error) {
            console.error('Error al obtener el accessToken:', error);
        }
    }


    saveRevitData(projectData: Project, isLoader: boolean = false) {
        console.log(projectData);
        const a = this.auth.idTokenClaims$;
        a.subscribe(accessToken => {
            if (accessToken && accessToken != undefined) {

                const httpOptions = {
                    headers: new HttpHeaders({
                        'Authorization': 'Bearer ' + accessToken.__raw,
                        'Access-Control-Allow-Origin': '*'
                    })
                };
                this.dataEmitter.emit(true);
                this.http.get<Project>(URL_API + '/proyectos/rooms/' + this.projectId.getValue(), httpOptions).toPromise().then(result => {
                    this.setHabitacionesFamilyType(result);
                    this.setHabitacionesFamilyTypeOnData(projectData);
                    this.save(projectData);
                    this.dataEmitter.emit(false);
                },
                error => {
                    this.save(projectData);
                    this.dataEmitter.emit(false);
                });



            }
        })
    }

    storeHabitacionType(habitacion: string, tipo: TipoHabitacion) {
        const a = this.auth.idTokenClaims$;
        a.subscribe(accessToken => {
            if (accessToken && accessToken != undefined) {

                const httpOptions = {
                    headers: new HttpHeaders({
                        'Authorization': 'Bearer ' + accessToken.__raw,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    })
                };
                const dataToSerialize = {
                    idProject: this.projectId.getValue(),
                    idTipo: tipo.id,
                    nombre: habitacion
                };
                this.http.post<Habitacion[]>(URL_API + '/proyectos/habitaciontipo', JSON.stringify(dataToSerialize), httpOptions).subscribe(result => {

                    

                });



            }
        })
    }

    storeFamiliaType(nombreFamilia: string, nombre: string, tipo: TipoHabitacion) {
        const a = this.auth.idTokenClaims$;
        a.subscribe(accessToken => {
            if (accessToken && accessToken != undefined) {

                const httpOptions = {
                    headers: new HttpHeaders({
                        'Authorization': 'Bearer ' + accessToken.__raw,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    })
                };
                const dataToSerialize = {
                    idProject: this.projectId.getValue(),
                    idTipo: tipo.id,
                    nombreFamilia: nombreFamilia,
                    nombre: nombre,
                };
                this.http.post<InstanciaFamilia[]>(URL_API + '/proyectos/familiatipo', JSON.stringify(dataToSerialize), httpOptions).subscribe(result => {

                    

                });



            }
        })
    }

    async save(projectData: Project) {

        const a = this.auth.idTokenClaims$;
        a.subscribe(accessToken => {
            if (accessToken && accessToken != undefined) {

                const httpOptions = {
                    headers: new HttpHeaders({
                        'Authorization': 'Bearer ' + accessToken.__raw,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    })
                };
                this.setHabitacionesFamilyTypeOnData(projectData);

                console.log(projectData);
                this.dataEmitter.emit(true);
                this.http.post<Project>(URL_API + '/proyectos/saverooms', JSON.stringify(projectData), httpOptions).subscribe(result => {

                    if (result.bloques.length > 0) {
                        console.log(result.bloques);
                        this.roomsData.next(result.bloques);
                    }

                    this.dataEmitter.emit(false);
                }
                , error => {
                    this.dataEmitter.emit(false);
                });


            }
        });

    }

    setprojectData(projectId: string) {
        if (this.projectId.getValue() !== projectId){
            this.setProjectId(projectId);
            this.getProjectData();
        }
    }

    setProjectId(newProjectId: string) {
        this.projectId.next(newProjectId);
    }

    getProjectData() {
        this.apiService.getProyectoById(this.projectId.getValue()).subscribe(async (res: any) => {
            if (res && res.notFound) {
                // Redirige a la página que desees en caso de 404
                this.router.navigate(['/']);
            } else {
                this.project = await res;
            }
        }, error => {
            console.error('Error al obtener el proyecto:', error);
        });
    }

    getRoomsObservable() {
        return this.roomsData.asObservable();
    }

    setRoomsData() {
        const a = this.auth.idTokenClaims$;
        a.subscribe(accessToken => {
            if (accessToken && accessToken != undefined) {

                const httpOptions = {
                    headers: new HttpHeaders({
                        'Authorization': 'Bearer ' + accessToken.__raw,
                        'Access-Control-Allow-Origin': '*'
                    })
                };

                this.http.get<Project>(URL_API + '/proyectos/rooms/' + this.projectId.getValue(), httpOptions).subscribe(result => {

                    //this.roomsData.next(result.bloques);
                    this.setHabitacionesFamilyType(result);

                    if (!this.isRevitData) {
                        this.roomsData.next(result.bloques);
                    }

                }, error => {
                    console.error(error)
                    if (!this.isRevitData) {
                        this.roomsData.next([]);
                    }
                });

            }
        });

    }
    getRoomsByApi(){
        const a = this.auth.idTokenClaims$;
        return a.pipe(switchMap(accessToken => {
          if (accessToken && accessToken != undefined) {
    
            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': 'Bearer ' + accessToken.__raw,
                'Access-Control-Allow-Origin': '*'
              })
            };
            return this.http.get<Project>(URL_API + '/proyectos/rooms/' + this.projectId.getValue(), httpOptions);
          }
        }));
    }

    private setHabitacionesFamilyType(result: Project) {
        result.bloques.forEach(bloque => {
            bloque.escaleras.forEach(escalera => {
                escalera.plantas.forEach(planta => {
                    planta.viviendas.forEach(vivienda => {
                        vivienda.habitaciones.forEach(hab => {
                            this.habitacionType[hab.nombre] = this.tipoHabitacion.find(x => x.id == hab.idTipoHabitacion);
                            hab.elementos.forEach(elemento => {
                                this.familyType[elemento.nombreFamilia + elemento.nombre] = this.tipoFamilia.find(x => x.id == elemento.idTipoFamilia);
                            });
                        });
                    });
                });
            });
        });
    }

    private setHabitacionesFamilyTypeOnData(result: Project) {
        result.bloques.forEach(bloque => {
            bloque.escaleras.forEach(escalera => {
                escalera.plantas.forEach(planta => {
                    planta.viviendas.forEach(vivienda => {
                        vivienda.habitaciones.forEach(hab => {
                            if (this.habitacionType[hab.nombre] != undefined) {
                                hab.idTipoHabitacion = this.habitacionType[hab.nombre].id;
                            }
                            hab.elementos.forEach(elemento => {
                                if (this.familyType[elemento.nombreFamilia + elemento.nombre] != undefined) {
                                    elemento.idTipoFamilia = this.familyType[elemento.nombreFamilia + elemento.nombre].id;
                                }
                            });
                        });
                    });
                });
            });
        });
    }

    private getTiposHabitaciones() {
        if (this.tipoHabitacion.length == 0) {
            const a = this.auth.idTokenClaims$;
            a.subscribe(accessToken => {
                if (accessToken && accessToken != undefined) {

                    const httpOptions = {
                        headers: new HttpHeaders({
                            'Authorization': 'Bearer ' + accessToken.__raw,
                            'Access-Control-Allow-Origin': '*',
                        })
                    };
                    this.http.get<TipoHabitacion[]>(URL_API + '/tipohabitacion', httpOptions).subscribe(result => {
                        this.tipoHabitacion = result;
                    });

                }
            });
        }
    }



    private getTiposFamilias() {
        if (this.tipoFamilia.length == 0) {
            const a = this.auth.idTokenClaims$;
            a.subscribe(accessToken => {
                if (accessToken && accessToken != undefined) {

                    const httpOptions = {
                        headers: new HttpHeaders({
                            'Authorization': 'Bearer ' + accessToken.__raw,
                            'Access-Control-Allow-Origin': '*',
                        })
                    };
                    this.http.get<AccesorioDomestico[]>(URL_API + '/aparatosdomesticos', httpOptions).subscribe(result => {
                        this.tipoFamilia = result;
                    });

                }
            });
        }
    }

    private getTiposPatinejos() {
        if (this.tipoPatinejo.length == 0) {
            const a = this.auth.idTokenClaims$;
            a.subscribe(accessToken => {
                if (accessToken && accessToken != undefined) {

                    const httpOptions = {
                        headers: new HttpHeaders({
                            'Authorization': 'Bearer ' + accessToken.__raw,
                            'Access-Control-Allow-Origin': '*',
                        })
                    };
                    this.http.get<TipoPatinejo[]>(URL_API + '/patinejos/tipos', httpOptions).subscribe(result => {
                        this.tipoPatinejo = result;
                    });

                }
            });
        }
    }


    CambiarTipoHabitacion(nombre: string, idTipo: string): Habitacion {
        let habitacion: Habitacion;
        this.roomsData.subscribe((data: Bloque[]) => {
            data.forEach(bloque => {
                bloque.escaleras.forEach(escalera => {
                    escalera.plantas.forEach(planta => {
                        planta.viviendas.forEach(vivienda => {
                            vivienda.habitaciones.forEach(hab => {
                                if (hab.nombre == nombre) {
                                    hab.idTipoHabitacion = idTipo;
                                }
                            });
                        });
                    });
                });
            });
        });

        return habitacion;
    }

    CambiarTipoFamilia(nombre: string, idTipo: string): Habitacion {
        let habitacion: Habitacion;
        this.roomsData.subscribe((data: Bloque[]) => {
            data.forEach(bloque => {
                bloque.escaleras.forEach(escalera => {
                    escalera.plantas.forEach(planta => {
                        planta.viviendas.forEach(vivienda => {
                            vivienda.habitaciones.forEach(hab => {
                                hab.elementos.forEach(elemento => {
                                    if (elemento.nombreFamilia + elemento.nombre == nombre) {
                                        elemento.idTipoFamilia = idTipo;
                                    }
                                });
                            });
                        });
                    });
                });
            });
        });

        return habitacion;
    }


}

export class AccesorioDomestico {
    id: string;
    nombre: string;
}

export class TipoHabitacion {
    id: string;
    nombre: string;
}

export class TipoFamilia {
    id: string;
    nombre: string;
}


export class TipoPatinejo {
    id: string;
    nombre: string;
}

export class Project {
    bloques?: Bloque[];
    niveles?: Nivel[];
    idProyecto: string;
}








