<form [formGroup]="form" (submit)="SavePlanteSobre()">
  <div class="col-12">
    <div>
      <div class="row page-content-row">
        <div class="col-md-6 col-sm-12">
          <p class="page-heading">{{ 'overPlantDetail_label1' | tr }}</p>
          <p class="page-text">{{ 'subOverPlantDetail_label1' | tr }}</p>
        </div>
        <div class="col-md-5 col-sm-12 offset-md-1">
          <mat-form-field class="mat-field">
            <mat-label>{{ 'enter_height' | tr }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="height"
              (change)="heightChange($event.target.value)"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row page-content-row">
        <div class="col-md-6 col-sm-12">
          <p class="page-heading">{{ 'overPlantDetail_label2' | tr }}</p>
          <p class="page-text">{{ 'subOverPlantDetail_label2' | tr }}</p>
        </div>
        <div class="col-md-5 col-sm-12 offset-md-1">
          <mat-form-field class="mat-field">
            <mat-label>{{ 'si' | tr }}</mat-label>
            <select
              matNativeControl
              formControlName="urbanization"
              (change)="urbanizationChange($event.target.value)"
            >
              <option [value]="true">{{ 'yes' | tr }}</option>
              <option [value]="false">{{ 'no' | tr }}</option>
            </select>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="form.value.urbanization">
        <div class="row align-items-center">
          <div class="col-md-6 offset-md-1 col-sm-12">
            <p class="page-heading">{{ 'overPlantDetail_label3' | tr }}</p>
            <p class="page-text">{{ 'subOverPlantDetail_label3' | tr }}</p>
          </div>
          <div class="col-md-5 col-sm-12">
            <mat-form-field class="mat-field">
              <mat-label>{{ 'enter_a_surface' | tr }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="urbanArea"
                (change)="urbanAreaChange($event.target.value)"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-6 offset-md-1 col-sm-12">
            <p class="page-heading">{{ 'overPlantDetail_label4' | tr }}</p>
            <p class="page-text">{{ 'subOverPlantDetail_label4' | tr }}</p>
          </div>
          <div class="col-md-5 col-sm-12">
            <mat-form-field class="mat-field">
              <mat-label>{{ 'enter_a_volume' | tr }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="pool"
                (change)="poolChange($event.target.value)"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-6 offset-md-1 col-sm-12">
            <p class="page-heading">{{ 'overPlantDetail_label5' | tr }}</p>
            <p class="page-text">{{ 'subOverPlantDetail_label5' | tr }}</p>
          </div>
          <div class="col-md-5 col-sm-12">
            <mat-form-field class="mat-field">
              <mat-label>{{ 'enter_a_surface' | tr }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="irrigation"
                (change)="irrigationChange($event.target.value)"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-6 offset-md-1 col-sm-12">
            <p class="page-heading">{{ 'overPlantDetail_label6' | tr }}</p>
            <p class="page-text">{{ 'subOverPlantDetail_label6' | tr }}</p>
          </div>
          <div class="col-md-5 col-sm-12">
            <mat-form-field class="mat-field">
              <mat-label>{{ 'enter_a_surface' | tr }}</mat-label>
              <input
                matInput
                type="text"
                formControlName="lighting"
                (change)="lightingChange($event.target.value)"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button type="submit" class="btn-setting-bottom-1">{{ 'save' | tr }}</button>
</form>
