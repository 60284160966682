<app-loading *ngIf="isLoader"></app-loading>
<div *ngIf="!isLoader">
  <table *ngIf="dataSource?.length > 0" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="Nivel">
      <th mat-header-cell *matHeaderCellDef> Nivel </th>
      <td mat-cell *matCellDef="let element"> {{element.Nivel}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="Area">
      <th mat-header-cell *matHeaderCellDef> Area </th>
      <td mat-cell *matCellDef="let element"> {{element.Area}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="NumeroLados">
      <th mat-header-cell *matHeaderCellDef> Numero de lados </th>
      <td mat-cell *matCellDef="let element"> {{element.NumeroLados}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <button (click)="save()">
    {{'keep'| tr}}
  </button>
  
  <button (click)="next()">
    {{'following' | tr}}
  </button>
</div>