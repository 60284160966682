import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { URL_API } from 'src/main';
import { ProjectService, TipoFamilia, TipoPatinejo } from '../services/project.service';
import { Vivienda, Habitacion } from 'src/app/Shared/modals/allStructre.interface';

@Component({
  selector: 'app-familias',
  templateUrl: './familias.component.html',
  styleUrls: ['./familias.component.css'],
})



export class FamiliasComponent implements OnInit {

  projectId: string = "";
  familias: Familia[] = [];
  familiasTodas: Familia[] = [];
  tipofamilia: TipoFamilia[] = [];
  isLoader:boolean = false;
  selectedTipoFamilia: { [key: string]: TipoFamilia } = {};
  filter: boolean = false;
  sortedColumn: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';

  constructor(public auth: AuthService,
    private route: ActivatedRoute,
    private http: HttpClient,
    public projectService: ProjectService,
    private cdRef: ChangeDetectorRef) {


    this.selectedTipoFamilia = this.projectService.familyType;
  }

  ngOnInit() {
    this.isLoader = true;
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.projectService.setProjectId(this.projectId);
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*'
          })
        };

        this.http.get<Familia[]>(URL_API + '/proyectos/familias/' + this.projectService.projectId.getValue(), httpOptions).subscribe(result => {
          this.isLoader = false;
          this.familiasTodas = result;
          this.familias = this.getUniqueFamilias(result);
          this.sortFamilias('categoria');
        },err =>{
          this.isLoader = false;
        });

      }else{
        this.isLoader = false;
      }
    })
  }
  getUniqueFamilias(familias: Familia[]): Familia[] {
    const uniqueFamilias: Familia[] = [];
    const uniqueKeys = new Set<string>();
    // Recorrer todas las familias recibidas en el resultado
    familias.forEach(familia => {
      const key = familia.nombreFamilia + familia.nombre;
      // Si el nombreFamilia + nombre aún no existe en el Set uniqueKeys, agregarlo al arreglo uniqueFamilias y al Set uniqueKeys
      
      this.projectService.familyType[key] = this.projectService.tipoFamilia.find(x => x.id == familia.idTipoFamilia);
      if (!uniqueKeys.has(key)) {
        familia.count = 1;
        uniqueKeys.add(key);
        uniqueFamilias.push(familia);
      }else{
        uniqueFamilias.find(x => x.nombreFamilia + x.nombre == key).count++;
      }
    });

    return uniqueFamilias;
  }
  getInstanciasFamilias(familia: Familia): Familia[] {
    return this.familiasTodas.filter(f => f.nombreFamilia + f.nombre === familia.nombreFamilia + familia.nombre);
  }

  searchRoom(habitacion) {
    
    
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchRoom', 'obj': habitacion }));
  }
  searchVivienda(vivienda) {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchVivienda', 'obj': vivienda }));
  }

  searchFamily(family) {
    //make a copy of family
    var family2 = JSON.parse(JSON.stringify(family));
    family2.conectores = null;
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchFamily', 'obj': family2 }));
  }

  filtrarfamiliasConNombreNoVacio() {
    if (this.filter) {
      return this.familias.filter(familia => {
        const nombre = this.getNombre(familia);
        return nombre.trim() === ''; // Filtrar solo si el nombre no está vacío (sin espacios)
      });
    } else {
    }
    return this.familias;
  }

  textoFiltro: string = "Quitar asignados";
  filtrar() {
    this.filter = !this.filter;
    if (this.filter) {
      this.textoFiltro = "Mostrar todos";
    } else {
      this.textoFiltro = "Quitar asignados";
    }
    this.cdRef.detectChanges();
  }

  storeFamilyType(familia: Familia, tipo: TipoFamilia) {
    this.projectService.familyType[familia.nombreFamilia+familia.nombre] = tipo;
    this.projectService.CambiarTipoFamilia(familia.nombreFamilia+familia.nombre, tipo.id);
    this.projectService.storeFamiliaType(familia.nombreFamilia, familia.nombre, tipo);
  }

  getNombre(familia: Familia) {
    if (this.projectService.familyType[familia.nombreFamilia + familia.nombre] == undefined) {

      return "";
    }
    return this.projectService.familyType[familia.nombreFamilia + familia.nombre].nombre;
  }

  sortFamilias(column: string) {
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortedColumn = column;
      this.sortDirection = 'asc';
    }

    this.familias.sort((a, b) => {
      let valueA: any;
      let valueB: any;

      switch(column) {
        case 'nombreFamilia':
          valueA = a.nombreFamilia;
          valueB = b.nombreFamilia;
          break;
        case 'door_col6':
          valueA = a.nombre;
          valueB = b.nombre;
          break;
        case 'conectoresMEP':
          valueA = a.conectores;
          valueB = b.conectores;
          break;
        case 'count':
          valueA = a.count;
          valueB = b.count;
          break;
        default:
          valueA = a[column];
          valueB = b[column];
      }

      if (valueA > valueB) {
        return this.sortDirection === 'asc' ? 1 : -1;
      } else if (valueA < valueB) {
        return this.sortDirection === 'asc' ? -1 : 1;
      } else {
        return 0;
      }
    });
  }

}

interface Familia {
  id: string;
  nombre: string;
  nombreFamilia: string;
  IdVivienda: string;
  IdPlanta: string;
  IdEscalera: string;
  IdBloque: string;
  Vivienda: Vivienda;
  Planta: string;
  Escalera: string;
  Bloque: string;
  idTipoFamilia: string;
  count: number;
  conectores: Boolean;
  idElement: number;
}
