export class Proyecto {
    id: string;
    idTipologia?: string;
    idIdioma?: string;
    idMunicipio?: string;
    idTipoVia?: string;
    nombreVia?: string;
    numero?: string;
    codigoPostal?: string;
    referenciaCatastral?: string;
    latitud?: string;
    longitud?: string;
    id_Calidades?: string;
    id_CertificacionEnergetica?: string;
    libroBlanco: boolean;
    idInstalaciones?: string;
    idProfesional?: string;
    idPromotor?: string;
    idEstado?: string;
    hayErrores: boolean;
    idError?: string;
    fechaCreacion: Date;
    fechaUltimoAcceso: Date;
    hayGas: boolean;
    codigoProyecto?: string;
    tituloProyecto?: string;
    alturaMaxEvacuacion?: number;
    idMarcaClimatizacion?:string;
    idMarcaACS?:string;
    idMarcaVentilacion?:string;
    numeroViviendas?:number;
    tipoProyecto?:string;
    idImagen?:string;
    imagen?:Imagen;
    lastFolder?:string;
  }
  export class Imagen {
    id: string;
    imagen: string;
  }

