import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { URL_API } from 'src/main';
import { ProjectService } from '../services/project.service';
import { Puertas } from 'src/app/Shared/modals/puertas.interface';
import { ApiServices } from 'src/app/Shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ThemePalette } from '@angular/material/core';
@Component({
  selector: 'app-doors',
  templateUrl: './doors.component.html',
  styleUrls: ['./doors.component.css']
})
export class DoorsComponent {
  projectId: string = "";
  uniqPuertas: Puertas[] = [];
  puertas: Puertas[] = [];
  filter: boolean = false;
  isLoader: boolean = false;
  constructor(public auth: AuthService,
    private route: ActivatedRoute,
    private http: HttpClient,
    public projectService: ProjectService,
    private cdRef: ChangeDetectorRef, private apiService: ApiServices, private toastr: ToastrService) {


  }

  ngOnInit() {
    this.isLoader = true;
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.projectService.setProjectId(this.projectId);
    this.apiService.getPuertas(this.projectId).subscribe(
      (result) => {
        this.isLoader = false;
        this.puertas = result;
        if (this.puertas) {
          this.uniqPuertas = this.getUniqueVentanas(this.puertas);
          console.log('uniqPuertas:', this.uniqPuertas)
          this.uniqPuertas.forEach((uniq, index) => {
            uniq.subPuertas = this.puertas.filter(puerta =>
              puerta.nombreFamilia === uniq.nombreFamilia && puerta.nombre === uniq.nombre
            );
            // Update 'stair' property
            const hasTrueEscalera = uniq.subPuertas.some(item => item.isEscalera === true);
            const hasFalseEscalera = uniq.subPuertas.some(item => item.isEscalera === false);
            uniq.isEscalera= hasTrueEscalera ? (hasFalseEscalera ? false : true) : null;
            // Update 'principal' property
            const hasTruePrincipal = uniq.subPuertas.some(item => item.principal === true);
            const hasFalsePrincipal = uniq.subPuertas.some(item => item.principal === false);
            uniq.principal = hasTruePrincipal ? (hasFalsePrincipal ? false : true) : null;
            // Update 'ventana' property
            const hasTrueVentana = uniq.subPuertas.some(item => item.ventana === true);
            const hasFalseVentana = uniq.subPuertas.some(item => item.ventana === false);
            uniq.ventana = hasTrueVentana ? (hasFalseVentana ? false : true) : null;
          });
          console.log('Total puertas', this.puertas);
          console.log('uniq puertas', this.uniqPuertas);
        }
      },
      (err) => {
        this.isLoader = false;
        console.error(err);
      });
  }

  getUniqueVentanas(puertas: Puertas[]): Puertas[] {
    const uniqueVentanas: Puertas[] = [];
    const uniqueKeys = new Set<string>();
    const keyCountMap: Map<string, number> = new Map();
    puertas.forEach(puertasItem => {
      const key = puertasItem.nombreFamilia + puertasItem.nombre;
      if (!uniqueKeys.has(key)) {
        uniqueKeys.add(key);
        keyCountMap.set(key, 1);
      } else {
        const existingCount = keyCountMap.get(key);
        keyCountMap.set(key, existingCount + 1);
      }
    });
    puertas.forEach(puertasItem => {
      const key = puertasItem.nombreFamilia + puertasItem.nombre;
      if (uniqueKeys.has(key)) {
        const count = keyCountMap.get(key);
        uniqueVentanas.push({ ...puertasItem, count: count });
        uniqueKeys.delete(key);
      }
    });
    return uniqueVentanas;
  }

  filtrarventanasConNombreNoVacio() {
    if (this.filter) {
      return this.uniqPuertas.filter(puertas => {
        const nombre = this.getNombre(puertas);
        return nombre.trim() === ''; // Filtrar solo si el nombre no está vacío (sin espacios)
      });
    } else {
    }
    return this.uniqPuertas;
  }

  textoFiltro: string = "Quitar asignados";
  filtrar() {
    this.filter = !this.filter;
    if (this.filter) {
      this.textoFiltro = "Mostrar todos";
    } else {
      this.textoFiltro = "Quitar asignados";
    }
    this.cdRef.detectChanges();
  }



  getNombre(ventana: Puertas) {
    return "";
  }


  onInputBlur(event: any, ventana: Puertas) {
    const a = this.auth.idTokenClaims$;
    a.subscribe(accessToken => {
      if (accessToken && accessToken != undefined) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken.__raw,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          })
        };
        const dataToSerialize = {
          idProyecto: this.projectService.projectId.getValue(),
          nombreFamilia: ventana.nombreFamilia,
          nombre: ventana.nombre,
          u: event.target.value,
        };
        this.http.post<any>(URL_API + '/proyectos/puertas', JSON.stringify(dataToSerialize), httpOptions).subscribe(result => {

        },
          error => {
            console.log(error);
          });
      }
    })
  }
  stairChange(e, puerta: Puertas) {
    if (e.checked === false) {
      puerta.isEscalera = null;
      puerta.subPuertas.forEach(item => {
        item.isEscalera = false;
      })
    } else {
      puerta.isEscalera = true;
      puerta.subPuertas.forEach(item => {
        item.isEscalera = true;
      })
    }
    let postData = {
      idProyecto: this.projectService.projectId.getValue(),
      nombre: puerta.nombre,
      nombreFamilia: puerta.nombreFamilia,
      isEscalera: e.checked
    }
    this.apiService.updatedStair(postData).subscribe(res => {
      this.toastr.success('Puerta stair updated');
    }, error => {
      this.toastr.error('data did not updated ! error:', error);
    })
  }
  principalChange(e, puerta: Puertas) {
    if (e.checked === false) {
      puerta.principal = null;
      puerta.subPuertas.forEach(item => {
        item.principal = false;
      })
    } else {
      puerta.principal = true;
      puerta.subPuertas.forEach(item => {
        item.principal = true;
      })
    }
    let postData = {
      idProyecto: this.projectService.projectId.getValue(),
      nombreFamilia: puerta.nombreFamilia,
      nombre: puerta.nombre,
      principal: e.checked
    }
    this.apiService.updatedPrinciple(postData).subscribe(res => {
      this.toastr.success('Puerta principal updated');
    }, error => {
      this.toastr.error('data did not updated ! error:', error);
    })
  }
  ventanaChange(e, puerta: Puertas) {
    if (e.checked === false) {
      puerta.ventana = null;
      puerta.subPuertas.forEach(item => {
        item.ventana = false;
      })
    } else {
      puerta.ventana = true;
      puerta.subPuertas.forEach(item => {
        item.ventana = true;
      })
    }
    let postData = {
      idProyecto: this.projectService.projectId.getValue(),
      nombreFamilia: puerta.nombreFamilia,
      nombre: puerta.nombre,
      ventana: e.checked
    }
    this.apiService.updatedVentana(postData).subscribe(res => {
      this.toastr.success('Puerta ventana updated');
    }, error => {
      this.toastr.error('data did not updated ! error:', error);
    })
  }
  stairIndividualChange(e, puerta: Puertas, subPureta: Puertas) {
    subPureta.isEscalera = e;
    const hasTrueEscalera = puerta.subPuertas.some(item => item.isEscalera === true);
    const hasFalseEscalera = puerta.subPuertas.some(item => item.isEscalera === false);
    puerta.isEscalera = hasTrueEscalera ? (hasFalseEscalera ? false : true) : null;
    let postData = {
      idProyecto: this.projectService.projectId.getValue(),
      idElement: subPureta.idElement,
      nombre: subPureta.nombre,
      nombreFamilia: subPureta.nombreFamilia,
      isEscalera: e
    }
    this.apiService.updatedIndividualStair(postData).subscribe(res => {
      this.toastr.success('Sub Puerta stair updated');
    }, error => {
      this.toastr.error('data did not updated ! error:', error);
    });
  }
  principalIndividualChange(e, puerta: Puertas, subPureta: Puertas) {
    subPureta.principal = e;
    const hasTruePrincipal = puerta.subPuertas.some(item => item.principal === true);
    const hasFalsePrincipal = puerta.subPuertas.some(item => item.principal === false);
    puerta.principal = hasTruePrincipal ? (hasFalsePrincipal ? false : true) : null;

    let postData = {
      idProyecto: this.projectService.projectId.getValue(),
      idElement: subPureta.idElement,
      nombreFamilia: subPureta.nombreFamilia,
      nombre: subPureta.nombre,
      principal: e
    }
    this.apiService.updatedIndividualPrinciple(postData).subscribe(res => {
      this.toastr.success('Sub Puerta principal updated');
    }, error => {
      this.toastr.error('data did not updated ! error:', error);
    });
  }
  ventanaIndividualChange(e, puerta: Puertas, subPureta: Puertas) {
    subPureta.ventana = e;
    const hasTrueVentana = puerta.subPuertas.some(item => item.ventana === true);
    const hasFalseVentana = puerta.subPuertas.some(item => item.ventana === false);
    puerta.ventana = hasTrueVentana ? (hasFalseVentana ? false : true) : null;
    let postData = {
      idProyecto: this.projectService.projectId.getValue(),
      idElement: subPureta.idElement,
      nombreFamilia: subPureta.nombreFamilia,
      nombre: subPureta.nombre,
      ventana: e
    }
    this.apiService.updatedIndividualVentana(postData).subscribe(res => {
      this.toastr.success('Sub Puerta ventana updated');
    }, error => {
      this.toastr.error('data did not updated ! error:', error);
    })
  }

  searchFamily(family) {
    window?.chrome?.webview?.postMessage(JSON.stringify({ 'id': 'searchFamily', 'obj': family }));
  }
}


