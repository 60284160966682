// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MAT_DIALOG_DATA ,MatDialogRef,} from '@angular/material/dialog';

import { Component, OnInit, Inject } from '@angular/core';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent {
  title: string;
  message: string;

  constructor(public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) {
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
